var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.steps)?_c('div',{staticClass:"stepper buttons mb-0 w-100"},_vm._l((_vm.steps),function(step,index){return _c('button',{key:index,class:_vm.buttonClass,staticStyle:{"height":"auto"},attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.$emit('step', (index))}}},[(_vm.$route.params.product && index != 5)?_c('span',{staticClass:"icon is-large mr-1 mt-1"},[_c('span',{class:['fas', {
          'fa-circle' : +_vm.currentStep !== (+index),
          'fa-check-circle' : +_vm.currentStep === (+index),
        }, 'is-size-5']})]):_vm._e(),(!_vm.$route.params.product)?_c('span',{staticClass:"icon is-large mr-1 mt-1"},[_c('span',{class:['fas', {
          'fa-circle' : +_vm.currentStep !== (+index),
          'fa-check-circle' : +_vm.currentStep === (+index),
        }, 'is-size-5']})]):_vm._e(),(_vm.$route.params.product && index != 5)?_c('span',{staticClass:"is-small is-size-6 has-text-left ml-2",staticStyle:{"line-height":"17px"}},[(_vm.subtitle)?[_c('small',{staticClass:"is-size-7"},[_vm._v(_vm._s(_vm.$t("simulator.step"))+" "+_vm._s(index))]),_c('br')]:_vm._e(),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(step.title))])],2):_vm._e(),(!_vm.$route.params.product)?_c('span',{staticClass:"is-small is-size-6 has-text-left ml-2",staticStyle:{"line-height":"17px"}},[(_vm.subtitle)?[_c('small',{staticClass:"is-size-7"},[_vm._v(_vm._s(_vm.$t("simulator.step"))+" "+_vm._s(index))]),_c('br')]:_vm._e(),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(step.title))])],2):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }