<template>
    <div class="container d-flex justify-content-center align-items-center" :style="{height: '85vh'}">
        <form @submit.prevent="resetPasswordClicked" class="col-12 form-group my-2">
            <h2 class="h2-style text-center">{{$t("login.resetPassword")}}</h2>
            <div class="col-12 form-group my-2">           
                <label for="password">{{ $t("login.pass") }}</label>
                <input type="password" id="password" name="password" v-model="password" class="form-control" :class="{ 'is-invalid': submitted && hasPasswordError }"/>
                <div v-if="submitted && hasPasswordError" class="invalid-feedback">
                    <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
                    <span v-if="errors.Password">{{errors.Password}}</span>
                    <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                    <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                    <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                    <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                    <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                </div>
            </div>
            <div class="col-12 form-group my-2">
                <label for="confirmPassword">{{ $t("login.rpass") }}</label>
                <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
                <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                    <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
                    <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                </div>
            </div>
            <div class="col-12 py-3 text-center">
                <button type="submit" class="btn btn-secondary" v-bind:class="{disabled: loading}" :style="{width: '160px', maxWidth: '160px'}">
                    <span v-show="!loading">{{$t("login.resetBtn")}}</span>
                    <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                </button> 
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { UTILS_HELPER } from "@/helpers/utils.js"
import { required, sameAs } from "vuelidate/lib/validators";
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: {
        
    },
     metaInfo () {
      return { 
            title: `${"/resetPasswordTitle"} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.resetPassword", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.resetPassword", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.resetPassword", 'es')])},
            ]
        } 
    },
    data: function () {
        return {
            username: null,
            token: null,
            password: null,
            confirmPassword: null,
            loading: false,
            submitted: false,
        }
    }, 
    validations: {
        password: { required },
        confirmPassword: { required, sameAsPassword: sameAs('password') }

    },
    created: function (){
        this.username = this.$route.query.username;
        this.token = this.$route.query.token;
    },
     methods: {
        ...mapActions('account', ['resetPassword']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
    
        resetPasswordClicked: function()
        {
            this.clearAlert();
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }
           
            this.loading = true;
            const { username, token, password } = this;
            this.resetPassword({username, token, password}); 
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
    },
    computed: {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['processConfirm']),
        ...mapState('account', ['processTask']),

        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasPasswordError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Password")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Password") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordTooShort") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresNonAlphanumeric") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresDigit") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresLower") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresUpper");
        },
    },
    watch: {
        processTask(val)
        {
            if(val != null)
            {
                if(val)
                {
                    this.loading = false;
                    this.submitted = false;
                    this.password = null;
                    this.confirmPassword = null;
                    this.loading = false;
                    this.$fire({
                        title: this.$t("login.resetPassTitle"),
                        text: this.$t("login.resetPassTextSuccess"),
                        type: "success"
                    })
                    this.$router.push({name: 'Login'});
                }
                else{
                    this.loading = false;
                    if(!this.hasPasswordError){
                        this.submitted = false;
                        this.password = null;
                        this.confirmPassword = null;
                        this.$fire({
                            title: this.$t("login.resetPassTitle"),
                            text: this.$t("login.resetPassTextError"),
                            type: "error"
                        });
                        this.$router.push({name: 'Login'});
                    }
                }
            }
        }
    },
}
</script>

<style scoped>
.container{
    max-width: 500px!important;
}
>>>.label-style{
    color: #2B86C5;
}
.confirmAcc-style{
    color: #2B86C5;
    margin-top: 5rem;
}
.submit-button
{
    background-color: #784BA0;
    border: 4px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
.action
{
    justify-content: center;
    align-content: center; 
    display: flex;
    flex-direction: column;
}
.h2-style{
    color: #2B86C5;
}
</style>