<template>
  <div class="upload-background-image">
    <button :class="buttonClasses"
            v-if="!$route.params.product"
            :disabled="loading"
            @click="openFileHandler">
      <span class="icon mr-5 is-size-6">
      <span :class="['fa', {
        'fa-upload':!loading && !hasImage || !successRequest,
        'fa-spin fa-spinner':loading && successRequest,
        'fa fa-trash-alt': hasImage && !loading && successRequest
      }]"></span>
      </span>
      <span class="is-inline-block is-size-6 ml-2">
        {{!hasImage || !successRequest ? $t("simulator.loadBackgroundImage") : $t("simulator.removeBackgroundImage")}}
      </span>
    </button>
    <input type="file" style="display: none;"
           v-if="!hasImage || !successRequest"
           accept=".png,.jpeg,.jpg"
           :ref="`backgroundImage`"
           :disabled="loading"
           @change="fileHandlerCallback"/>
    <input type="file" style="display: none;"
           v-if="hasImage && successRequest"
           :ref="`backgroundImage`"
           @click.prevent.stop="removeFile"/>
  </div>
</template>
<script>
export default {
  name: 'UploadBackground',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // created because it may fail to insert the image (ex: large image size)
    successRequest: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: null,
      default() {
        return () => ['button', 'is-white'];
      }
    }
  },
  data() {
    return {
      hasImage: false,
    };
  },
  computed: {
    
  },
  methods: {
    openFileHandler() {
      let element = this.$refs[`backgroundImage`];
      return element.length > 0 ? element[0].click() : element.click();
    },
    fileHandlerCallback() {
      let element = this.$refs[`backgroundImage`];
      element = element.length > 0 ? element[0] : element;
      let file = element.files && element.files.length ?
                 element.files[0] : null;
      if(file===null) { return false; }
      else{ this.hasImage = true; }
      this.$emit('background', file);
    },
    removeFile() {
      let element = this.$refs[`backgroundImage`];
      element = element.length > 0 ? element[0] : element;
      element.value = "";
      this.hasImage = false;
      this.$emit('resetBackground');
    }
  },
}
</script>