<template>
  <div class="units-table">
  <table class="table units is-fullwidth is-hidden-mobile">
    <thead>
    <tr>
      <th scope="col" colspan="2">{{$t("simulator.pricePerUnit")}}</th>
      <th scope="col">{{$t("simulator.quantity")}}</th>
      <th scope="col"></th>
      <th scope="col">{{$t("simulator.totalPrice")}}</th>
    </tr>
    </thead>
    <tbody class="">
    <tr>
      <td>{{ formatAmount(currentSelected.amount) }}</td>
      <td>
        <button class="button is-white is-small opacity-1" disabled >
          <span class="icon"><span class="fa fa-times"></span></span>
        </button>
      </td>
      <td>
        <button class="button is-white is-small" :disabled="(+quantity) === 1" @click="removeQuantity">
          <span class="icon"><span class="fa fa-minus"></span></span>
        </button>
        <button class="button is-white is-small opacity-1 has-text-weight-bold" disabled>
          <span>{{ quantity }}</span>
        </button>
        <button class="button is-white is-small" @click="addQuantity" :disabled="$route.params.product">
          <span class="icon"><span class="fa fa-plus"></span></span>
        </button>
      </td>
      <td>
        <button class="button is-white is-small opacity-1" disabled >
          <span class="icon"><span class="fa fa-equals"></span></span>
        </button>
      </td>
      <td>
        <span v-if="expectedAmount != totalAmount" class="strokethrough">{{ formatAmount(expectedAmount) }}</span>
        {{ formatAmount(totalAmount) }}
      </td>
    </tr>
    </tbody>
  </table>
  <table class="table units is-fullwidth is-hidden-tablet">
    <thead>
    <tr>
      <th scope="col">{{$t("simulator.pricePerUnit")}}</th>
      <td class="has-text-right">{{ formatAmount(currentSelected.amount) }}
        <button class="button is-white is-small opacity-1" disabled >
          <span class="icon"><span class="fa fa-times"></span></span>
        </button>
      </td>
    </tr>
    <tr>
      <th scope="col">{{$t("simulator.quantity")}}</th>
      <td class="has-text-right">
        <button class="button is-white is-small" :disabled="(+quantity) === 1" @click="removeQuantity">
          <span class="icon"><span class="fa fa-minus"></span></span>
        </button>
        <button class="button is-white is-small opacity-1 has-text-weight-bold" disabled>
          <span>{{ quantity }}</span>
        </button>
        <button class="button is-white is-small" @click="addQuantity">
          <span class="icon"><span class="fa fa-plus"></span></span>
        </button>
      </td>
    </tr>
    <tr>
      <th scope="col">{{$t("simulator.totalPrice")}}</th>
      <td class="has-text-right">
        <button class="button is-white is-small opacity-1" disabled >
          <span class="icon"><span class="fa fa-equals"></span></span>
        </button>
        <span v-if="expectedAmount != totalAmount" class="strokethrough">{{ formatAmount(expectedAmount) }}</span>
        {{ formatAmount(totalAmount) }}
      </td>
    </tr>
    </thead>
  </table>
  </div>
</template>
<script>
export default {
  name: 'UnitsStep',
  props: {
    units: {
      type: Number,
      default: 1,
    },
    currentSelected: {
      type: Object,
      default() {
        return {
          amount: this.totalAmount,
        }
      }
    },
    prices: {
      type: Array
    }
  },
  data() {
    return {
      quantity: this.$route.params.quantity ? this.$route.params.quantity : this.$route.params.product && this.$route.params.product.quantity ? this.$route.params.product.quantity : 1,
    };
  },
  watch: {
    quantity:
    {
      handler(val)
      {
        if(val)
        {
          this.$emit('units', this.quantity);
          this.$route.params.quantity = val;
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    expectedAmount()
    {
      return this.currentSelected.amount * this.quantity;
    },
    totalAmount() {
      let result = this.currentSelected.amount;

      if (this.prices && this.prices.length)
      {
        this.prices.forEach(element => {
          if(this.quantity >= element.minQuantity)
            result = element.price;
        });
      }
      
      return result * this.quantity;
    },
  },
  methods: {
    formatAmount(amount) {
      return new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount);
    },
    removeQuantity() {
      this.quantity -= 1;
      this.$emit('quantity', 'product', this.quantity);
    },
    addQuantity() {
      this.quantity += 1;
      this.$emit('quantity', 'product', this.quantity);
    },
  }
}
</script>

<style scoped>
.strokethrough
{
  text-decoration: line-through;
}
</style>