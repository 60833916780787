<template>
  <section> 
        <SpinnerLoading v-if="loading || orderListDetails == null || addressInfoOutput == null"/>
        <div class="container">
            <div class="row py-5 template">
                <div class="col-sm-12 col-md-3 col-lg-3 col-style">
                    <h4 class="tab-btn my-5  cursor-pointer" :class="{'active': activeTab == index}" @click="activeTab = index" v-for="(menuOption, index) in menuOptions" :key="index">{{menuOption.name}}</h4>
                </div>
                <div class="d-none d-md-block col-md-1 col-lg-1 verticalBar"></div>
                <!-- Orders -->
                <div class="col-sm-12 col-md-8 col-lg-8 align-self-center" :class="{'d-none': activeTab != 0}" >
                    <div class="row m-0 align-content-center" v-if="orders.length == []">
                        <h2 class="d-flex justify-content-center">{{$t("myAccount.noOrders")}}</h2>
                        <router-link class="d-flex justify-content-center" :to="{name: 'Shop'}"><button class="btn btn-primary mt-5 mb-5 border-0">{{$t("cart.goToShop")}}</button></router-link>
                    </div>
                   <div v-else class="table-wrapper">
                        <div class="table-title">
                            <div class="row">
                                <div class="col-12">
                                    <h2><b>{{$t("orderDetails")}}</b></h2>
                                </div>
                            </div>
                        </div>
                        <div>
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                    <th class="w-25" scope="col">{{$t("orders.ref")}}</th>
                                        <th class="w-25" scope="col">{{$t("orders.date")}}</th>
                                        <th class="w-25" scope="col">{{$t("orders.price")}}</th>
                                        <th class="w-25" scope="col">{{$t("orders.status")}}</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(order, index) in orders" :key="index">
                                    <tr>
                                        <td scope="row">00{{order.id}}</td>
                                        <td>{{order.createdAt}}</td>
                                        <td>{{order.price.toFixed(2)}}€</td>
                                        <td>{{order.status === 'PendingPayment' ? $t('myAccount.pendingPayment') : order.status === 'PendingProcessing' ? $t('myAccount.pendingProcessing') : order.status === 'Processing' ? $t('myAccount.processing') : order.status === 'Shipped' ? $t('myAccount.shipped') : order.status === 'Concluded' ? $t('myAccount.concluded') :  $t('myAccount.canceled') }}</td>
                                        <td @click="activeShow == index ? activeShow = -1 : activeShow = index"><a class="view" title="View Details" data-toggle="tooltip"><b-icon :icon="activeShow == index ? 'arrow-down' : 'arrow-right'" class="cursor-pointer"></b-icon></a></td>
                                    </tr>
                                    <tr v-if="activeShow == index">
                                        <td colspan="1" class="vertical-address-bar">
                                            <a @click="showAddressesPopUpForm = true">{{$t("orders.address")}}</a>
                                        </td>
                                        <td colspan="4">
                                            <div class="d-flex justify-content-between border-bottom" v-for="(productDetail, index) in orderDetails.products" :key="index">
                                                <div class="py-3">
                                                    <p class="m-0"><strong>{{productDetail.name}}</strong></p>
                                                    <p class="m-0">{{productDetail.price}}€</p>
                                                </div>
                                                <div class="py-3">
                                                    <p class="m-0"><router-link :to="{ name: 'ProductDetails', params:{ slug: productDetail.slug}}">{{$t("orders.product")}}</router-link></p>
                                                    <p v-if="Object.keys(productDetail.colors).length || Object.keys(productDetail.images).length" class="m-0"><router-link :to="{ name: 'Simulator', params:{ slug: productDetail.slug, notes: productDetail.notes, colors:productDetail.colors, images: productDetail.images }}">{{$t("orders.simulator")}}</router-link></p>
                                                </div>
                                            </div>
                                            <AddressesDetailPopUp v-if="showAddressesPopUpForm" @closeConfirmRequested="showAddressesPopUpForm = false" :order="orderDetails.addresses" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="rows >= 5" class="col-12 d-flex pagination">
                            <Pagination :rows="rows" :itemsPerPage="itemsPerPage" :loading="loading" v-model="currentPage"></Pagination>
                        </div>
                    </div>
                </div>
                <!-- Interests -->
                <div class="col-sm-12 col-md-8 col-lg-8" :class="{'d-none': activeTab != 1}">
                    <!-- <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.name") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="name">
                    </div> -->
                    <div class="d-flex align-items-center">
                        <label for="brand" class="flex-grow-1">{{ $t("login.brand") }}</label>
                        <label class="d-flex align-items-center font-weight-bold" for="brand"><span :style="{fontSize: '0.7rem',fontWeight: 'bold'}">Inflate</span><Tooltip :text="$t('brandDesc.inflate')"></Tooltip></label>                                                   
                        <label class="d-flex align-items-center font-weight-bold" for="brand"><span :style="{fontSize: '0.7rem'}">Active</span><Tooltip :text="$t('brandDesc.active')"></Tooltip></label>                     
                    </div>                              
                    <select name="brand" id="brand" v-model="brand" class="form-control mt-2 flex-grow-1" :class="{ 'is-invalid': submitted }">
                        <option value="" disabled selected>{{$t("login.selectYourBrand")}}</option>
                        <option value="InflateYourImagination">Inflate</option>
                        <option value="ActiveBrands">Active</option>
                    </select>
                    <div :class="{'d-none': activeTab != 1 && activeTab != 2 && activeTab != 3}" class="d-flex justify-content-end w-100 mt-3" >
                        <button @click="handleSubmit" v-bind:class="{disabled: loading}" type="submit" class="btn btn-primary border-0">
                            <span v-show="!loading">{{ $t("save") }}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        </button>
                    </div>
                </div>
                <!-- Billing Address -->
                <div class="col-sm-12 col-md-8 col-lg-8" :class="{'d-none': activeTab != 2}">
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.name") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.name" :class="{ 'is-invalid': submitted && hasInvoiceError('Name') }">
                        <div v-if="submitted && hasInvoiceError('Name')" class="invalid-feedback">
                            <span v-if="!$v.invoice.name.required">{{$t("invoice.name")}}</span>                            
                            <span v-if="errors.Name">{{errors.Name}}</span>
                        </div>                    
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.email") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.email" :class="{ 'is-invalid': submitted && hasInvoiceError('Email') }">
                        <div v-if="submitted && hasInvoiceError('Email')" class="invalid-feedback">
                            <span v-if="!$v.invoice.email.required">{{$t("invoice.email")}}</span>                            
                            <span v-if="!$v.invoice.email.email">{{ $t("invoice.invEmail") }}</span>
                            <span v-if="errors.Email">{{errors.Email}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="inputContactNumber">{{ $t("checkout.phone") }}</label>
                        <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="invoice.phone" :class="{ 'is-invalid': submitted && hasInvoiceError('Phone') }"/>
                        <div v-if="submitted && hasInvoiceError('Phone')" class="invalid-feedback">
                            <span v-if="!$v.invoice.phone.required">{{$t("invoice.phone")}}</span>                            
                            <span v-if="errors.Phone">{{errors.Phone}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.company") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.companyName">
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.nif") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.vat" :class="{ 'is-invalid': submitted && hasInvoiceError('Vat') }">
                        <div v-if="submitted && hasInvoiceError('Vat')" class="invalid-feedback">
                            <span v-if="!$v.invoice.vat.required">{{$t("invoice.vat")}}</span>                            
                            <span v-if="errors.Vat">{{errors.Vat}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.country") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.country" :class="{ 'is-invalid': submitted && hasInvoiceError('Country') }">
                        <div v-if="submitted && hasInvoiceError('Country')" class="invalid-feedback">
                            <span v-if="!$v.invoice.country.required">{{$t("invoice.country")}}</span>                            
                            <span v-if="errors.Country">{{errors.Country}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.address") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.street" :class="{ 'is-invalid': submitted && hasInvoiceError('Street') }">
                        <div v-if="submitted && hasInvoiceError('Street')" class="invalid-feedback">
                            <span v-if="!$v.invoice.street.required">{{$t("invoice.street")}}</span>                            
                            <span v-if="errors.Street">{{errors.Street}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.zip") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.zipCode" :class="{ 'is-invalid': submitted && hasInvoiceError('ZipCode') }">
                        <div v-if="submitted && hasInvoiceError('ZipCode')" class="invalid-feedback">
                            <span v-if="!$v.invoice.zipCode.required">{{$t("invoice.zipCode")}}</span>                            
                            <span v-if="errors.ZipCode">{{errors.ZipCode}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.location") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.state" :class="{ 'is-invalid': submitted && hasInvoiceError('State') }">
                        <div v-if="submitted && hasInvoiceError('State')" class="invalid-feedback">
                            <span v-if="!$v.invoice.state.required">{{$t("invoice.state")}}</span>                            
                            <span v-if="errors.State">{{errors.State}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.city") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.city" :class="{ 'is-invalid': submitted && hasInvoiceError('City') }">   
                        <div v-if="submitted && hasInvoiceError('City')" class="invalid-feedback">
                            <span v-if="!$v.invoice.city.required">{{$t("invoice.city")}}</span>                            
                            <span v-if="errors.City">{{errors.City}}</span>
                        </div>                  
                    </div>
                    <div :class="{'d-none': activeTab != 1 && activeTab != 2 && activeTab != 3}" class="d-flex justify-content-end w-100" >
                        <button @click="handleSubmit" v-bind:class="{disabled: loading}" type="submit" class="btn btn-primary border-0">
                            <span v-show="!loading">{{ $t("save") }}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        </button>
                    </div>
                </div>
                <!-- Shipping Address -->
                <div class="col-sm-12 col-md-8 col-lg-8" :class="{'d-none': activeTab != 3}">
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.name") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.name" :class="{ 'is-invalid': submitted && hasShippingError('Name') }">
                        <div v-if="submitted && hasShippingError('Name')" class="invalid-feedback">
                            <span v-if="!$v.shipping.name.required">{{$t("shipping.name")}}</span>                            
                            <span v-if="errors.Name">{{errors.Name}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.email") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.email" :class="{ 'is-invalid': submitted && hasShippingError('Email') }">
                        <div v-if="submitted && hasShippingError('Email')" class="invalid-feedback">
                            <span v-if="!$v.shipping.email.required">{{$t("shipping.email")}}</span>  
                            <span v-if="!$v.shipping.email.email">{{ $t("shipping.invEmail") }}</span>
                            <span v-if="errors.Email">{{errors.Email}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="inputContactNumber">{{ $t("checkout.phone") }}</label>
                        <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="shipping.phone" :class="{ 'is-invalid': submitted && hasShippingError('Phone') }"/>
                        <div v-if="submitted && hasShippingError('Phone')" class="invalid-feedback">
                            <span v-if="!$v.shipping.phone.required">{{$t("shipping.phone")}}</span>                            
                            <span v-if="errors.Phone">{{errors.Phone}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.company") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.companyName">
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.nif") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.vat" :class="{ 'is-invalid': submitted && hasShippingError('Vat') }">
                        <div v-if="submitted && hasShippingError('Vat')" class="invalid-feedback">
                            <span v-if="!$v.shipping.vat.required">{{$t("shipping.vat")}}</span>                            
                            <span v-if="errors.Vat">{{errors.Vat}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.country") }}</label>
                         <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.country" :class="{ 'is-invalid': submitted && hasShippingError('Country') }">
                         <div v-if="submitted && hasShippingError('Country')" class="invalid-feedback">
                            <span v-if="!$v.shipping.country.required">{{$t("shipping.country")}}</span>                            
                            <span v-if="errors.Country">{{errors.Country}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.address") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.street" :class="{ 'is-invalid': submitted && hasShippingError('Street') }">
                        <div v-if="submitted && hasShippingError('Street')" class="invalid-feedback">
                            <span v-if="!$v.shipping.street.required">{{$t("shipping.street")}}</span>                            
                            <span v-if="errors.Street">{{errors.Street}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.zip") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.zipCode" :class="{ 'is-invalid': submitted && hasShippingError('ZipCode') }">
                        <div v-if="submitted && hasShippingError('ZipCode')" class="invalid-feedback">
                            <span v-if="!$v.shipping.zipCode.required">{{$t("shipping.zipCode")}}</span>                            
                            <span v-if="errors.ZipCode">{{errors.ZipCode}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.location") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.state" :class="{ 'is-invalid': submitted && hasShippingError('State') }">
                        <div v-if="submitted && hasShippingError('State')" class="invalid-feedback">
                            <span v-if="!$v.shipping.state.required">{{$t("shipping.state")}}</span>                            
                            <span v-if="errors.State">{{errors.State}}</span>
                        </div> 
                    </div>
                    <div class="justify-content-center mb-3">
                        <label for="fname">{{ $t("checkout.city") }}</label>
                        <input class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.city" :class="{ 'is-invalid': submitted && hasShippingError('City') }">
                        <div v-if="submitted && hasShippingError('City')" class="invalid-feedback">
                            <span v-if="!$v.shipping.city.required">{{$t("shipping.city")}}</span>                            
                            <span v-if="errors.City">{{errors.City}}</span>
                        </div> 
                    </div>
                <div :class="{'d-none': activeTab != 1 && activeTab != 2 && activeTab != 3}" class="d-flex justify-content-end w-100" >
                    <button @click="handleSubmit" v-bind:class="{disabled: loading}" type="submit" class="btn btn-primary border-0">
                        <span v-show="!loading">{{ $t("save") }}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                    </button>
                </div>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
import {  mapState, mapActions } from "vuex";
import InputGetOrders from "../classes/InputGetOrders";
import { UTILS_HELPER } from "@/helpers/utils.js";
import Pagination from "@/components/Pagination.vue";
import VuePhoneNumberInput from 'vue-phone-number-input';
import AddressesDetailPopUp from "@/components/popups/AddressesDetailPopUp.vue";
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { required, email } from "vuelidate/lib/validators";
import Tooltip from '@/components/Tooltip.vue'
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: { Pagination, VuePhoneNumberInput, AddressesDetailPopUp, SpinnerLoading, Tooltip },
    metaInfo () {
      return { 
            title: `${this.$t("vueRouter.accountTitle")} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.myAccount", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.myAccount", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.myAccount", 'es')])},
            ]
        } 
    },
    data: function(){
        return{
            currentPage: 1,
            activeTab: 0,
            activeShow: -1,
            menuOptions:[
                {name:this.$t("myAccount.orders")},
                {name:this.$t("myAccount.interests")},
                {name:this.$t("myAccount.bilAdd")},
                {name:this.$t("myAccount.delAdd")},
                {name:this.$t("myAccount.logout")}
            ],
            productDetails:[],
            billingInfo: null,
            shippingInfo: null,
            submitted: false,
            loading: false,
            name: null,
            brand: null,
            invoice:{
                name: '',
                email: '',
                phone: '',
                vat: '',
                country: '',
                street: '',
                zipCode: '',
                state: '',
                city: '',
                type: 'Billing'
            },
            shipping:{
                name: '',
                email: '',
                phone: '',
                companyName: '',
                vat: '',
                country: '',
                street: '',
                zipCode: '',
                state: '',
                city: '',
                type: 'Shipping'
            },
            showAddressesPopUpForm: false,
            itemsPerPage: localStorage.itemsPerPage ?? 5,
        }
    },
    validations: {
      invoice: {
        name: { required },
        email: { required, email },
        phone: { required },
        vat: { required },
        country: { required },
        street: { required },
        zipCode: { required },
        state: { required },
        city: { required },
      },
      shipping: {
        name: { required },
        email: { required, email },
        phone: { required },
        vat: { required },
        country: { required },
        street: { required },
        zipCode: { required },
        state: { required },
        city: { required },
      }
    },
    computed:
    {
        ...mapState('account', ['brandType']),
        ...mapState({
            ordersOutput: state => state.orders.listOutput,
            orderListDetails: state => state.orders.orderOutput,
            addressInfoOutput: state => state.account.listOutput,
            updateInfo: state => state.account.processUpdate,
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        rows: function()
        {
            return this.ordersOutput ? this.ordersOutput.totalRecords : 1;
        },
        userInfo: function()
        {
            return this.addressInfoOutput && this.addressInfoOutput.data ? this.addressInfoOutput.data : {};
        },
        ordersInfo: function(){
            return this.ordersOutput ? this.ordersOutput : [];
        },
        orderDetails: function(){
            return this.orderListDetails && this.orderListDetails.data ? this.orderListDetails.data : {};
        },
        orders: function()
        {
            var result = [];

            if(this.ordersInfo && this.ordersInfo.data)
            {
                result = this.ordersInfo.data;
                result.forEach(order => 
                { 
                    order['createdAt'] = UTILS_HELPER.formatDate(order.createdAt);
                });
            }

            return result;
        },
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        }    
    },
    methods:
    {
        ...mapActions({
            getOrdersList: 'orders/getList',
        }),
        ...mapActions({
            getOrderDetails: 'orders/getDetail',
            clearAlert: 'alert/clear' 
        }),
        ...mapActions('account', ['updateBrand']),
        ...mapActions('account', ['updateProfile']),
        ...mapActions('account', ['getAddress']),
        ...mapActions('account', ['logout']),
        updateItemsRequested()
        {
            this.loading = true;
            // GET list of products
            const orderBy = 2;
            const propertyName = "id";
            const searchText = '';
            const userId = null;

            //Make request
            var input = new InputGetOrders(this.itemsPerPage, this.currentPage, orderBy, propertyName, searchText, userId);

            this.getOrdersList(input);
        },
        handleSubmit() {
            this.clearAlert();
            this.submitted = true;

            // Validations
            if(this.activeTab === 2){
                this.$v.invoice.$touch();
                if (this.$v.invoice.$invalid) {
                    return;
                }
            }
            else if(this.activeTab === 3){
                this.$v.shipping.$touch();
                if (this.$v.shipping.$invalid) {
                    return;
                }
            }

            // Submissions
            this.loading = true;
            if(this.activeTab === 1)
            {
                this.updateBrand(this.brand);
            } 
            else if (this.activeTab === 2)
            {
                const addresses = [this.invoice]
                this.updateProfile({addresses});
            } 
            else if (this.activeTab === 3)
            {
                const addresses = [this.shipping]
                this.updateProfile({addresses});
            } 
        },
        hasInvoiceError(fieldName)
        {
            return this.$v.invoice[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },    
        hasShippingError(fieldName)
        {
            return this.$v.shipping[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },    
        onSuccessAlert: function(index)
        {
            this.$emit("success-alert-requested", { title:`${this.productsInfo[index].name }`, message: this.$t("cart.addCart"), primaryButton: this.$t("cart.goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("cart.close")})   
        },
    },
    mounted: function()
    {
        // GET list of products
        this.updateItemsRequested(1, this.itemsPerPage);
        this.brand = this.brandType;
    },
    watch:{
        ordersOutput(){
            this.loading = false; 
            // Scroll to top when we changing the page in shop.
            setTimeout(function () {
                window.scrollTo(0, 0);
                },100);
        },
        alertType: function(val)
        {
            if(val != null)
            {
                this.loading = false;
            }
        },
        activeShow(val) {       
            if(this.orders[val]){
                let id = this.orders[val].id;
                this.getOrderDetails(id);
            }
        },
        activeTab(val) {
            switch (val) {
                case 0: 
                {
                    this.updateItemsRequested(1, this.itemsPerPage);
                    break;
                }
                // case 1:
                case 2:
                case 3:
                {
                    this.getAddress();
                    break;
                }
                case 4:
                {
                    this.logout()
                    break;
                }
            }
        }, 
        updateInfo(val){
            this.submitted = false;

            if(val)
            {
                this.$fire({
                    title: this.$t("success"),
                    type: "success"
                })
            }
        },
        userInfo(val){
            if(val != null)
            {
                this.name = val.name;
                this.brand = val.brand;
                if(val && val.addresses && val.addresses[0])
                {
                    this.billingInfo = val.addresses[0];
                    this.invoice.name = this.billingInfo.name;
                    this.invoice.email = this.billingInfo.email;
                    this.invoice.phone = this.billingInfo.phone;
                    this.invoice.companyName = this.billingInfo.companyName;
                    this.invoice.vat = this.billingInfo.vat;
                    this.invoice.country = this.billingInfo.country;
                    this.invoice.street = this.billingInfo.street;
                    this.invoice.zipCode = this.billingInfo.zipCode;
                    this.invoice.state = this.billingInfo.state;
                    this.invoice.city = this.billingInfo.city;
                }
                if(val && val.addresses && val.addresses[1])
                {
                    this.shippingInfo = val.addresses[1];
                    this.shipping.name = this.shippingInfo.name;
                    this.shipping.email = this.shippingInfo.email;
                    this.shipping.phone = this.shippingInfo.phone;
                    this.shipping.companyName = this.shippingInfo.companyName;
                    this.shipping.vat = this.shippingInfo.vat;
                    this.shipping.country = this.shippingInfo.country;
                    this.shipping.street = this.shippingInfo.street;
                    this.shipping.zipCode = this.shippingInfo.zipCode;
                    this.shipping.state = this.shippingInfo.state;
                    this.shipping.city = this.shippingInfo.city;
                }
            }
        },
        currentPage(){
            this.updateItemsRequested(1, this.itemsPerPage)    
        }
    }
}
</script>

<style scoped>
.verticalBar{
    border-left: 4px solid #17224d;
    height: 400px;
}
.vertical-address-bar{
    border-right: 2px solid #17224d!important;
}
.tab-btn{
    cursor: pointer;
    color: #17224d;
    opacity: 0.3;
}
.tab-btn.active{
    color: #17224d;
    opacity: 1;
}
a{
    text-decoration: none!important;
    color: #17224d!important;
}
.table > :not(:first-child) {
    border-color: white;
}
.table-title {
    color: #fff;
    background: #17224d;		
    padding: 15px 25px;
    border-radius: 3px 3px 0 0;
}
.table-title h2 {
    margin: 5px auto;
    font-size: 1.5rem;
}
table.table tr th, table.table tr td {
    border-color: white;
    padding: 12px 15px;
    vertical-align: middle;
}
table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}	
table.table td a {
    display: inline-block;
}
table.table td a.view {        
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-radius: 30px;
    text-align: center;
}
.border-bottom{
    border-bottom:  2px solid #17224d!important;
}
.pagination {
    float: right;
    margin: 0 0 5px;
    overflow: unset !important;
}
@media screen and (max-width: 767px) {
  .template{
    padding: 0px!important;
  }
}
</style>