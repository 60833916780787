<template>
    <div class="image container d-flex align-items-center justify-content-center">
        <div class="image-content d-flex flex-column justify-content-center">
            <!-- Close -->
            <div class="close-button cursor-pointer d-flex justify-content-end">
                <p @click="closePopUp"><img src="../assets/close.svg" alt="image" class="w-100"/></p>
            </div>
            <!-- file -->
            <vueper-slides ref="fullscreenGallery" class="no-shadow text-light" :visible-slides="1" :bullets="false" fractions progress :arrowsOutside="true">
                <!-- content -->
                <vueper-slide v-for="(file, index) in gallery" :key="index" :video="file.video ? embedLink(file.video) : null" :image="file.video ? file[1] : file"></vueper-slide>
            </vueper-slides>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    components:{VueperSlides, VueperSlide},
    props: {
        gallery: Array,
        startIndex: Number
    },
    mounted: function()
    {
        this.$refs.fullscreenGallery.goToSlide(this.startIndex);
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    destroyed() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
    methods:
    {
        closePopUp: function()
        {
            this.$emit("closeRequest");
        },
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('image')) {
                this.closePopUp();
            }

            this.drag = false;
        },
        // SAMEORIGIN problem - If you are getting this error for a YouTube video, rather than using the full url use the embed url.
        embedLink: function (link) {
            let genericUrl = "https://www.youtube.com/watch?v=";
            let replaceUrl = "https://www.youtube.com/embed/";

            if(genericUrl){
                return link.replace(genericUrl, replaceUrl); 
            }else{
                return link;
            }
        },
    },
    watch:
    {
        startIndex: function()
        {
            this.$refs.fullscreenGallery.goToSlide(this.startIndex);
        }
    }
}
</script>

<style scoped>
.close-button p
{
    position: fixed;
    right: 3%;
    top: 2%;
    width: 35px;
    cursor: pointer;
    font-size: 20px;
    color:white
}
.image{
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);  
  z-index: 999;
  margin: 0;
  max-width: unset!important;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  gap: 16px;
}
.image-content
{
    width: 80%;
    height: 100%;
    min-height: 50%;
}
img
{
    width: 100%;
}
>>>.vueperslides__progress{
position: unset;
z-index: 0;
height: unset;
color: unset;
}
>>>.vueperslides__progress > *{
    position: unset;
    background: unset;
    transition: unset;
}
>>>.vueperslides__fractions{
    top: 0%;
    left: unset;
    right: -15%;
    border-radius: unset;
    background: unset;
    border: unset;
    padding: 0;
    font-size: 35px;
}
>>>.vueperslides__fractions::first-letter {
  font-size: 150%;
}
>>>.vueperslide {
    background-size: contain;
    background-repeat: no-repeat;
}
>>>.vueperslides{
    height: 100%;
}
>>>.vueperslides__inner,
>>>.vueperslides__parallax-wrapper{
    height: 100%;
}
@media (max-width: 991px) {
  .image-content
    {
        height: 80%;
        width: 100%; 
    }
    >>>.vueperslides__fractions {
            top: -85px;
            right: -15px;
        }
}

@media (max-width: 575px) {
    .image-content
    {
        height: 50%;
        width: 100%; 
    }
    >>>.vueperslides{
        height: 50%;
        min-height: 140px;
    }
    >>>.vueperslides__arrow--prev,
    >>>.vueperslides__arrow--next{
        font-size: 13px;
    }
}
</style>