<template>
  <div class="simulator">
    <div class="container is-fluid">
      <!-- Product information -->
      <h1 class="mt-3 mb-0" v-if="productDetail">{{productDetail.name}}</h1>

      <!-- Step description -->
      <div class="columns mb-0 mt-1">
        <div class="column is-8">
          <step-description :current-step="settings.wizard.currentStep" />
        </div>
      </div>

      <!-- Steps -->
      <div class="columns">
        <div class="column is-8 stepper-parent">
          <stepper
            :steps="settings.wizard.steps"
            :current-step="settings.wizard.currentStep"
            @step="updateCurrentStep"
          />
        </div>
        <div class="column is-4">
          <div class="is-pulled-right">
            <download
              :downloading="loaders.downloading"
              @click="objectDownloadScreenshot()"
            />
          </div>
          <div class="is-pulled-right">
            <download-pdf
              :pdfFilePath="objectDownloadPdf"
            />
          </div>
          <div class="is-clearfix"></div>
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div id="simulator-root" class="column col-12 col-lg-6 col-xl-8">
          <!-- Canvas -->
          <div id="simulator-wrapper">
            <div class="is-relative">
              <template v-if="measuresObjectLoaded">
              <div id="measures-h">
                <span>{{ productDetail.length }} m</span>
              </div>
              <div id="measures-v">
                <span>{{ productDetail.height }} m</span>
              </div>
              </template>
              
              <div class="simulator-wrapper is-relative col-12">
                <div class="simulator-loading is-size-4" v-if="loaders.model">
                  <div class="icon mr-3">
                    <div class="fa fa-spin fa-spinner"></div>
                  </div>
                  <span> {{$t("simulator.renderModel")}} </span>
                </div>
                <canvas
                  id="simulator"
                  :class="{
                    'is-loading': loaders.model,
                    'zoom-settings': true
                  }"
                ></canvas>
              </div>
            </div>
          </div>

          <!-- Options -->
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="d-flex gap-5 my-4">
              <upload-background v-if="!$route.params.product" @background="objectBackgroundUpload" @resetBackground="resetBackground" :successRequest="successRequest" :loading="loaders.background" class="is-pulled-left"/>
              <reset-position class="reset-position-style flex-grow-1" @reset="resetPosition"/>
            </div>
            <div class="d-flex gap-5 my-4">
              <show-human :selected="humanObjectLoaded"  @showing="toggleHuman" /> 
              <show-measures :selected="measuresObjectLoaded" @showing="toggleMeasures" />
            </div>
          </div>
          <!-- Product information & link to product -->
          <div v-html="productDetail.shortDescription" class="mt-3 mb-0" v-if="productDetail && productDetail.shortDescription"></div>
          <router-link v-if="productDetail" :to="{ name: 'ProductDetails', params: {slug: productDetail.slug}}">{{$t('simulator.goToProductDetail')}}</router-link>
        </div>

        <!-- Content -->
        <div id="simulator-content" class="col-12 col-lg-6 col-xl-4 d-flex flex-column">
          <bottom-step-description
            :current-step="settings.wizard.currentStep"
          />
          <template
            v-if="
              settings.wizard.steps[settings.wizard.currentStep].component !==
              'undefined'
            "
          >
            <component
              :is="settings.wizard.steps[settings.wizard.currentStep].component"
              v-bind="computedCurrentStepWizardProps"
              v-on="computedCurrentStepWizardHandlers"
            />
          </template>
          <!-- Next -->
          <div class="flex-grow-1"/>
          <add-to-cart class="my-4"
            v-if="settings.wizard.currentStep === 5"
            :loading="addToCart"
            @click="addProductToCart()"
          />
          <next class="my-4"
            v-if="settings.wizard.currentStep !== 5 && !$route.params.product"
            :currentStep="settings.wizard.currentStep"
            @next="updateCurrentStep(++settings.wizard.currentStep)"
          />
          <next class="my-4"
            v-if="$route.params.product && settings.wizard.currentStep !== 4"
            :currentStep="settings.wizard.currentStep"
            @next="updateCurrentStep(++settings.wizard.currentStep)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Component imports:
import GoBack from "@/components/Simulator/Header/GoBack";
import AskHelp from "@/components/Simulator/Header/AskHelp";
import StepDescription from "@/components/Simulator/Header/StepDescription";
import ResetPosition from "@/components/Simulator/Header/Togglers/ResetPosition";
import ShowHuman from "@/components/Simulator/Header/Togglers/ShowHuman";
import ShowMeasures from "@/components/Simulator/Header/Togglers/ShowMeasures";
import Download from "@/components/Simulator/Header/Actions/Download";
import DownloadPdf from "@/components/Simulator/Header/Actions/DownloadPdf";
import UploadBackground from "@/components/Simulator/Header/Actions/UploadBackground";

// THREEJS imports:
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
// import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import Stepper from "@/components/Simulator/Stepper/index";

// mixins:
import { Simulator } from "../mixins/simulator/index";
import Next from "@/components/Simulator/Stepper/Actions/Next";
import AddToCart from "@/components/Simulator/Stepper/Actions/AddToCart";
import BottomStepDescription from "@/components/Simulator/Stepper/BottomStepDescription";

import { mapActions, mapState } from "vuex";
import router from "@/router";
import InputPostCart from "../classes/InputPostCart";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js"; 

export default {
  components: {
    BottomStepDescription,
    Next,
    AddToCart,
    Stepper,
    UploadBackground,
    Download,
    DownloadPdf,
    ResetPosition,
    ShowMeasures,
    ShowHuman,
    StepDescription,
    AskHelp,
    GoBack,
  },
      metaInfo () {
      var result =
      { 
          title: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")},
              {name: 'keywords', content: this.productDetail && this.productDetail.categories && this.productDetail.categories.length ? this.productDetail.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
              // Twitter Card
              {name: 'twitter:card', content: "summary"},
              {name: 'twitter:title', content: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay"},
              {name: 'twitter:description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")},
              {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              //Facebook OpenGraph
              {property: 'og:title', content: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay"},
              {property: 'og:site_name', content: 'FactoryPlay'},
              {property: 'og:type', content: 'website'},
              {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              {property: 'og:description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")}
          ]
      } 

      if(this.$route.params.slug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.simulator", 'pt')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.simulator", 'en')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.simulator", 'es')])}/${this.$route.params.slug}`}
          ];
      }

      return result;
  }, 
  mixins: [Simulator],
  data() {
    return {
      addToCart: false,
      canvas: null,
      renderer: null,
      composer: null,
      effectFXAA: null,
      camera: null,
      scene: null,
      controls: null,
      raycaster: null,
      objectModel: null,
      productDetail: null,
      humanPath: "/3d/models/human.obj",
      measuresPath: "/3d/models/measures.obj",
      humanObjectLoaded: false,
      measuresObjectLoaded: false,
      mouse: null,
      background: 0xbfbfbf,
      textures: {
        selection: null,
        options: {
          wood: "textures/crate.gif",
          another: "textures/b.png",
        },
      },
      dimensions: {
        window: {
          width: 0,
          heightL: 0,
        },
      },
      scheduledTimeout: null,
      isShow: false,
    };
  },
  computed: {
    ...mapState('carts', ['processTask']),

    getImages() {
      let images = {};

      // Get logos
      for(let selection in this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview)
      {
        let positions = this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[selection];
        let selections = Object.assign({}, positions);
        let self = this;

        Object.values(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[selection])
        .filter(function (imageSelection, key) {
          if (imageSelection !== false)
          {
            let scale = self.settings.wizard.steps[self.StepIdentifiers.IMAGES].scales[Object.keys(selections)[key]];
            Object.assign(images, { [Object.keys(selections)[key]]: {data: imageSelection.split(',')[1], scale: scale}});
          }  
        });
      }

      // Get patterns
      for(let selection in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview)
      {
        let positions = this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[selection];
        let selections = Object.assign({}, positions);

        Object.values(
          this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties
            .patterns_preview[selection]
        )
        .filter(function (imageSelection, key) {
          if (imageSelection !== false) Object.assign(images, { [Object.keys(selections)[key]]: {data: imageSelection.split(',')[1]} });
        });
      }

      return images;
    },
    getColors() {
      let colors = {};
      for(let selection in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections)
      {
        let positions = this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[selection];
        let selections = Object.assign({}, positions);
        
        Object.values(
          this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties
            .selections[selection]
        )
        .filter(function (colorSelection, key) {
          if (colorSelection !== false) Object.assign(colors, { [Object.keys(selections)[key]]: colorSelection });
        });
      }
      return colors;
    },
    computedCurrentStepWizardProps() {
      if (
        typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
          .properties !== "undefined" ||
        typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
          .preProcessedProperties !== "undefined"
      ) {
        let properties =
          typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
            .properties !== "undefined"
            ? this.settings.wizard.steps[this.settings.wizard.currentStep]
                .properties
            : {};

        let preProcessed =
          typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
            .preProcessedProperties !== "undefined"
            ? this.settings.wizard.steps[this.settings.wizard.currentStep]
                .preProcessedProperties
            : {};

        for (let property in preProcessed) {
          properties[property] =
            typeof this[preProcessed[property]] !== "undefined"
              ? this[preProcessed[property]]()
              : null;
        }

        return properties;
      } else {
        return {};
      }
    },

    computedCurrentStepWizardHandlers() {
      if (
        typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
          .handlers !== "undefined"
      ) {
        let handlers = {};
        for (let handler in this.settings.wizard.steps[
          this.settings.wizard.currentStep
        ].handlers) {
          if (
            typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
              .handlers[handler] === "function"
          ) {
            handlers[handler] =
              this.settings.wizard.steps[
                this.settings.wizard.currentStep
              ].handlers[handler];
          } else if (
            typeof this.settings.wizard.steps[this.settings.wizard.currentStep]
              .handlers[handler] === "string"
          ) {
            handlers[handler] =
              this[
                this.settings.wizard.steps[
                  this.settings.wizard.currentStep
                ].handlers[handler]
              ];
          }
        }
        return handlers;
      } else {
        return {};
      }
    }
  },
  methods: {
    ...mapActions({
      addCart: "carts/addCart",
    }),
    ...mapActions('carts', ['removeCart']),

    toggleHuman() {
      console.log(this.humanObjectLoaded)
      if (!this.humanObjectLoaded) {
        this.setupTHREEHumanObjectModel();
        console.log(this.setupTHREEHumanObjectModel())
      } else {
        this.removeTHREEHumanObjectModel();
      }
    },
    toggleMeasures() {
      if (!this.measuresObjectLoaded) {
        this.setupTHREEMeasuresObjectModel();
      } else {
        this.removeTHREEMeasuresObjectModel();
      }
    },
    goToCart() {
      router.push({ name: "Cart" });
    },
    updateCurrentStep(step) {
      this.settings.wizard.currentStep = +step;  
    },
    addProductToCart() {
        if(this.$route.params.id)
        {
          this.removeCart(this.$route.params.id);
        }
        this.addToCart = true;
        let body = new InputPostCart(
          this.productId,
          this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.units,
          this.settings.wizard.steps[this.StepIdentifiers.NOTES].properties.note,
          this.getColors,
          this.getImages
        );
        this.addCart(body); 
    },
    boot() {
      const self = this;

      self.canvas = document.getElementById("simulator");

      let wrapper = document.getElementById("simulator-wrapper");

      if (wrapper == null) {
        return false;
      }

      self.dimensions.window.width = wrapper.offsetWidth;

      self.dimensions.window.height = self.canvas.offsetHeight;

      self.setupTHREEObjects();

      self.setupTHREERenderer();

      self.setupTHREEScene();

      self.setupTHREECamera();

      self.setupTHREEControls();

      self.setupTHREESceneLights();

      self.setupTHREEObjectModels();

      self.setupTHREEComposer();

      self.nextAnimationFrame();

    },
    

    objLoaderProgressWatcher(xhr) {
      if (xhr.lengthComputable) {
        const percentComplete = (xhr.loaded / xhr.total) * 100;
        console.log(Math.round(percentComplete, 2) + "% downloaded");
      }
    },

    objLoaderErrorWatcher(error) {
      console.log("error loading obj", error);
    },

    setupTHREEHumanObjectModel() {
      const self = this;
      new OBJLoader(new THREE.LoadingManager()).load(
        self.humanPath,
        function (object) {
          // // Remove last children before add  the new ones
          object.position.y = -18;
          object.position.x = 40;

          let scale = 7 / (self.productDetail.height / 1.75); // ((2.5 * self.productDetail.height) / 5);

          object.scale.set(scale, scale, scale);
          self.humanObjectLoaded = true;
          self.scene.add(object);
          self.resetPosition();
        },
        self.objLoaderProgressWatcher,
        self.objLoaderErrorWatcher
      );
    },
    removeTHREEHumanObjectModel() {
      const self = this;

      self.scene.children = self.scene.children.filter(function (item) {
        return item.name !== "human";
      });
      this.humanObjectLoaded = false;
    },

    setupTHREEMeasuresObjectModel() {
      const self = this;

      this.resetPosition();

      this.$nextTick(() => {
        self.measuresObjectLoaded = true;
      })
      // new OBJLoader(new THREE.LoadingManager()).load(
      //   '/3d/models/h.obj',
      //   function (object) {
      //     object.position.y = -15;
      //
      //     object.name = "measures-h";
      //     self.measuresObjectLoaded = object;
      //     self.scene.add(object);
      //   },
      //   self.objLoaderProgressWatcher,
      //   self.objLoaderErrorWatcher
      // );
    },
    resetPosition() {
      const self = this;
      self.camera.rotation.set(-0.05309538581043926, 0.00433225389927189, 0.00023023836581970486)
      self.camera.position.set(0.2, 2.45, 46.1);
    },
    removeTHREEMeasuresObjectModel() {
      // const self = this;

      // self.scene.children = self.scene.children.filter(function (item) {
      //   return item.name !== "measures-h" && item.name !== 'measures-v';
      // });

      this.measuresObjectLoaded = false;
    },

    setupTHREEObjectModels() {
      const self = this;
      
      new OBJLoader(new THREE.LoadingManager()).load(
        self.objectPath,
        (object) => {
          // // Remove last children before add  the new ones
          object.position.y = -18;
          self.objectModel = object;
          self.scene.add(object);
          self.applyTexture();
        },
        self.objLoaderProgressWatcher,
        self.objLoaderErrorWatcher
      );
    },

    setupTHREEComposer() {
      const self = this;

      self.composer = new EffectComposer(self.renderer);

      const renderPass = new RenderPass(self.scene, self.camera);
      self.composer.addPass(renderPass);

      self.effectFXAA = new ShaderPass(FXAAShader);
      self.effectFXAA.uniforms["resolution"].value.set(
        1 / window.innerWidth,
        1 / window.innerHeight
      );
      self.composer.addPass(self.effectFXAA);
    },

    setupTHREEObjects() {
      const self = this;
      self.raycaster = new THREE.Raycaster();
      self.mouse = new THREE.Vector2();
    },

    setupTHREEScene() {
      const self = this;
      self.scene = new THREE.Scene();
      self.scene.background = new THREE.Color(self.background);
    },
    setupTHREERenderer() {
      const self = this;

      self.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: self.canvas,
        preserveDrawingBuffer: true,
      });

      self.renderer.shadowMap.enabled = true;

      self.renderer.setSize(
        self.dimensions.window.width,
        self.dimensions.window.height
      );
    },

    setupTHREECamera() {
      const self = this;

      self.camera = new THREE.PerspectiveCamera(
        75,
        self.dimensions.window.width / self.dimensions.window.height,
        1,
        90
      );
      self.camera.position.set(0.2, 2.45, 46.1);
    },

    setupTHREESceneLights() {
      const self = this;

      self.scene.add(new THREE.AmbientLight(0xaaaaaa, 0.2));

      const light = new THREE.DirectionalLight(0xddffdd, 0.6);
      light.position.set(1, 1, 1);
      light.castShadow = true;
      light.shadow.mapSize.width = 1024;
      light.shadow.mapSize.height = 1024;

      const d = 10;

      light.shadow.camera.left = -d;
      light.shadow.camera.right = d;
      light.shadow.camera.top = d;
      light.shadow.camera.bottom = -d;
      light.shadow.camera.far = 2000;

      self.scene.add(light);
    },

    setupTHREEControls() {
      const self = this;

      self.controls = new OrbitControls(self.camera, self.renderer.domElement);
      self.controls.minDistance = 0;
      self.controls.maxDistance = 120;
      self.controls.enablePan = false;
      self.controls.enableDamping = true;
      self.controls.dampingFactor = 0.05;
      self.controls.addEventListener('change', () => {
        if(self.scheduledTimeout === null && (
            this.measuresObjectLoaded || this.humanObjectLoaded

          )) {
          self.scheduledTimeout = setTimeout( () => {
            if(this.measuresObjectLoaded) { this.removeTHREEMeasuresObjectModel(); }
            if(this.humanObjectLoaded) { this.removeTHREEHumanObjectModel(); }
            self.scheduledTimeout = null;
          }, 750);
        }
      })
    },
    nextAnimationFrame() {
      requestAnimationFrame(this.nextAnimationFrame);
      this.composer.render();
    },
  },
  watch: {
    objectPath: function () {
      this.boot();
    },
    processTask: function(val) {
      if(val){
        this.addToCart = false;
        this.$emit("success-alert-requested", {
          title: `${this.productDetail.name}`,
          message: this.$t("cart.addCart"),
          primaryButton: this.$t("cart.goToCart"),
          primaryCallback: this.goToCart,
          secondaryButton: this.$t("close"),
        });
      }
    }
  },
};
</script>
<style scoped>
#measures-h {
  width: 56%;
  height: 2px;
  position: absolute;
  bottom: 106px;
  left: 22%;
  background: black;
  z-index: 999;
  text-align: center;
}
#measures-v {
  height: 56%;
  width: 2px;
  position: absolute;
  bottom: 22%;
  left: 20%;
  background: black;
  z-index: 999;
  text-align: center;
}
#measures-h::before {
  right: -5px;
  top: -4px;

} #measures-h::after {
    left: -5px;
    top: -4px;

  }

#measures-v::before {
  left: -4px;
  top: -5px;

} #measures-v::after {
    left: -4px;
    bottom: -5px;

  }

#measures-v::after,
#measures-v::before,
#measures-h::before,
#measures-h::after {
  height: 10px;
  width: 10px;
  position: absolute;
  content: " ";
  border-radius: 100%;
  background: black;
}
#measures-h span, #measures-v span {
  display: block;
  vertical-align: middle;
  padding-top: 10px;
}
#measures-v span {
  margin-top: 150px;
  width: 80px;
}
</style>
<style scoped >
/* Simulator */
switch[type="checkbox"].is-rtl + label::after,
.switch[type="checkbox"].is-rtl + label:after {
  left: auto;
  right: 1.625rem;
}

.switch[type="checkbox"]:checked.is-rtl + label::after,
.switch[type="checkbox"]:checked.is-rtl + label:after {
  right: 0.25rem;
  left: auto;
}
.simulator{
  margin-bottom: 3rem;
}
#simulator {
  width: 100%;
  max-width: 100%;
  max-height: 650px;
  pointer-events: none;
}

.zoom-settings{
  pointer-events: initial!important;
 }

@media screen and (min-width: 992px) {
  #simulator-root
  {
    position: sticky;
    top: 0;
    height: min-content;
  }
}

@keyframes simLoading {
  0% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 0%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 0%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 0%);
  }
  100% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 0%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 0%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 0%);
  }
  1% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 2%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 2%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 2%);
  }
  99% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 2%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 2%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 2%);
  }
  2% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 4%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 4%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 4%);
  }
  98% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 4%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 4%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 4%);
  }
  3% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 6%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 6%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 6%);
  }
  97% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 6%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 6%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 6%);
  }
  4% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 8%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 8%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 8%);
  }
  96% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 8%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 8%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 8%);
  }
  5% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 10%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 10%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 10%);
  }
  95% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 10%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 10%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 10%);
  }
  6% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 12%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 12%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 12%);
  }
  94% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 12%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 12%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 12%);
  }
  7% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 14%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 14%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 14%);
  }
  93% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 14%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 14%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 14%);
  }
  8% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 16%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 16%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 16%);
  }
  92% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 16%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 16%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 16%);
  }
  9% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 18%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 18%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 18%);
  }
  91% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 18%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 18%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 18%);
  }
  10% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 20%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 20%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 20%);
  }
  90% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 20%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 20%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 20%);
  }
  11% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 22%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 22%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 22%);
  }
  89% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 22%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 22%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 22%);
  }
  12% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 24%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 24%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 24%);
  }
  88% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 24%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 24%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 24%);
  }
  13% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 26%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 26%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 26%);
  }
  87% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 26%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 26%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 26%);
  }
  14% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 28%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 28%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 28%);
  }
  86% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 28%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 28%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 28%);
  }
  15% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 30%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 30%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 30%);
  }
  85% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 30%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 30%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 30%);
  }
  16% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 32%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 32%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 32%);
  }
  84% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 32%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 32%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 32%);
  }
  17% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 34%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 34%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 34%);
  }
  83% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 34%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 34%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 34%);
  }
  18% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 36%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 36%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 36%);
  }
  82% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 36%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 36%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 36%);
  }
  19% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 38%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 38%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 38%);
  }
  81% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 38%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 38%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 38%);
  }
  20% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 40%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 40%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 40%);
  }
  80% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 40%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 40%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 40%);
  }
  21% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 42%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 42%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 42%);
  }
  79% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 42%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 42%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 42%);
  }
  22% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 44%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 44%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 44%);
  }
  78% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 44%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 44%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 44%);
  }
  23% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 46%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 46%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 46%);
  }
  77% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 46%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 46%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 46%);
  }
  24% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 48%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 48%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 48%);
  }
  76% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 48%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 48%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 48%);
  }
  25% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 50%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 50%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 50%);
  }
  75% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 50%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 50%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 50%);
  }
  26% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 52%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 52%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 52%);
  }
  74% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 52%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 52%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 52%);
  }
  27% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 54%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 54%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 54%);
  }
  73% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 54%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 54%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 54%);
  }
  28% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 56%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 56%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 56%);
  }
  72% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 56%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 56%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 56%);
  }
  29% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 58%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 58%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 58%);
  }
  71% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 58%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 58%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 58%);
  }
  30% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 60%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 60%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 60%);
  }
  70% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 60%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 60%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 60%);
  }
  31% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 62%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 62%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 62%);
  }
  69% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 62%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 62%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 62%);
  }
  32% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 64%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 64%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 64%);
  }
  68% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 64%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 64%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 64%);
  }
  33% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 66%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 66%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 66%);
  }
  67% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 66%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 66%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 66%);
  }
  34% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 68%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 68%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 68%);
  }
  66% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 68%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 68%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 68%);
  }
  35% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70%);
  }
  65% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 70%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70%);
  }
  36% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 72%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 72%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 72%);
  }
  64% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 72%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 72%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 72%);
  }
  37% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 74%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 74%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 74%);
  }
  63% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 74%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 74%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 74%);
  }
  38% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 76%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 76%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 76%);
  }
  62% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 76%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 76%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 76%);
  }
  39% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 78%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 78%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 78%);
  }
  61% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 78%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 78%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 78%);
  }
  40% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 80%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 80%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 80%);
  }
  60% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 80%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 80%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 80%);
  }
  41% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 82%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 82%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 82%);
  }
  59% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 82%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 82%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 82%);
  }
  42% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 84%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 84%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 84%);
  }
  58% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 84%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 84%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 84%);
  }
  43% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 86%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 86%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 86%);
  }
  57% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 86%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 86%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 86%);
  }
  44% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 88%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 88%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 88%);
  }
  56% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 88%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 88%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 88%);
  }
  45% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 90%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 90%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 90%);
  }
  55% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 90%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 90%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 90%);
  }
  46% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 92%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 92%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 92%);
  }
  54% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 92%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 92%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 92%);
  }
  47% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 94%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 94%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 94%);
  }
  53% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 94%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 94%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 94%);
  }
  48% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 96%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 96%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 96%);
  }
  52% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 96%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 96%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 96%);
  }
  49% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 98%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 98%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 98%);
  }
  51% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 98%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 98%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 98%);
  }
  50% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 100%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 100%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 100%);
  }
  50% {
    background: linear-gradient(
        217deg,
        rgba(255, 0, 0, 0.8),
        rgba(255, 0, 0, 0) 100%
      ),
      linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 100%),
      linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 100%);
  }
}

.simulator-loading {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  animation: 5s infinite simLoading;
  transition: all 500ms;
  background: linear-gradient(
      217deg,
      rgba(255, 0, 0, 0.8),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
    linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
  font-weight: bolder;
}
canvas.is-loading {
  line-height: 0 !important;
  filter: grayscale(1);
  opacity: 0.85;
}
</style>