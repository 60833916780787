import { CATEGORY_SERVICE } from "@/services/categories.service.js";

const state = { listOutput:{}, processTask: false};

const actions = {
    getBrand({ dispatch, commit }, inputgetCategories) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        CATEGORY_SERVICE.getBrand(inputgetCategories)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

};

const mutations = {
    /* List */
    listRequest() {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
};


export const categories = {
    namespaced: true,
    state,
    actions,
    mutations
};