<template>
  <section id="search">
    <SpinnerLoading v-if="!loaded"/>
    <div class="d-flex flex-wrap align-items-center" :style="{minHeight: '100vh'}" v-else>
        <ProductThumb @success-alert-requested="onSuccessAlert(index)" v-for="(product,index) in products" :key="index" :product="product" @clickModel="sendClickModel(index)" @click="sendClick(index)"></ProductThumb>
        <NoResultsPlaceholder v-if="!products.length" class="mb-5"></NoResultsPlaceholder>
    </div>
    <Pagination class="mb-2" v-if="loaded && products.length" :rows="rows" :itemsPerPage="itemsPerPage" v-model="currentPage"></Pagination>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InputGetProducts from "@/classes/InputGetProducts";
import SpinnerLoading from "@/components/SpinnerLoading";
import ProductThumb from "@/components/ProductThumb.vue";
import InputPostCart from "../classes/InputPostCart";
import Pagination from "@/components/Pagination.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import { API_HELPER } from "@/helpers/api.js";

export default {
    name: "Home",
    components: { SpinnerLoading, ProductThumb, Pagination, NoResultsPlaceholder },
     metaInfo () {
      return { 
            title: this.$route && this.$route.query && this.$route.query.query ? `${this.$route.query.query} - FactoryPlay` : `${this.$i18n.t("searchResultsTitle")} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.searchResults", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.searchResults", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.searchResults", 'es')])},
            ]
        } 
    },
    data() {
        return {
            loaded: false,
            itemsPerPage: localStorage.itemsPerPage ?? 9,
            currentPage: 1
        };
    },
    mounted: function()
    {
        if(!this.$route.query.query)
        {
            this.$router.push({name: 'Home'});
        }

        // GET list of products
        this.updateItemsRequested();
    },
    computed:
    {
        ...mapState({
            productsOutput: state => state.products.listOutput,
        }),
        products: function()
        {
            return this.productsOutput && this.productsOutput.data && this.productsOutput.data.products ? this.productsOutput.data.products : [];
        },
        rows: function()
        {
            return this.productsOutput ? this.productsOutput.totalRecords : 1;
        },
    },
    methods: {
        ...mapActions({
            getProductsList: 'products/getList',
        }),
        updateItemsRequested(currentPage)
        {
            if(currentPage) this.currentPage = currentPage;

            // GET list of products
            const orderBy = 1;
            const propertyName = "id";
            const searchText = this.$route.query.query;
            const status = 1;

            //Make request
            var input = new InputGetProducts(this.itemsPerPage, this.currentPage, orderBy, propertyName, searchText, status);

            this.getProductsList(input);
        },
        sendClick(index){
            this.$router.push({name: "ProductDetails", params: {product: this.products[index], slug: this.products[index].slug}}); 
        },
        goToCart()
        {
            this.$router.push({name : 'Cart'});
        },
        onSuccessAlert: function(index)
        {
            this.addCart(new InputPostCart (this.products[index].id, 1));
            this.$emit("success-alert-requested", { title:`${this.products[index].name }`, message: this.$t("addCart"), primaryButton: this.$t("goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("close")})   
        },  
        sendClickModel(index){
            this.$router.push({name: "Simulator", params: {slug: this.products[index].slug}}); 
        },  
    },
    watch: 
    {
        $route(to) {
            if (to.name == "SearchResults") {
                this.updateItemsRequested(1);
            }
        },
        productsOutput: function(val)
        {
            if(val)
            {
                this.loaded = true;
            }
        },
        currentPage(){
            this.updateItemsRequested();    
        } 
    }
}
</script>

<style scoped>

</style>