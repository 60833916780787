import { USER_SERVICE } from "@/services/user.service.js";
import router from '@/router/index';

const user = JSON.parse(localStorage.getItem('user'));
const brandType = localStorage.getItem('brandType') && localStorage.getItem('brandType') != 'undefined' ? localStorage.getItem('brandType') : null;

const state = user
    ? { status: { loggedIn: true }, user,  listOutput: {}, processTask: undefined, processUpdate: null, processConfirm: null, lastAdressesOutput:{}, brandType }
    : { status: {}, user: null,  listOutput: {}, processTask: undefined, processUpdate: null, processConfirm: null, lastAdressesOutput:{}, brandType };

const actions = {
    login({ dispatch, commit }, { email, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { email });
        
        USER_SERVICE.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.go(-1);
                },
                error => {
                    commit('loginFailure', user);                
                    dispatch('alert/error', error, { root: true });

                }

            );
    },
    signup({ dispatch, commit }, input) {
        dispatch('alert/clear', null, { root: true });
        commit('signupRequest');
        
        USER_SERVICE.signup(input)
            .then(
                user => {
                    commit('signupSuccess', user);
                    dispatch('alert/success', user, { root: true });

                },
                error => {
                    commit('signupFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestResetToken({ dispatch, commit }, username) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        USER_SERVICE.requestResetToken(username)
            .then(
                output => {
                    commit('linkSuccess', output);
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    resetPassword({ dispatch, commit }, {username, password, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('resetPasswordRequest');

        USER_SERVICE.resetPassword(username, password, token)
            .then(
                output => {
                    commit('resetPasswordSuccess', output);
                },
                error => {
                    commit('resetPasswordFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    confirmAccount({ dispatch, commit }, {password, username, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('confirmAccountRequest');

        USER_SERVICE.confirmAccount(password, username, token)
            .then(
                output => {
                    commit('confirmAccountSuccess', output);
                },
                error => {
                    commit('confirmAccountFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestToken({ dispatch, commit }, username) {
        dispatch('alert/clear', null, { root: true });
        commit('requestTokenRequest');

        USER_SERVICE.requestToken(username)
            .then(
                output => {
                    commit('requestTokenSuccess', output);
                },
                error => {
                    commit('requestTokenFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    updateProfile({ dispatch, commit }, profile) {
        dispatch('alert/clear', null, { root: true });
        commit('updateProfileRequest');
        
        USER_SERVICE.updateProfile(profile)
        .then(
            output => {
                    commit('updateProfileSuccess',output);
                    dispatch('alert/success', output, { root: true });
                }, 
                error => {
                    commit('updateProfileFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getAddress({ dispatch, commit }, addressInfo) {
        dispatch('alert/clear', null, { root: true });
        commit('addressInfoRequest');
        
        USER_SERVICE.getAddress(addressInfo)
        .then(
            output => {
                    commit('addressInfoSuccess',output);
                    dispatch('alert/success', output, { root: true });
                }, 
                error => {
                    commit('addressInfoFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getLastAddresses({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('lastAddressesInfoRequest');
        
        USER_SERVICE.getLastAddresses()
        .then(
            output => {
                    commit('lastAddressesInfoSuccess',output);
                    dispatch('alert/success', output, { root: true });
                }, 
                error => {
                    commit('lastAddressesInfoFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    
    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('logout');

        USER_SERVICE.logout();
    },
    updateBrand({ dispatch, commit }, brand)
    {
        dispatch('alert/clear', null, { root: true });

        commit('updateBrandRequest', brand);

        // Save the profile if the user is logged in
        if(state.user)
        {
            actions.updateProfile({ dispatch, commit }, {brand});
        }
    }
};

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;

        // login successful if there's a jwt token in the response
        if (user.data && user.data.accessToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            if(user.data.brand)
            {
                state.brandType = user.data.brand;
                localStorage.setItem('brandType', user.data.brand);        
            }
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    /* Signup */
    signupRequest(state)
    {
        state.status = null;
    },
    signupSuccess(state)
    {
        state.status = {registered: true};
    },
    signupFailure(state)
    {
        state.status = false;
    },
    /* Request link */
    linkRequest(state) {
        state.processTask = undefined;
    },
    linkSuccess(state) {
        state.processTask = true;
    },
    linkFailure(state) {
        state.processTask = false;
    },
    /* Reset password */
    resetPasswordRequest(state) {
        state.processTask = null;
    },
    resetPasswordSuccess(state) {
        state.processTask = true;
    },
    resetPasswordFailure(state) {
        state.processTask = false;
    },
    /* confirmAccount */
    confirmAccountRequest(state) {
        state.processConfirm = null;
    },
    confirmAccountSuccess(state) {
        state.processConfirm = true;
    },
    confirmAccountFailure(state) {
        state.processConfirm = false;
    },
    /* requestToken */
    requestTokenRequest(state) {
        state.processTask = null;
    },
    requestTokenSuccess(state) {
        state.processTask = true;
    },
    requestTokenFailure(state) {
        state.processTask = false;
    },
    /* Profile */
    updateProfileRequest(state) {
        state.processUpdate = null;
    },
    updateProfileSuccess(state) {
        state.processUpdate = true;
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    updateProfileFailure(state) {
        state.processUpdate = false;
    },
    /* Address */
    addressInfoRequest(state) {
        state.listOutput = null;
    },
    addressInfoSuccess(state, output) {
        state.listOutput = output;
    },
    addressInfoFailure(state) {
        state.listOutput = false;
    },
    /* */
    /* last Addresses */
    lastAddressesInfoRequest(state) {
        state.lastAdressesOutput = null;
    },
    lastAddressesInfoSuccess(state, output) {
        state.lastAdressesOutput = output;
    },
    lastAddressesInfoFailure(state) {
        state.lastAdressesOutput = false;
    },
    /* */
    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');

        router.go('/Login'); // Force all modules to reset state
    },
    updateBrandRequest(state, brand) {
        state.brandType = brand;
        localStorage.setItem('brandType', brand);
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};