<template>
  <div class="container-fluid p-0">
        <div class="div-title cursor-pointer" @click="brandClickedInflate">
            <!-- <span class="title-style-inflate"><b>inflate</b> fun</span>
            <img class="img-style-inflate" src="@/assets/svg/heroFormInflate.svg" alt=""/> -->
            <img class="img-style-inflate" src="@/assets/shop/heroFormInflate.png" alt=""/>
        </div>
        <div class="div-title cursor-pointer" @click="brandClickedActive">
            <!-- <span class="title-style-active"><b>active</b> brands</span>
            <img class="img-style-active" src="@/assets/svg/heroFormActive.svg" alt=""/> -->
            <img class="img-style-active" src="@/assets/shop/heroFormActive.png" alt=""/>
        </div>
    <!-- Slides -->
        <vueper-slides ref="mainShop" class="slider no-shadow flex-grow-1 mb-0" 
          v-if="products && products.length"
          :bullets="false" 
          :arrows="false" 
          :touchable="false" 
          :visibleSlides="1" 
          :disableArrowsOnEdges="true" 
          :lazy="true" 
          fixed-height="600px"
          :autoplay="true"
        >        
          <vueper-slide v-for="(product, index) in products" :key="index" :link="getFullUrl(index)">
              <template v-slot:content>
                <img class="img-fluid img-settings cursor-pointer" :src="url + product.photos[0]" alt=""/>
              </template>
          </vueper-slide>
        </vueper-slides>
      <!-- End Slides -->
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions } from "vuex";
export default {
  components: {VueperSlides, VueperSlide },
  data: function () {
    return {
        url: process.env.VUE_APP_URL_ROOT,
        baseUrl: process.env.VUE_APP_FRONT,
        quantity: 1,
        hover: false,
        dynamicHeight: null,
        currentSlide: 0
    };
  },
  props: {
    products: Array,
  },
  methods: {
    ...mapActions('account', ['updateBrand']),
    ...mapActions('carts', ['addCart']),
    getFullUrl: function(index)
    {
      return `${this.baseUrl}/Product/${this.products[index].slug}`;
    },
    brandClickedInflate()
    {
        var brand = 'InflateYourImagination'
        this.updateBrand(brand);
        this.$router.push({name: "Shop", query: {brand: brand} });
        // this.$router.push({name: "Shop", query: {type: 'InflateYourImagination'} });
    },
    brandClickedActive()
    {
        var brand = 'ActiveBrands'
        this.updateBrand(brand);
        this.$router.push({name: "Shop", query: {brand: brand} });
        // this.$router.push({name: "Shop", query: {type: 'ActiveBrands'} });
    },
  },
}
</script>

<style scoped>
a{
    text-decoration: none;
}
.boxes-container{
  height: 300px;
  flex-wrap: wrap;
}
.box{
  width: 100%;
}
.img-settings{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#product-text{
  background-color:#17224d;
}
.div-title{
  position: relative;
}
.img-style-inflate{
 position: absolute;
  z-index: 2;
  height: 75px;
  top: 65px;
}
.img-style-active{
   position: absolute;
  z-index: 2;
  height: 75px;
  top: 165px;
}
.title-style-inflate{
  color: white;
  font-size: 20px;
  position: absolute;
  bottom: -75px;
  z-index: 3;
  left: 10px;
  top: 80px;
}
.title-style-active{
  color: white;
  font-size: 20px;
  position: absolute;
  bottom: -75px;
  z-index: 3;
  left: 10px;
  top: 180px;
}
</style>