<template>
  <section>
    <SpinnerLoading v-if="cartOutput == null"/>
      <div class="row m-0 cart-empty align-content-center" v-if="cart.length == [] && cartOutput != null">
        <h1 class="d-flex justify-content-center cartEmpty-style">{{$t("cart.cartEmpty")}}</h1>
        <router-link class="d-flex justify-content-center" :to="{name: 'Shop'}"><button class="btn btn-primary mt-5 mb-5 border-0">{{$t("cart.goToShop")}}</button></router-link>
      </div>
      <div v-if="cartOutput" class="container mt-5 mb-5">
        <div class="row">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ $t("cart.productName") }}</th>
                <th scope="col">{{ $t("cart.quantity") }}</th>
                <th scope="col">{{ $t("cart.value") }}</th>
                <th class="float-end" scope="col">{{ $t("cart.actions") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(productDetail, index) in cart.products" :key="index">
                <th class="product-color" scope="row"><router-link :to="{ name: 'ProductDetails', params: {slug: productDetail.slug}}">{{productDetail.name}}</router-link></th>
                <td><input type="number" @change="updateQuantity(productDetail, productDetail.quantity)" :onkeyup="productDetail.quantity == 0 ? productDetail.quantity = 1 : productDetail.quantity" :onInput="productDetail.quantity = Math.abs(productDetail.quantity)" v-model="productDetail.quantity" :disabled="loading" id="number"/></td>
                <td>{{productDetail.price}}€</td>
                <td class="float-end">
                  <p class="m-0 p-0"><router-link :to="{ name: 'ProductDetails', params: {slug: productDetail.slug}}">{{ $t("cart.viewProduct") }}</router-link></p>
                  <p class="m-0 p-0" v-if="Object.keys(productDetail.colors).length || Object.keys(productDetail.images).length"><router-link :to="{ name: 'Simulator', params:{ slug: productDetail.slug, id: productDetail.id, notes: productDetail.notes, colors: productDetail.colors, images: productDetail.images, quantity: productDetail.quantity}}">{{$t("cart.editSim")}}</router-link></p>
                  <!-- Button trigger modal -->
                  <p class="m-0 p-0"><a class="cursor-pointer" @click="removeTaskCart(productDetail.id, index)" :disabled="loading">{{ $t("cart.delete") }}</a></p>  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-12 col-lg-6" :style="{padding: '0px'}">
            <table class="table">
            <thead>
              <tr>
                <th scope="col" colspan="3">{{ $t("cart.subtotal") }}</th>
                <th class="float-end" scope="col">{{cart.subTotal}}€</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(productDetail, index) in cart.products" :key="index">
                <th v-if="productDetail.quantityDiscount" scope="row" colspan="3">
                  <span>{{ $t("cart.discQuantity") }} ({{productDetail.name}})</span> 
                </th>
                <th v-if="productDetail.quantityDiscount" scope="row" class="float-end">{{productDetail.quantityDiscount.toFixed(2)}}€</th>
              </tr>
              <tr v-if="cart.discount">
                <th scope="row" colspan="3">
                  <span>{{ $t("cart.disc") }}</span> 
                </th>
                <th scope="row" class="float-end">{{cart.discount}}€</th>
              </tr>
              <tr>
                <th scope="row" colspan="3">
                  <span>{{ $t("cart.iva") }}</span> 
                  <br>
                  <span id="span-color">{{ $t("cart.ivaDetail") }}</span>
                </th>
                <th scope="row" class="float-end">{{cart.taxes ? cart.taxes.toFixed(2) : 0}}€</th>
              </tr>
              <tr>
                <th scope="row" class="d-flex pb-5">
                  {{ $t("cart.shipp") }}
                </th>
                <th scope="row" colspan="3">
                  <div class="float-end" :style="{margin: '10px auto', textAlign: 'right'}" v-for="shipping in cart.shippings" :key="shipping.type">
                    <input class="mt-1 mx-2" type="radio" v-model="selectedShipping" :value="shipping.type">
                    <label for="">
                      {{shipping.type == "Normal" ? $t("cart.shippNormal") : $t("cart.shippExpress")}}
                      ({{shipping.days}} 
                      {{$t("cart.workingDays")}})
                    </label>
                    <span class="price-style">{{shipping.price}}€</span>
                  </div>        
                </th>
              </tr>
              <tr>
                <th scope="row" colspan="3">
                  <span>{{ $t("cart.total") }}</span> 
                </th>
                <th scope="row" class="float-end">{{cart.total ? cart.total.toFixed(2) : 0}}€</th>
              </tr>
            </tbody>
          </table>
            <div class="row justify-content-center">
              <button class="btn btn-primary mt-5 border-0" :style="{width: '200px'}" @click="cartCheckout(cart.shippings)">{{ $t("cart.checkout") }}</button>
            </div>
          </div>
        </div>
      </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InputGetShipping from "@/classes/InputGetShipping";
import SpinnerLoading from '@/components/SpinnerLoading.vue';
import { API_HELPER } from "@/helpers/api.js";

export default {
  components: {
    SpinnerLoading
  },
     metaInfo () {
      return { 
            title: `${this.$t("vueRouter.cartTitle")} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.cartDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.cart", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.cart", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.cart", 'es')])},
            ]
            
        } 
    },
    data: function(){
        return{
            selectedShipping: "Normal",
            loading: false,
        }
    },
    computed:
    {
        ...mapState({
            cartOutput: state => state.carts.listOutput
        }),
        ...mapState("carts", ["processTask"]),
        ...mapState("carts", ["removeTask"]),
        cart: function(){
          return this.cartOutput && this.cartOutput.data ? this.cartOutput.data : [];
        },
    },
    methods:{
      ...mapActions({
        getCart: 'carts/getList',
        }),
        ...mapActions("carts", ["updateCart"]),
        ...mapActions('carts', ['removeCart']),
        ...mapActions('carts', ['setSelectedShipping']),
        invokeGetCart()
        {
          //Make request
          this.getCart(new InputGetShipping(this.selectedShipping));
        },
        updateQuantity(product, quantity) {
            {
              this.loading = true;
              this.updateCart({product, quantity });
            }
          },
        removeTaskCart(id, index)
        {
          this.$emit("delete-alert-requested", { title:`${this.cart.products[index].name}` , message: this.$t("cart.removeCart"), primaryCallback: () =>
          {
            this.loading = true;
            this.removeCart(id);
          }, secondaryButton: this.$t("noBtn")});
        },
        cartCheckout(selectedShipping)
        {
          this.setSelectedShipping(selectedShipping);
          this.$router.push({name: "CheckoutPage", params: { shippings: selectedShipping}});
        }
    },
    mounted: function()
    {
      // GET list of cart 
        this.invokeGetCart();      
    },
    watch: {
      selectedShipping: function() {
        this.getCart(new InputGetShipping(this.selectedShipping));
      },
      processTask: function(val)
      {
        if(val)        
        {
          this.loading = false;
          this.invokeGetCart();
        }
        setTimeout(function () {
          window.scrollTo(0, 0);
        },100);
      },
      removeTask: function(val)
      {
        if(val)
        {
          this.loading = false;
          this.invokeGetCart();
        }
      },   
    }
}
</script>

<style scoped>
.cart-empty{
  height: 100vh;
}
.product-color a, td{
  text-decoration: none;
  color: #17224d;
}
a{
  text-decoration: none;
  color: #17224d;
}
table {
  color: #dbdbdb;
}
tr{
  border-bottom:  1px solid #dbdbdb!important;
}
label{
  display: inline;
}
#number {
  width: 3em;
}
#span-color{
  color: #17224d;
}
#checkbox-style{
  margin-top: 6px;
  margin-right: 6px;
}
.cartEmpty-style{
  color: #17224d;
}
.table > :not(caption) > * > * {
    border-bottom-width: 0px!important;
}
</style>