export const SimulatorHelpers = {
    methods: {
        /**
         * Converts a base64 file into a Blob
         * @param base64
         * @returns {Blob}
         */
        base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        },

        /**
         * Converts an ArrayBuffer to Blob
         * @param buffer
         * @returns {Blob}
         * @constructor
         */
        ArrayBufferToBlob(buffer) {
            return new Blob([new Uint8Array(buffer)]);
        },

        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },

        dataURLtoFile(dataurl, filename) {
            
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = Buffer.from(arr[1], 'base64'),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type: mime});
        },

        /**
         * Handles the download of a base64 file (strData) on the browser.
         * @param strData
         * @param filename
         * @param callback
         */
        handleFileBrowserDownload(strData, filename, callback = function () {
        }) {
            var link = document.createElement('a');
            if (typeof link.download === 'string') {
                document.body.appendChild(link); //Firefox requires the link to be in the body
                link.download = filename;
                link.href = strData;
                link.click();
                document.body.removeChild(link);
                callback();
            } else {
                window.location.replace(link);
                callback();
            }
        },
    }
};