export default class InputGetProducts
{
  constructor(pageSize, pageNumber, orderBy, propertyName, searchText, status, category, highlighted, brand, searchInSubcategories, inStock) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.searchText = searchText;
    this.status = status;
    this.category = category;
    this.highlighted = highlighted;
    this.brand = brand;
    this.searchInSubcategories = searchInSubcategories;
    this.inStock = inStock;
  }    
}