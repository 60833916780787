<template>
    <BasePopUp @detailsClosed="closeConfirmRequested" :modelTitle="title">
            <template v-slot:content>
                <p class="mb-0 text-left" v-html="message"/>
            </template>
            <template v-slot:footer>
                <button class="btn btn-secondary border-0 mx-2 px-3" @click="secondaryButtonClicked" v-if="secondaryButtonText">{{secondaryButtonText}}</button>
                <button class="btn btn-primary border-0 mx-2 px-3" @click="primaryButtonClicked">{{primaryButtonText}}</button>
            </template>
    </BasePopUp>
</template>

<script>
import i18n from '@/helpers/i18n';
import BasePopUp from './BasePopUp.vue';

export default {
    components: {
        BasePopUp,
    },
    props: {
        title: String,
        message: String,
        primaryButtonText:
        {
            type: String,
            default: i18n.t("yesBtn")
        },
        primaryButtonCallback: { type: Function },
        secondaryButtonText:
        {
            type: String,
            default: null
        },
        secondaryButtonCallback: { type: Function }
    },
    methods:
    {
        primaryButtonClicked: function()
        {
            this.$emit("primary-click");
            if(this.primaryButtonCallback) this.primaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        secondaryButtonClicked: function()
        {
            this.$emit("secondary-click");
            if(this.secondaryButtonCallback) this.secondaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        closeConfirmRequested()
        {       
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
    }
}
</script>

<style scoped>
</style>