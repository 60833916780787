<template>
  <div class="field note-step">
    <div class="control">
      <textarea :disabled="$route.params.product" name="note" rows="3" v-model="the_note" class="textarea" style="resize: none;"></textarea>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotesStep',
  watch: {
    the_note:
    {
      handler(val)
      {
        this.$emit('note', this.the_note);
        if(this.$route.params.notes)
        {
          this.$route.params.notes = val;
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      the_note: this.$route.params.notes ? this.$route.params.notes : this.$route.params.product ? this.$route.params.product.notes : '',
    };
  },
}
</script>