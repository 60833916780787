<template>
  <section>
      <div class="container-fluid">
        <SpinnerLoading v-if="addressInfoOutput == null || cartOutput == null || orderOutput == null"/>
            <div v-if="!submitted || !isconcluded || !paymentMethod || ((!paymentMethod == 'BankTransfer' || !paymentMethod == 'MBWay') && !info)" class="row">
                <div class="col-lg-6 mt-5 mb-5" id="col-margin-border">
                        <div class="d-flex justify-content-center">
                            <h4 class="register-style cursor-pointer" v-bind:class="{ 'active': action == INVOICE }" @click="action = INVOICE">{{ $t("checkout.invoice") }}</h4>
                            <div class="vertical-line"></div>
                            <h4 class="login-style cursor-pointer" v-bind:class="{ 'active': action == SHIPPING }" @click="action = SHIPPING">{{ $t("checkout.shipping") }}</h4>
                        </div>
                    <div class="col-12 justify-content-center d-flex">
                        <!-- Billing address --> 
                        <form class="mt-5 w-75" @submit.prevent="handleOrderSubmit">
                            <div v-if="action == INVOICE">
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.name") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.name" :class="{ 'is-invalid': submitted && hasErrorInvoice('Name') }">
                                    <div v-if="submitted && hasErrorInvoice('Name')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.name.required">{{$t("orders.valName")}}</span>
                                        <span v-if="errors.Name">{{errors.Name}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.email") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.email" :class="{ 'is-invalid': submitted && hasErrorInvoice('Email') }">
                                    <div v-if="submitted && hasErrorInvoice('Email')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.email.required">{{$t("orders.valEmail")}}</span>
                                        <span v-if="errors.Email">{{errors.Email}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="inputContactNumber">{{ $t("checkout.phone") }}</label>
                                    <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="invoice.phone"/>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.company") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.companyName">
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.nif") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.vat" :class="{ 'is-invalid': submitted && hasErrorInvoice('Vat') }">
                                    <div v-if="submitted && hasErrorInvoice('Vat')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.vat.required">{{$t("orders.valVat")}}</span>
                                        <span v-if="errors.Vat">{{errors.Vat}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.country") }}</label>
                                    <select id="country" name="country" class="form-control" v-model="invoice.country">
                                        <option v-for="(item, key) in countries" :key="key" :value="item.Name">{{item.Name}}</option>
                                    </select>
                                    <div v-if="submitted && hasErrorInvoice('Country')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.country.required">{{$t("orders.valCountry")}}</span>
                                        <span v-if="errors.Country">{{errors.Country}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.street") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.street" :class="{ 'is-invalid': submitted && hasErrorInvoice('Street') }">
                                    <div v-if="submitted && hasErrorInvoice('Street')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.street.required">{{$t("orders.valStreet")}}</span>
                                        <span v-if="errors.Street">{{errors.Street}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.zip") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.zipCode" :class="{ 'is-invalid': submitted && hasErrorInvoice('ZipCode') }">
                                    <div v-if="submitted && hasErrorInvoice('ZipCode')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.zipCode.required">{{$t("orders.valZipCode")}}</span>
                                        <span v-if="errors.ZipCode">{{errors.ZipCode}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.state") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.state" :class="{ 'is-invalid': submitted && hasErrorInvoice('State') }">
                                    <div v-if="submitted && hasErrorInvoice('State')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.state.required">{{$t("orders.valState")}}</span>
                                        <span v-if="errors.State">{{errors.State}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.city") }}</label>
                                    <input class="form-control" type="text" id="fname" name="fname" value="" v-model="invoice.city" :class="{ 'is-invalid': submitted && hasErrorInvoice('City') }">
                                    <div v-if="submitted && hasErrorInvoice('City')" class="invalid-feedback">
                                        <span v-if="!$v.invoice.city.required">{{$t("orders.valRegion")}}</span>
                                        <span v-if="errors.City">{{errors.City}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Shipping address --> 
                            <div v-if="action == SHIPPING">
                                <div>
                                    <input class="mx-3 form-check-input" type="checkbox" name="" v-model="useSameAddresses">
                                    <label for="">{{$t("useSameAddresses")}}</label>
                                </div>
                                <div class="mt-3 mb-3">
                                    <label for="fname">{{ $t("checkout.name") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.name" :class="{ 'is-invalid': submitted && hasErrorShipping('Name') }">
                                    <div v-if="submitted && hasErrorShipping('Name')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.name.required">{{$t("orders.valName")}}</span>
                                        <span v-if="errors.Name">{{errors.Name}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.email") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.email" :class="{ 'is-invalid': submitted && hasErrorShipping('Email') }">
                                    <div v-if="submitted && hasErrorShipping('Email')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.email.required">{{$t("orders.valEmail")}}</span>
                                        <span v-if="errors.Email">{{errors.Email}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="inputContactNumber">{{ $t("checkout.phone") }}</label>
                                    <VuePhoneNumberInput :disabled=useSameAddresses default-country-code="PT" id="inputContactNumber" v-model="shipping.phone"/>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.company") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.companyName">
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.nif") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.vat" :class="{ 'is-invalid': submitted && hasErrorShipping('Vat') }">
                                    <div v-if="submitted && hasErrorShipping('Vat')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.vat.required">{{$t("orders.valVat")}}</span>
                                        <span v-if="errors.Vat">{{errors.Vat}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.country") }}</label>
                                        <select :disabled=useSameAddresses id="country" name="country" class="form-control" v-model="shipping.country">
                                            <option v-for="(item, key) in countries" :key="key" :value="item.Name">{{item.Name}}</option>
                                        </select>
                                    <div v-if="submitted && hasErrorShipping('Country')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.country.required">{{$t("orders.valCountry")}}</span>
                                        <span v-if="errors.Country">{{errors.Country}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.street") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.street" :class="{ 'is-invalid': submitted && hasErrorShipping('Street') }">
                                    <div v-if="submitted && hasErrorShipping('Street')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.street.required">{{$t("orders.valAddress")}}</span>
                                        <span v-if="errors.Street">{{errors.Street}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.zip") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.zipCode" :class="{ 'is-invalid': submitted && hasErrorShipping('ZipCode') }">
                                    <div v-if="submitted && hasErrorShipping('ZipCode')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.zipCode.required">{{$t("orders.valZipCode")}}</span>
                                        <span v-if="errors.ZipCode">{{errors.ZipCode}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.state") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.state" :class="{ 'is-invalid': submitted && hasErrorShipping('State') }">
                                    <div v-if="submitted && hasErrorShipping('State')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.state.required">{{$t("orders.valState")}}</span>
                                        <span v-if="errors.State">{{errors.State}}</span>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="fname">{{ $t("checkout.city") }}</label>
                                    <input :disabled=useSameAddresses class="form-control" type="text" id="fname" name="fname" value="" v-model="shipping.city" :class="{ 'is-invalid': submitted && hasErrorShipping('City') }">
                                    <div v-if="submitted && hasErrorShipping('City')" class="invalid-feedback">
                                        <span v-if="!$v.shipping.city.required">{{$t("orders.valRegion")}}</span>
                                        <span v-if="errors.City">{{errors.Region}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>                         
                    </div>
                    <div class="col-12 d-flex align-items-center flex-column">
                        <label class="w-75" for="fname">{{ $t("checkout.notes") }}</label>
                        <textarea class="w-75" id="" cols="40" rows="2" v-model="orderNotes"></textarea>
                    </div>
                </div>
                <div class="col-lg-6 mt-5 mb-5">
                    <!-- Bank Transfer -->
                    <div class="row row-bank-style">
                        <div class="col-12">
                            <input class="cursor-pointer" type="radio" value="BankTransfer" v-model="paymentMethod" id="scales" name="bank">
                            <label class="label-bank-class" for="scales">{{ $t("checkout.bankTransf") }}</label>
                            <!-- <p v-show="paymentMethod !== 'BankTransfer' ">{{ $t("checkout.bankTransfDesc") }}</p> -->
                            <p>{{ $t("checkout.bankTransfDesc") }}</p>
                        </div>
                        <!-- Show more -->
                        <div v-show="paymentMethod === 'BankTransfer' " class="col-12 showMore primary-background">
                            <div class="row">
                                <div class="col-12 mt-2">
                                    <label for="CardNumber" class="mb-2">{{$t("checkout.iban")}}</label>
                                    <div id="account-name" class="input-pay">
                                        <input class="w-100" type="text" v-model="info">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Multibanco -->
                        <!-- <div class="col-12 mt-3">
                            <input class="cursor-pointer" type="radio" value="Multibanco" v-model="paymentMethod" id="scales" name="bank">
                            <label class="label-bank-class" for="scales">{{ $t("checkout.ATM") }}</label>
                            <p>{{ $t("checkout.ATMdesc") }}</p>
                        </div> -->
                        <!-- MBWAY -->
                        <!-- <div class="col-12 mt-3">
                            <input class="cursor-pointer" type="radio" value="MBWay" v-model="paymentMethod" id="scales" name="bank">
                            <label class="label-bank-class" for="scales">{{ $t("checkout.mbWay") }}</label>
                            <p>{{ $t("checkout.mbWayDesc") }}</p>
                        </div> -->
                        <!-- Show more -->
                        <!-- <div v-show="paymentMethod === 'MBWay' " class="col-12 showMore primary-background">
                            <div class="row">
                                <div class="col-12 mt-2">
                                    <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="info" />
                                </div>
                            </div>
                        </div> -->
                        <!-- CC-EasyPay -->
                        <!-- <div class="col-12 mt-3">
                            <input class="cursor-pointer" type="radio" value="CC" v-model="paymentMethod" id="scales" name="bank">
                            <label class="label-bank-class" for="scales">{{ $t("checkout.card") }}</label>
                            <p>{{ $t("checkout.cardDesc") }}</p>
                        </div> -->

                        <div class="horizontal-line mt-4"></div>
                    </div>

                    <!-- Order values  -->
                    <div class="row row-subtotal-style mt-5" v-if="cart">
                        <div class="row mt-3 mb-3">
                            <div class="col-lg-6"><strong>{{ $t("checkout.subtotal") }}</strong></div>
                            <div class="col-lg-6 d-flex justify-content-end">{{cart.subTotal}}€</div>
                        </div>
                            <div class="horizontal-line"></div>
                        <div class="row mt-3 mb-3">
                            <div class="col-lg-6"><strong>{{ $t("checkout.IVA") }}</strong></div>  
                            <div class="col-lg-6 d-flex justify-content-end">{{finalTax.toFixed(2)}}€</div>
                        </div>
                            <div class="horizontal-line"></div>
                        <div v-if="shippings && shippings.length" class="row mt-3 mb-3">
                            <div class="col-lg-6"><strong>{{ $t("checkout.shipp") }}</strong></div>
                            <div class="col-lg-6 d-flex justify-content-end">{{shippings[0].price}}€</div>
                        </div>
                            <div class="horizontal-line"></div>
                        <div class="row mt-3 mb-3">
                            <div class="col-lg-6"><strong>{{ $t("checkout.total") }}</strong></div>
                            <div class="col-lg-6 d-flex justify-content-end">{{cart.total.toFixed(2)}}€</div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-primary mt-5 border-0" :style="{width: '120px', maxWidth: '120px'}" @click="handleOrderSubmit" v-bind:class="{disabled: !paymentMethod || ((paymentMethod == 'BankTransfer' || paymentMethod == 'MBWay') && !info)}">
                                <span v-show="!loading">{{ $t("checkout.conclude") }}</span>
                                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Order finished -->
            <div class="d-flex flex-column h-100-vh justify-content-center" v-else>
                <div v-if="paymentMethod == 'Multibanco'" class="d-flex justify-content-center align-items-center flex-column">
                    <h2 class="d-flex justify-content-center shopEmpty-style align-items-center py-4">{{$t("checkout.finishOrder")}}</h2>
                    <h4 class="fw-bold">{{$t("checkout.entity")}}<span class="fw-normal">{{payMethod.entity}}</span></h4>
                    <h4 class="fw-bold">{{$t("checkout.reference")}}<span class="fw-normal">{{payMethod.reference}}</span></h4>
                    <h4 class="fw-bold">{{$t("checkout.value")}}<span class="fw-normal">{{submittedTotalValue}}€</span></h4>
                </div>
                <div v-else-if="paymentMethod == 'CC'" class="d-flex justify-content-center align-items-center flex-column">
                    <h2 class="d-flex justify-content-center shopEmpty-style align-items-center py-4">{{$t("checkout.processOrder")}}</h2>
                    <p>{{$t("checkout.orderLink")}}</p>
                    <a class="fw-bold" :href="payMethod.url" target="_blank">{{$t("checkout.linkHere")}}</a>
                </div>
                <div v-else-if="paymentMethod == 'BankTransfer' || paymentMethod == 'MBWay'" class="d-flex justify-content-center">
                    <h2 class="d-flex justify-content-center shopEmpty-style align-items-center">{{$t("checkout.finishOrder")}}</h2>
                </div>
                <router-link class="d-flex justify-content-center" :to="{name: 'MyAccount'}"><button class="btn btn-primary my-5 border-0">{{$t("checkout.reviewOrder")}}</button></router-link>
            </div>
      </div>
  </section>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import { mapState, mapActions } from "vuex";
import router from '@/router';
import { required, email } from "vuelidate/lib/validators";
import SpinnerLoading from "@/components/SpinnerLoading.vue";
// import SelectCountry from "@/components/SelectCountry.vue";
import { UTILS_HELPER } from "@/helpers/utils.js"; 
import countriesJson from '@/helpers/countries.json';
import { API_HELPER } from "@/helpers/api.js";

const SHIPPING = 0;
const INVOICE = 1;
export default {
    components: {
        VuePhoneNumberInput, SpinnerLoading
    },
     metaInfo () {
      return { 
            title: `${this.$t("vueRouter.checkoutPageTitle")} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.checkoutPageDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.checkoutPage", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.checkoutPage", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.checkoutPage", 'es')])},
            ]
            
        } 
    },
    data() {
        return {
            countries: countriesJson,
            submitted: false,
            loading: false,
            useSameAddresses: false,
            invoice:{
                name: '',
                email: '',
                phone:"",
                companyName: '',
                vat: '',
                country: '',
                street: '',
                zipCode: '',
                state: '',
                city: '',
                type: 'Billing'
            },
            shipping:{
                name: '',
                email: '',
                phone:"",
                companyName: '',
                vat: '',
                country: '',
                street: '',
                zipCode: '',
                state: '',
                city: '',
                type: 'Shipping'
            },
            // Global payment info
            info: "",
            // paymentMethod:'',
            paymentMethod:'BankTransfer',
            submittedTotalValue: null,

            orderNotes: '',
            shippings: null,
            isconcluded: false,

            action: INVOICE,
            // Expose for HTML
            SHIPPING: SHIPPING,
            INVOICE: INVOICE,
            finalTax: 0
        };
    },
    validations() {
        switch(this.action)
        {
            case INVOICE:
            {
                return{
                    invoice:{
                        name: { required },
                        email: { required, email },
                        phone:{ required },
                        vat: { required },
                        country: { required },
                        street: { required },
                        zipCode: { required },
                        state: { required },
                        city: { required },
                    },                
                }
            }
            case SHIPPING:
            {
                return {
                    shipping:{
                        name: { required },
                        email: { required, email },
                        phone:{ required },
                        vat: { required },
                        country: { required },
                        street: { required },
                        zipCode: { required },
                        state: { required },
                        city: { required },
                    },     
                }
            }
        }
    },
    computed:{
        ...mapState({
            addressInfoOutput: state => state.account.listOutput,
            lastAddressesInfo: state => state.account.lastAdressesOutput,
            cartOutput: state => state.carts.listOutput,
            orderOutput: state => state.orders.orderOutput,
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        userInfo: function()
        {
            return this.addressInfoOutput && this.addressInfoOutput.data ? this.addressInfoOutput.data : {};
        },
        lastAddresses: function()
        {
            return this.lastAddressesInfo && this.lastAddressesInfo.data ? this.lastAddressesInfo.data : null;
        },
        cart: function(){
            return this.cartOutput && this.cartOutput.data ? this.cartOutput.data : [];
        },
        payMethod: function(){
            return this.orderOutput && this.orderOutput.data && this.orderOutput.data.method ? this.orderOutput.data.method : {};
        },
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
    },
    methods:{
        ...mapActions('orders', ['createOrder']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        ...mapActions('account', ['getAddress']),
        ...mapActions('account', ['getLastAddresses']),
      ...mapActions({
        getCart: 'carts/getList',
        }),
        //Create Order
        handleOrderSubmit() {
            this.clearAlert();
            this.submitted = true;

            //stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid && !this.useSameAddresses) {
                return;
            }
            
            this.loading = true;
            this.submittedTotalValue = this.cart.total;
            
            const payment = this.paymentMethod;
            const notes = this.orderNotes;
            const shipping = this.shippings[0].type;
            const addresses = [this.invoice]

            if (this.useSameAddresses) {
               addresses.push(Object.assign({}, this.invoice, { type: "shipping" }));
            } else {
               addresses.push(this.shipping);
            }

            if(this.paymentMethod === 'BankTransfer')
            {
                const iban = this.info;
                
                this.createOrder({shipping, notes, payment, iban, addresses});            
            }
            else if(this.paymentMethod === 'MBWay')
            {
                const phoneNumber = this.info;

                this.createOrder({shipping, notes, payment, phoneNumber, addresses});
            }
            else{
                this.createOrder({shipping, notes, payment, addresses});
            }
        },
        hasErrorInvoice(fieldName)
        {
            return this.$v.invoice[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, `Addresses[0].${fieldName}`);
        },
        hasErrorShipping(fieldName)
        {
            return this.$v.shipping[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, `Addresses[1].${fieldName}`);
        },
        setBilling()
        {
            this.invoice.name = this.userInfo.addresses[0].name;
            this.invoice.email = this.userInfo.addresses[0].email;
            this.invoice.phone = this.userInfo.addresses[0].phone;
            this.invoice.companyName = this.userInfo.addresses[0].companyName;
            this.invoice.vat = this.userInfo.addresses[0].vat;
            this.invoice.country = this.userInfo.addresses[0].country;
            this.invoice.street = this.userInfo.addresses[0].street;
            this.invoice.zipCode = this.userInfo.addresses[0].zipCode;
            this.invoice.state = this.userInfo.addresses[0].state;
            this.invoice.city = this.userInfo.addresses[0].city;
        },
        setShipping()
        {
            this.shipping.name = this.userInfo.addresses[1].name;
            this.shipping.email = this.userInfo.addresses[1].email;
            this.shipping.phone = this.userInfo.addresses[1].phone;
            this.shipping.companyName = this.userInfo.addresses[1].companyName;
            this.shipping.vat = this.userInfo.addresses[1].vat;
            this.shipping.country = this.userInfo.addresses[1].country;
            this.shipping.street = this.userInfo.addresses[1].street;
            this.shipping.zipCode = this.userInfo.addresses[1].zipCode;
            this.shipping.state = this.userInfo.addresses[1].state;
            this.shipping.city = this.userInfo.addresses[1].city;
        }        
    },
    mounted: function()
    {
        if(this.$route.params.shippings)
        {
            this.shippings = this.$route.params.shippings;
        }else{
            router.push({ name: 'Cart' });
        }
        // Get list of Last Addresses
        this.getLastAddresses();
    },
    watch:
    {
        action: function()
        {
            this.submitted = false;
        },
        lastAddresses: function(val) {
            if (val != null)
            {
                if (!val.length) {
                    this.getAddress()
                } else {

                    this.setBilling(val.find(x => x.type == "Billing"));
                    this.setShipping(val.find(x => x.type == "Shipping"));
                }
            }            
        },
        userInfo: function(val)
        {
            if (val != null && val.addresses && val.addresses.length) {
                if(this.userInfo.addresses.find(x => x.type == "Billing"))
                {
                    this.setBilling(this.userInfo);
                }
                if(this.userInfo.addresses.find(x => x.type == "Shipping"))
                {
                    this.setShipping(this.userInfo);
                }       
            } else {
                this.invoice.name = this.userInfo.name;
                this.invoice.email = this.userInfo.email;
                this.invoice.phone = this.userInfo.phoneNumber;
                this.invoice.companyName = this.userInfo.company;
                this.invoice.vat = this.userInfo.vat;
                
                this.shipping.name = this.userInfo.name;
                this.shipping.email = this.userInfo.email;
                this.shipping.phone = this.userInfo.phoneNumber;
                this.shipping.companyName = this.userInfo.company;
                this.shipping.vat = this.userInfo.vat;
            }
        },
        orderOutput: function(val)
        {
            if(val != null)
            {
                if(val)
                {
                    this.loading = false;     
                    this.isconcluded = true;
                }else{
                    this.loading = false;     
                    this.isconcluded = false;
                    this.$emit("error-alert-requested", { message: this.$t("checkout.orderError"), primaryButton: this.$t("close") });
                }
            }
            setTimeout(function () {
                window.scrollTo(0, 0);
            },100);
        },
        'invoice.country': function(val){
            this.finalTax = val === "Portugal" ? this.cart.taxes : 0;
        }
    }
}
</script>

<style scoped>
.register-style{
    margin-right: 2rem;
}
.login-style{
    margin-left: 2rem;
}
.vertical-line{
    border-right: 2px solid #17224d;
}
.horizontal-line{
    border-bottom: 2px solid #17224d;
}
#col-margin-border{
    border-right: 2px solid #17224d;
}
.btn-primary{
    border-radius: 50px;
}
.label-bank-class{
    margin-left: 1rem;
    font-size: 17px;
    font-weight: bold;
}
.row-bank-style{
    margin-left: 3rem;
    margin-right: 3rem;
}
.row-subtotal-style{
    margin-left: 10rem;
    margin-right: 10rem;
}
h4{
    color: #17224d;
    opacity: 0.7;
}
h4.active
{
    opacity: 1;
}
.label-form-style{
    display: block !important;
}
a
{
    text-decoration: none;
}
 @media screen and (max-width: 1270px) {
    .row-subtotal-style{
        margin-left: 5rem;
        margin-right: 5rem;
    }
} 
 @media screen and (max-width: 991px) {
    #col-margin-border{
        border-right: none;
    }
} 
 @media screen and (max-width: 600px) {
    .row-subtotal-style{
        margin-left: 1rem;
        margin-right: 1rem;
    }
} 
 @media screen and (max-width: 352px) {
    .label-bank-class{
        font-size: 13px;
    }
} 
</style>