<template>
    <div class="container d-flex justify-content-center align-items-center" :style="{height: '85vh'}">
        <SpinnerLoading v-if="processConfirm == null" id="fullscreen-loading"/>
        <div v-if="processConfirm" class="action">
            <h2 class="h2-style mb-5 align-self-center">{{$t("confirmAcc")}}</h2>
            <a href="/" class="btn btn-primary w-50 align-self-center border-0">{{$t("homePage")}}</a>
        </div>
        <form v-else @submit.prevent="resendEmailClicked" class="col-12 form-group my-2">
            <h2 class="h2-style text-center">{{$t("login.resendConfirmEmail")}}</h2>
            <div class="row py-3">
                <div class="col-12 text-center">
                    <span>{{ $t("login.description") }}</span>
                </div>
            </div>            
            <label for="email">{{ $t("login.email") }}</label>
            <input type="text" id="email" name="email" v-model="email" class="form-control" :class="{ 'is-invalid': submitted && hasEmailError }"/>
            <div v-if="submitted && hasEmailError" class="invalid-feedback">
                <span v-if="!$v.email.required">{{ $t("login.userVal") }}</span>
                <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
                <span v-if="errors.Username">{{errors.Username}}</span>
                <span v-if="errors.Email">{{errors.Email}}</span>
                <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
            </div>
            <div class="col-12 mt-5 mb-5 text-center">
                <button type="submit" class="btn btn-secondary" v-bind:class="{disabled: loading}" :style="{minWidth: '140px'}">
                    <span v-show="!loading">{{$t("login.resend")}}</span>
                    <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                </button> 
            </div>
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js"
import { required, email } from "vuelidate/lib/validators";
import SpinnerLoading from "@/components/SpinnerLoading.vue";

export default {
    components: {
        SpinnerLoading,
    },
       metaInfo () {
      return { 
            title: `${"/ConfirmAccountTitle"} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.confirmAccount", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.confirmAccount", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.confirmAccount", 'es')])},
            ]
        } 
    },
    data: function () {
        return {
            username:"",
            token:"",
            password: null,
            loading: false,
            //resend 
            email: null,
            submitted: false,
        }
    }, 
    validations: {
        email: { required, email} 
    },
    created: function (){
        this.username = this.$route.query.username;
        this.token = this.$route.query.token;
        this.password = this.$route.query.password;
        this.confirmationAccount();
    },
     methods: {
        ...mapActions('account', ['confirmAccount']),
        ...mapActions('account', ['requestToken']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        confirmationAccount() {
            this.clearAlert();
            
            const { password, username, token } = this;
            this.confirmAccount({ password, username, token })  
        },
        resendEmailClicked: function()
        {
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }
           
            this.loading = true;
            const { email } = this;
            const username = email;
            this.requestToken(username);
            
        },
    },
    computed: {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['processConfirm']),
        ...mapState('account', ['processTask']),

        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasEmailError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Email")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Username") || Object.prototype.hasOwnProperty.call(this.errors, "InvalidEmail") || Object.prototype.hasOwnProperty.call(this.errors, "DuplicateEmail");
        }
    },
    watch: {
        processConfirm(val)
        {
            if(this.username && this.token && this.password)
            {
                if(!val)
                {
                    this.$fire({
                        title: this.$t("login.resendConfirmTitle"),
                        text: this.$t("login.resendConfirmTextError"),
                        type: "error"
                    })
                }
            }
        },
        processTask(val)
        {
            if(val)
            {
                this.loading = false;
                this.$fire({
                    title: this.$t("login.resendConfirmTitle"),
                    text: this.$t("login.resendConfirmTextSuccess"),
                    type: "success"
                })
                this.$router.push({name: 'Login'});
            }
        }
    },
}
</script>

<style scoped>
.container{
    max-width: 500px!important;
}
>>>.label-style{
    color: #17224d;
}
.confirmAcc-style{
    color: #17224d;
    margin-top: 5rem;
}
.submit-button
{
    background-color: #17224d;
    border: 4px solid white;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
.action
{
    justify-content: center;
    align-content: center; 
    display: flex;
    flex-direction: column;
}
.h2-style{
    color: #17224d;
}
</style>