import { ORDER_SERVICE } from "@/services/orders.service.js";
const state = { listOutput:{}, orderOutput:{}, processTask: undefined };

const actions = {
    getList({ dispatch, commit }, inputgetOrders) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        ORDER_SERVICE.list(inputgetOrders)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    createOrder({ dispatch, commit }, imputPostOrder) {
        dispatch('alert/clear', null, { root: true });
        commit('createOrderRequest');
        
        ORDER_SERVICE.createOrder( imputPostOrder )
        .then(
            output => {
                commit('createOrderSuccess', output);
            },
            error => {
                    commit('createOrderFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
    },
    getDetail({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getOrderIdRequest');
        
        ORDER_SERVICE.getDetail(id)
        .then(
            output => {
                commit('getOrderIdSuccess', output);
            },
            error => {
                    commit('getOrderIdFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest() {
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
      //createOrder
    createOrderRequest(state) {
        state.orderOutput = null;
    },
    createOrderSuccess(state, output) {
        state.orderOutput = output;
    },
    createOrderFailure(state) {
        state.orderOutput = false;
    },
    /* getOrderId */
    getOrderIdRequest(state) {
        state.orderOutput = null;
        state.processTask = null;
    },
    getOrderIdSuccess(state, output) {
        state.orderOutput = output;
        state.processTask = true;
    },
    getOrderIdFailure(state) {
        state.orderOutput = false;
        state.processTask = false;
    },
};


export const orders = {
    namespaced: true,
    state,
    actions,
    mutations
};