var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simulator"},[_c('div',{staticClass:"container is-fluid"},[(_vm.productDetail)?_c('h1',{staticClass:"mt-3 mb-0"},[_vm._v(_vm._s(_vm.productDetail.name))]):_vm._e(),_c('div',{staticClass:"columns mb-0 mt-1"},[_c('div',{staticClass:"column is-8"},[_c('step-description',{attrs:{"current-step":_vm.settings.wizard.currentStep}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8 stepper-parent"},[_c('stepper',{attrs:{"steps":_vm.settings.wizard.steps,"current-step":_vm.settings.wizard.currentStep},on:{"step":_vm.updateCurrentStep}})],1),_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"is-pulled-right"},[_c('download',{attrs:{"downloading":_vm.loaders.downloading},on:{"click":function($event){return _vm.objectDownloadScreenshot()}}})],1),_c('div',{staticClass:"is-pulled-right"},[_c('download-pdf',{attrs:{"pdfFilePath":_vm.objectDownloadPdf}})],1),_c('div',{staticClass:"is-clearfix"})])]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"column col-12 col-lg-6 col-xl-8",attrs:{"id":"simulator-root"}},[_c('div',{attrs:{"id":"simulator-wrapper"}},[_c('div',{staticClass:"is-relative"},[(_vm.measuresObjectLoaded)?[_c('div',{attrs:{"id":"measures-h"}},[_c('span',[_vm._v(_vm._s(_vm.productDetail.length)+" m")])]),_c('div',{attrs:{"id":"measures-v"}},[_c('span',[_vm._v(_vm._s(_vm.productDetail.height)+" m")])])]:_vm._e(),_c('div',{staticClass:"simulator-wrapper is-relative col-12"},[(_vm.loaders.model)?_c('div',{staticClass:"simulator-loading is-size-4"},[_vm._m(0),_c('span',[_vm._v(" "+_vm._s(_vm.$t("simulator.renderModel"))+" ")])]):_vm._e(),_c('canvas',{class:{
                  'is-loading': _vm.loaders.model,
                  'zoom-settings': true
                },attrs:{"id":"simulator"}})])],2)]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex gap-5 my-4"},[(!_vm.$route.params.product)?_c('upload-background',{staticClass:"is-pulled-left",attrs:{"successRequest":_vm.successRequest,"loading":_vm.loaders.background},on:{"background":_vm.objectBackgroundUpload,"resetBackground":_vm.resetBackground}}):_vm._e(),_c('reset-position',{staticClass:"reset-position-style flex-grow-1",on:{"reset":_vm.resetPosition}})],1),_c('div',{staticClass:"d-flex gap-5 my-4"},[_c('show-human',{attrs:{"selected":_vm.humanObjectLoaded},on:{"showing":_vm.toggleHuman}}),_c('show-measures',{attrs:{"selected":_vm.measuresObjectLoaded},on:{"showing":_vm.toggleMeasures}})],1)]),(_vm.productDetail && _vm.productDetail.shortDescription)?_c('div',{staticClass:"mt-3 mb-0",domProps:{"innerHTML":_vm._s(_vm.productDetail.shortDescription)}}):_vm._e(),(_vm.productDetail)?_c('router-link',{attrs:{"to":{ name: 'ProductDetails', params: {slug: _vm.productDetail.slug}}}},[_vm._v(_vm._s(_vm.$t('simulator.goToProductDetail')))]):_vm._e()],1),_c('div',{staticClass:"col-12 col-lg-6 col-xl-4 d-flex flex-column",attrs:{"id":"simulator-content"}},[_c('bottom-step-description',{attrs:{"current-step":_vm.settings.wizard.currentStep}}),(
            _vm.settings.wizard.steps[_vm.settings.wizard.currentStep].component !==
            'undefined'
          )?[_c(_vm.settings.wizard.steps[_vm.settings.wizard.currentStep].component,_vm._g(_vm._b({tag:"component"},'component',_vm.computedCurrentStepWizardProps,false),_vm.computedCurrentStepWizardHandlers))]:_vm._e(),_c('div',{staticClass:"flex-grow-1"}),(_vm.settings.wizard.currentStep === 5)?_c('add-to-cart',{staticClass:"my-4",attrs:{"loading":_vm.addToCart},on:{"click":function($event){return _vm.addProductToCart()}}}):_vm._e(),(_vm.settings.wizard.currentStep !== 5 && !_vm.$route.params.product)?_c('next',{staticClass:"my-4",attrs:{"currentStep":_vm.settings.wizard.currentStep},on:{"next":function($event){return _vm.updateCurrentStep(++_vm.settings.wizard.currentStep)}}}):_vm._e(),(_vm.$route.params.product && _vm.settings.wizard.currentStep !== 4)?_c('next',{staticClass:"my-4",attrs:{"currentStep":_vm.settings.wizard.currentStep},on:{"next":function($event){return _vm.updateCurrentStep(++_vm.settings.wizard.currentStep)}}}):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon mr-3"},[_c('div',{staticClass:"fa fa-spin fa-spinner"})])
}]

export { render, staticRenderFns }