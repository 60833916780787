<template>
  <a v-if="pdfFilePath" class="button is-white is-large" :href="pdfFilePath" target="_blank" :style="{'text-decoration': 'none'}">
    <span class="mr-5 is-size-6 is-inline-block">
      {{ $t('simulator.downloadPDF') }}
    </span>  
    <span class="icon ml-2 is-size-6">
      <span :class="['fa', 'fa-download']"></span>
    </span>
  </a> 
</template>
<script>
export default {
  name: 'DownloadPdf',
  props: {
    pdfFilePath: {
      type: String
    }
  }
}
</script>
