import { NEWSLETTER_SERVICE } from "@/services/newsletter.service.js";
const state = { listOutput:{}, processTask: false };

const actions = {
    sendNewsletter({ dispatch, commit }, imputPostNewsletter) {
        dispatch('alert/clear', null, { root: true });
        commit('sendNewsletterRequest');
        
        NEWSLETTER_SERVICE.sendNewsletter( imputPostNewsletter )
        .then(
            output => {
                commit('sendNewsletterSuccess', output);
            },
            error => {
                    commit('sendNewsletterFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
    },
};

const mutations = {
      //sendNewsletter
    sendNewsletterRequest(state) {
        state.processTask = null;
    },
    sendNewsletterSuccess(state) {
        state.processTask = true;
    },
    sendNewsletterFailure(state) {
        state.processTask = false;
    },
};


export const newsletter = {
    namespaced: true,
    state,
    actions,
    mutations
};