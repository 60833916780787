<template>
    <div>
        <span class="tooltip-click" @click="showContent = true">i</span>
        <div v-show="showContent" class="tooltip-content position-relative">
            <span class="tooltip-text">{{text}}</span>
            <div class="tooltip-background" @click="showContent = false"></div>
        </div>
    </div>
</template>

<script>
export default {
    props:
    {
        text: String
    },
    data() {
        return {
            showContent: false
        }
    },
}
</script>

<style scoped>
.tooltip-click
{
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    background-color: whitesmoke;
    border-radius: 50px;
    color: darkgrey;
    cursor: pointer;
}

.tooltip-text
{
    position: absolute;
    width: 500%;
    background-color: whitesmoke;
    color: darkslategray;
    text-align: center;
    border-radius: 12px;
    left: 100%;
    z-index: 3;
    padding: 1rem;
    top: -20px;
}

.tooltip-background
{
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

</style>