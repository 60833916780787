<template>
  <section>
    <SpinnerLoading v-if="!productDetail || loading"/>
    <!-- Main container -->
    <div v-else class="container">
        <div class="row row-upper-content">
            <!-- Gallery -->
            <div class="col-sm-12 col-md-12 col-lg-8">
                <!-- XS, SM Gallery -->
                <ThreePartGallery v-if="gallery != null" class="d-md-none col-12 my-5 h-100" :gallery="gallery"></ThreePartGallery>
                <!-- MD+ Gallery -->
                <ThreePartGallery v-if="gallery != null" class="d-none d-md-block my-5 h-100" :gallery="gallery"></ThreePartGallery>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mt-5">
                <div class="row align-content-style">
                    <!-- Categories -->
                    <div class="col-12">
                        <h2><strong :style="{ 'color': productDetail.categories[0].brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }">{{productDetail.name}}</strong></h2>
                        <b><span>{{ categories }}</span></b>
                    </div>
                    <!-- Name & short description -->
                    <div v-if="hasShortDescription" class="row align-content-style mt-2 mb-2">
                        <div class="col-12">
                            <span v-html="productDetail.shortDescription"/>
                        </div>
                    </div>
                    <hr class="hr-style" v-if="productDetail.sku || productDetail.eCommerce">
                    <div class="col-12 dimensions-style mb-3">
                        <p class="align-content-style" v-html="productDetail.longDescription"/>
                    </div>
                    <div class="col-12 dimensions-style flex-wrap gap-2 mb-3">
                        <span v-if="productDetail.weight"><strong>{{$t("products.weight")}}:</strong><br> <span>{{ productDetail.weight }}</span> {{$t("products.kilograms")}}</span>
                        <span v-if="productDetail.length"><strong>{{$t("products.length")}}:</strong><br> <span>{{ productDetail.length }}</span> {{$t("products.meters")}}</span>
                        <span v-if="productDetail.height"><strong>{{$t("products.height")}}:</strong><br> <span>{{ productDetail.height }}</span> {{$t("products.meters")}}</span>
                        <span v-if="productDetail.width"><strong>{{$t("products.width")}}:</strong><br> <span>{{ productDetail.width }}</span> {{$t("products.meters")}}</span>
                        <span v-if="productDetail.otherDimensions"><strong>{{$t("products.otherDimensions")}}:</strong><br> <span>{{ productDetail.otherDimensions }}</span></span>
                    </div>
                    <!-- <hr class="hr-style" v-if="productDetail.weight || productDetail.length || productDetail.height || productDetail.width || productDetail.otherDimensions" @click="showDim = !showDim"> -->
                    <div class="dimensions-style mb-3">
                        <span v-if="productDetail.sku"><strong>{{$t("products.reference")}}:</strong> <span>{{ productDetail.sku }}</span></span>
                        <span v-if="productDetail.eCommerce"><strong v-if="showPriceLogged">{{$t("products.price")}}:</strong> <span>{{ showPriceLogged }}</span><span> {{showEuro}}</span></span>
                        <span v-if="productDetail.eCommerce"><strong>{{$t("products.stock")}}:</strong> <span>{{ productDetail.inStock ? this.$t("available") : this.$t("soldOut") }}</span></span>
                    </div>
                    <!-- <hr class="hr-style" v-if="productDetail.longDescription"> -->
                    <div class="col-12 mt-3 share-col dimensions-style flex-column gap-3 mb-3">
                        <Sharing class="text-center mt-1"></Sharing>
                        <span v-if="productVideoUrl"><strong>{{$t("products.videoUrl")}}:</strong> <a :href="productVideoUrl" target="_blank">{{productVideoUrl}}</a></span>
                    </div>
                    <!-- Actions -->
                    <div class="col-12 actions-style">
                        <button class="btn-style my-2 col-12" :style="{ 'background-color': productDetail.categories[0].brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }" @click="showEnquireForm = true">{{$t("products.consult")}}</button>
                        <button :style="{ 'background-color': productDetail.categories[0].brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }" v-if="productDetail.inStock && productDetail.eCommerce && !productDetail.modelPath" @click="addProductCart" v-bind:class="{disabled: addingCart}" class="btn-style my-2">
                            <span v-show="!addingCart" >{{$t("products.addToCart")}}</span>
                            <span v-show="addingCart" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="addingCart" class="sr-only">{{$t("loading")}}</span>
                        </button>
                        <button v-if="productDetail.inStock && productDetail.eCommerce && productDetail.modelPath"  @click="goTo3DModel" :style="{ 'background-color': productDetail.categories[0].brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }" class="btn-style my-2">{{$t("products.model3D")}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-related-prod" v-if="productsRelated && productsRelated.length">
            <!-- Related products -->
            <div class="col-12 col-md-3 d-flex flex-column justify-content-center">
            <span class="mainShop-title text-start">{{$t("products.relatedProd")}}</span>
            <br>
            <span class="mainShop-desc text-start">{{$t("products.relatedProdDesc")}}</span>
            <div class="d-flex w-100 mt-2" id="buttons">
                <a @click="$refs.mainShop.previous()"><img class="arrow-style" src="../assets/arrowLeft.png" alt=""></a>
                <a @click="$refs.mainShop.next()"><img class="arrow-style" src="../assets/arrowRight.png" alt=""></a>
            </div>
            </div>
            <div class="col-12 col-md-9">
            <!-- Slides -->
            <vueper-slides ref="mainShop" class="slider no-shadow flex-grow-1 mb-0" :bullets="false" :arrows="false" :touchable="false" :visibleSlides="3" :disableArrowsOnEdges="true" :lazy="true" fixed-height="600px" :slideMultiple="true" :breakpoints="{
                1420: { visibleSlides: 2, gap: 2, fixedHeight: 450 },
                900: { visibleSlides: 1, gap: 2, fixedHeight: 400 },
                }"
            >        
                <vueper-slide v-for="(product,index) in productsRelated" :key="index">
                    <template v-slot:content>
                        <ProductThumb :product="product" @success-alert-requested="onSuccessAlert(index)" @click="sendClick(index)" @clickModel="goTo3DModel(index)"></ProductThumb>
                    </template>
                </vueper-slide>
            </vueper-slides>
            </div>
        </div>
        <div class="row retangle-style my-5" :style="{ 'background-color': productDetail.brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }">
            <img class="background-img" :src="require('@/assets/svg/' + backgroundImage)" alt="">
            <div class="col-12 col-md-10 col-title">
                <span class="title-contact">{{$t("contactShop.titleContact")}}</span>
                <br>
                <span class="desc-contact">{{$t("contactShop.descContact")}}</span>
            </div>
            <div class="col-12 col-md-2 col-btn">
                <button v-if="productDetail.model === false" class="btn-create-inflate" @click="showEnquireForm = true">{{$t("products.consult")}}</button>
                <button v-if="productDetail.model === true" class="btn-create-inflate" @click="goTo3DModelGenerator">{{$t("contactShop.btnCreateInflate")}}</button>
            </div>
        </div>
        <!-- Pop ups -->
        <EnquirePopup v-if="showEnquireForm" :productId="productDetail.id" @closeConfirmRequested="showEnquireForm = false" :productTitle="productDetail.name" @success-alert-requested="onEnquireSuccess"/>
    </div>
  </section>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";
import ThreePartGallery from "@/components/ThreePartGallery.vue";
import ProductThumb from "@/components/ProductThumb.vue";
import Sharing from "@/components/Sharing.vue";
import router from "@/router";
import SpinnerLoading from '@/components/SpinnerLoading.vue';
import EnquirePopup from "@/components/popups/EnquirePopup.vue";
import InputPostCart from "../classes/InputPostCart";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js"
const ACTIVE = "ActiveBrands";
const INFLATE = "InflateYourImagination";
const INFLATE_COLOR = "#ff0066";
const ACTIVE_COLOR = "#007fff";
export default {
     metaInfo () {
      var result =
      { 
          title: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")},
              {name: 'keywords', content: this.productDetail && this.productDetail.categories && this.productDetail.categories.length ? this.productDetail.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
              // Twitter Card
              {name: 'twitter:card', content: "summary"},
              {name: 'twitter:title', content: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay"},
              {name: 'twitter:description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")},
              {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              //Facebook OpenGraph
              {property: 'og:title', content: this.productDetail ? `${this.productDetail.name} - FactoryPlay` : "FactoryPlay"},
              {property: 'og:site_name', content: 'FactoryPlay'},
              {property: 'og:type', content: 'website'},
              {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              {property: 'og:description', content: this.productDetail && this.productDetail.shortDescription ? UTILS_HELPER.htmlToText(this.productDetail.shortDescription) : this.$t("intro")}
          ]
      } 

      if(this.$route.params.slug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.product", 'pt')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.product", 'en')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.product", 'es')])}/${this.$route.params.slug}`},
          ];
      }

      return result;
  }, 
    data: function(){
        return{
            loading: false,
            activeTab: this.tabs && this.tabs.length ? this.tabs[0].key : null,
            productDetail: null,
            addingCart: false,
            productsRelated: null,
            showEnquireForm: false,
             ACTIVE: ACTIVE,
            INFLATE: INFLATE,
            INFLATE_COLOR: INFLATE_COLOR,
            ACTIVE_COLOR: ACTIVE_COLOR,
            showDesc: false,
            showDim: false,
            showOther: false,
            showShare: false,
            brand: null,
        }
    },
    components: {ThreePartGallery, ProductThumb, Sharing, EnquirePopup, SpinnerLoading, VueperSlides, VueperSlide},
    computed:{
        ...mapState({
            productOutput: state => state.products.productOutput,
        }),
        ...mapState('carts', ['processTask']),
        ...mapState('products', ['relatedOutput']),
        categories(){
            return this.productDetail && this.productDetail.categories ?  this.productDetail.categories.map(function (e) {return e.name}).join(', ') : [];
        },  
        gallery: function()
        {
            let result = this.productDetail && this.productDetail.files && this.productDetail.files.length ? this.productDetail.files : [];

            if(this.productDetail && this.productDetail.videoUrl) {
                result = result.map(file => API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file]));
                result.unshift({'video': this.productDetail.videoUrl})
            }
            if(this.productDetail && !this.productDetail.videoUrl)
            {
                result = result.map(file => API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file]));
            }
            return result
        },
        productVideoUrl: function()
        {
            if (this.productDetail && this.productDetail.videoUrl) {
                if (!this.productDetail.videoUrl.match(/^[a-zA-Z]+:\/\//))
                {
                    return 'https://' + this.productDetail.videoUrl;
                }

                return this.productDetail.videoUrl;
            } 
            return null;
        },
        tabs: function()
        {
            var result = [];

            if(this.productDetail)
            {
                if(this.productDetail.longDescription && UTILS_HELPER.htmlToText(this.productDetail.longDescription)) result.push({key: 'des', name:this.$t("products.desc")});
                if(this.productDetail.weight || this.productDetail.weight || this.productDetail.meters || this.productDetail.width || this.productDetail.otherDimensions) result.push({key: 'dim', name:this.$t("products.dimens")});
                if(this.productDetail.sku || this.productDetail.eCommerce) result.push({key:'oth', name:this.$t("products.other")});
                result.push({key: 'sha', name:this.$t("products.share")});
            }

            return result;
        },
        hasShortDescription()
        {
            return this.productDetail && this.productDetail.shortDescription && UTILS_HELPER.htmlToText(this.productDetail.shortDescription);
        },
        showEuro()
        {
            return localStorage.getItem('user') && this.productDetail && this.productDetail.price ? "€": null
        },
        showPriceLogged()
        {
            return localStorage.getItem('user') && this.productDetail && this.productDetail.price ? this.productDetail.price : null
        },
        backgroundImage: function()
        {
            return this.productDetail.categories[0].brand == INFLATE ? 'inflatablePinkContact.svg' : 'inflatableBlueContact.svg';
        },
    },
    methods:{
        ...mapActions('account', ['updateBrand']),
        ...mapActions({
          getSlug: 'products/getDetail',
        }),
        ...mapActions('carts', ['addCart']),
        ...mapActions('products', ['getRelated']),
        invokeGetSlug()
        {
            if (!this.$route.params.slug) {
                router.push({ name: "Shop" });
            }
            else{
                this.getSlug(this.$route.params.slug);
                this.getRelated(this.$route.params.slug);
                // this.loading = false;
            }
        },
        addProductCart() {
            const loggedIn = localStorage.getItem('user');
            if(loggedIn){
                this.addingCart = true;
                this.addCart(new InputPostCart (this.productDetail.id,1));
                this.$emit("success-alert-requested", { title:`${this.productDetail.name }`, message: this.$t("cart.addCart"), primaryButton: this.$t("cart.goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("close")})   
            }else{
                this.$router.push({ name: 'Login'});
            }
        },
        goTo3DModel(){
            router.push({name : 'Simulator', params: {slug: this.productDetail.slug}});
        },
        goTo3DModelGenerator(){
            router.push({name : 'Simulator'});
        },
        sendClick(index){
            this.$router.push({name: "ProductDetails", params: {product: this.productsRelated[index], slug: this.productsRelated[index].slug}}); 
        },
        goToCart()
        {
            router.push({name : 'Cart'});
        },
        onSuccessAlert: function(index)
        {
            this.addCart(new InputPostCart (this.productsRelated[index].id, 1));
            this.$emit("success-alert-requested", { title:`${this.productsRelated[index].name }`, message: this.$t("cart.addCart"), primaryButton: this.$t("cart.goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("close")})   
        },
        onEnquireSuccess: function()
        {
            this.$emit("success-alert-requested", {message: this.$t("enquireSuccess"), primaryButton: this.$t("enquireSuccessClose")});
            this.showEnquireForm = false;
        },
    },
    mounted: function()
    {
        // GET list of productSlug
        this.invokeGetSlug();
    },
    watch: {
        $route(to) {
            if (to.name === "ProductDetails") {
                this.loading = true;
                this.invokeGetSlug();
            }
        },
        relatedOutput: function(val)
        {
            if(val != null)
            {
                if(val && val.data)
                {
                    this.productsRelated = val.data;
                }
                else
                {
                    router.push("/Shop");
                }
            }
        },
        productOutput: function(val)
        {
            if(val != null)
            {
                if(val && val.data)
                {
                    this.productDetail = val.data;
                    this.updateBrand(this.productDetail.categories[0].brand);
                    this.loading = false;
                }
                else
                {
                    router.push("/Shop");
                }
            }
        },
        processTask: function(val)
        {
            if(val != null){
                this.addingCart = false;
            }
        },
        tabs: function()
        {
            this.activeTab = this.tabs[0].key;
        }
    }

}
</script>

<style scoped>
/* >>>.btn-secondary{
    border-radius: 1.25rem !important;
} */
.hr-style{
    border: 1px solid black;
}
>>>.box{
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,.50);
  margin: 15px auto;
  width: 100%;
  position: relative;
  max-width: 460px;
  min-height: 520px;
  transition: transform .2s;
  border-radius: 15px;
}
>>>.img-settings{
    width: 100%;
    height: 100%;
    min-height: 270px;
    max-height: 270px;
    object-fit: cover;
}
.tab-btn{
    cursor: pointer;
    color: #17224d;
    opacity: 0.3;
}
.tab-btn.active{
    color: #17224d;
    opacity: 1;
}
.border-style {
  border-left: 6px solid #17224d;
  height: 400px;
}
.verticalBar{
    border-left: 4px solid #17224d;
    height: 400px;
}
.btn-style{
    color: white;
    width: 185px;
    max-width: 185px;
    border: none;
    border-radius: 5px;
}
.retangle-style{
    border-radius: 10px;
    padding: 30px;
    min-height: 250px;
    display: flex;
    align-items: center; 
    position: relative;
    overflow: hidden;
}
.background-img{
    position: absolute;
    width: auto;
    left: -55px;
    height: 50vh;
    transform: rotateZ(10deg);
}
.col-btn{
    z-index: 1;
}
.col-title{
    z-index: 1;
}
.title-contact{
    color: white;
    font-weight: bold;
    font-size: 30px;
}
.desc-contact{
    color: white;
    font-size: 15px;
}
.btn-create-inflate{
    background-color: white;
    padding: 10px;
    color: black;
    border-radius: 10px;
    border: none;
}
.arrow-product-detail{
    width: 30px;
    height: 30px;
}
.rotated {
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}
/* Slider */
.vueperslide{
    padding: 0 10px;
}
.arrow-style{
  width: 50px;
}
.mainShop-title{
  font-weight: 900;
  font-size: 30px;
  word-break: break-word;
}
.mainShop-desc{
  font-size: 15px;
  word-break: break-word;
}
/* .row-related-prod{
    margin-top: 10rem;
    margin-bottom: 5rem;
} */
.row-upper-content{
    margin-bottom: 5rem;
}
/* Sneak peak of next post */
.vueperslides {padding-right: 5em; height: min-content; } 
.vueperslide:not(.vueperslide--visible)
{
  opacity: 0.2;
}

[class^="icon-left"], [class*="icon-right"] {
    font-size: 100px;
    line-height: 65px;
}
.btn-icon{
  border: none;
  width: 98px !important;
  height: 50px !important;
  margin: 0 2px;
  cursor: pointer !important;
}
.box[data-v-93a64a52]{
  width: unset !important;
  z-index: 1;
}
.actions-style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}
.dimensions-style{
    display: flex;
    /* flex-direction: row; */
    justify-content: space-between;
}
@media screen and (min-width: 991px) 
{
  .card[data-v-00c03554]:hover{
    transform: scale(1.01);
  }
}
@media screen and (max-width: 991px) 
{
  .align-content-style{
    margin-top: 3rem;
    text-align: center;
  }
  #buttons{
    justify-content: center;
  }
  .mainShop-title{
    text-align: center !important;
  }
  .mainShop-desc{
    text-align: center !important;
  }
  .share-col{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* .row-related-prod{
    margin-top: 5rem;
  } */
  .actions-style{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .vueperslides{
    padding: 0;
  }
  .align-content-style{
    margin-top: 1rem;
  }
}
@media screen and (max-width: 550px) {
  .blog-slider{
    flex-direction: column;
  }
  h1{
    margin: 0 2px!important;
  }
}
/*End Slider */
@media screen and (max-width: 767px) {
    .descrip-style{
        text-align: center !important;
     }
     .col-style{
         flex-direction: row !important;
         width: 100%;
         display: flex;
         justify-content: center !important;
     }
     .tab-btn{
         margin: 0 10px;
     }
     h4{
         font-size: 16px;
     }
} 
@media screen and (max-width: 380px) {
    .dimensions-style{
        flex-direction: column;
        gap: 2rem;
    }
} 
/* @media screen and (min-width: 1407px) {
    .row-related-prod{
        margin-top: 10rem;
    }   
} */
</style>