import Vue from "vue";
import Vuex from "vuex";
import { alert} from '@/store/alert.module';
import { account } from '@/store/account.module';
import { products } from '@/store/products.module';
import { categories } from '@/store/categories.module';
import { carts } from '@/store/cart.module';
import { orders } from '@/store/orders.module';
import { staticPages } from '@/store/staticPages.module';
import { palettes } from '@/store/palettes.module';
import { newsletter } from '@/store/newsletter.module';
import { filter } from '@/store/filter.module';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {},
  actions: {},
  mutations: {},
  modules: {
    alert,
    account,
    products,
    categories,
    carts,
    orders,
    staticPages,
    palettes,
    newsletter,
    filter
  },
});
