<template>
    <section id="login">
        <div class="container d-flex flex-column justify-content-center">
            <div class="row mt-5">
                <div class="col-12 d-flex justify-content-center">
                    <h4 v-if="action !== RECOVERACTION " class="register-style cursor-pointer" v-bind:class="{ 'active': action == REGISTERACTION }" @click="action = REGISTERACTION">{{ $t("login.register") }}</h4>
                    <div v-if="action !== RECOVERACTION " class="vertical-line"></div>
                    <h4 v-if="action !== RECOVERACTION " class="login-style cursor-pointer" v-bind:class="{ 'active': action == LOGINACTION }" @click="action = LOGINACTION">{{ $t("login.login") }}</h4>
                    <h4 v-if="action == RECOVERACTION" class="cursor-pointer" v-bind:class="{ 'active': action == RECOVERACTION }">{{ $t("login.recover") }}</h4>
                </div>
            </div>
            <!-- Form -->
            <form @submit.prevent="submitClicked">
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <span>{{ $t("login.description") }}</span>
                    </div>
                </div>
                <div class="row mt-5 mb-5">
                    <div class="col-12 form-group my-2">
                        <label for="email">{{ $t("login.email") }}</label>
                        <input type="text" id="email" name="email" v-model="email" class="form-control" invalid-feedback="E-mail Inválido" :class="{ 'is-invalid': submitted && hasEmailError }"/>
                        <div v-if="submitted && hasEmailError" class="invalid-feedback">
                            <span v-if="!$v.email.required">{{ $t("login.userVal") }}</span>
                            <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
                            <span v-if="errors.Username">{{errors.Username}}</span>
                            <span v-if="errors.Email">{{errors.Email}}</span>
                            <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="name">{{ $t("login.name") }}</label>
                        <input type="text" v-model="name" id="name" name="name" class="form-control" :class="{ 'is-invalid': submitted && hasError('Name') }" />
                        <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                            <span v-if="!$v.name.required">{{ $t("login.nameVal") }}</span>
                            <span v-if="errors.Name">{{errors.Name}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="company">{{ $t("login.company") }}</label>
                        <input type="text" v-model="company" id="company" name="company" class="form-control" :class="{ 'is-invalid': submitted && hasError('Company') }" />
                        <div v-if="submitted && hasError('Company')" class="invalid-feedback">
                            <span v-if="!$v.company.required">{{ $t("login.companyVal") }}</span>
                            <span v-if="errors.Company">{{errors.Company}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="vat">{{ $t("login.vat") }}</label>
                        <input type="text" v-model="vat" id="vat" name="vat" class="form-control" :class="{ 'is-invalid': submitted && hasError('Vat') }" />
                        <div v-if="submitted && hasError('Vat')" class="invalid-feedback">
                            <span v-if="!$v.vat.required">{{ $t("login.vatVal") }}</span>
                            <span v-if="!$v.vat.minLength">{{ $t("login.vatLength") }}</span>
                            <span v-if="errors.Vat">{{errors.Vat}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="phoneNumber">{{ $t("login.phoneNumber") }}</label>
                        <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" :error="submitted && hasError('PhoneNumber')" v-model="phoneNumber" :class="{ 'is-invalid': submitted && hasError('PhoneNumber') }"/>
                        <div v-if="submitted && hasError('PhoneNumber')" class="invalid-feedback">
                            <span v-if="!$v.phoneNumber.required">{{ $t("login.phoneVal") }}</span>
                            <span v-if="errors.PhoneNumber">{{errors.PhoneNumber}}</span>
                        </div> 
                    </div>
                    <div v-if="action == REGISTERACTION || action == LOGINACTION" class="col-12 form-group my-2">
                        <label for="password">{{ $t("login.pass") }}</label>
                        <input type="password" id="password" name="password" v-model="password" class="form-control" invalid-feedback="Password Inválida" :class="{ 'is-invalid': submitted && hasPasswordError }"/>
                        <div v-if="submitted && hasPasswordError" class="invalid-feedback">
                            <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
                            <span v-if="errors.Password">{{errors.Password}}</span>
                            <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                            <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                            <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                            <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                            <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <label for="confirmPassword">{{ $t("login.rpass") }}</label>
                        <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
                        <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                            <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
                            <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
                        </div>
                    </div>
                    <div v-if="action == REGISTERACTION" class="col-12 form-group my-2">
                        <div class="d-flex align-items-center">
                            <label for="brand" class="flex-grow-1">{{ $t("login.brand") }}</label>
                            <label class="d-flex" for="brand"><span :style="{fontSize: '0.7rem',fontWeight: 'bold'}">Inflate</span><Tooltip :text="$t('brandDesc.inflate')"></Tooltip></label>                                                   
                            <label class="d-flex" for="brand"><span :style="{fontSize: '0.7rem',fontWeight: 'bold'}">Active</span><Tooltip :text="$t('brandDesc.active')"></Tooltip></label>                     
                        </div>                              
                        <select name="brand" id="brand" v-model="brand" class="form-control mt-2 flex-grow-1" :class="{ 'is-invalid': submitted && hasError('Brand') }">
                            <option value="" disabled selected>{{$t("login.selectYourBrand")}}</option>
                            <option value="InflateYourImagination">Inflate</option>
                            <option value="ActiveBrands">Active</option>
                        </select>
                        <div v-if="submitted && hasError('Brand')" class="invalid-feedback">
                            <span v-if="!$v.brand.required">{{ $t("login.brandVal") }}</span>
                            <span v-if="errors.Brand">{{errors.Brand}}</span>
                        </div>
                    </div>
                    <div class="row m-0 p-0" v-if="action == LOGINACTION">
                        <div class="d-flex justify-content-between">
                            <p class="cursor-pointer" id="recover-style" @click="action = RECOVERACTION">{{ $t("login.forgotPass") }}</p>
                            <!-- Only appears if the user try to login and the account is not valid. -->
                            <router-link v-if="errors.Username == 'A conta não está validada.' " id="recover-style" :to="{name: 'ConfirmAccount'}">{{ $t("login.resendConfirmAccount") }}</router-link>
                        </div>
                    </div>
                    <div class="row" v-if="action == RECOVERACTION">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                            <p class="cursor-pointer" id="recover-style" @click="action = LOGINACTION">{{ $t("login.loginBack") }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-5 text-center">
                    <button type="submit" class="btn btn-secondary" v-bind:class="{disabled: loading}" :style="{minWidth: '140px'}">
                        <span v-if="action == REGISTERACTION" v-show="!loading">{{$t("login.registerBtn")}}</span>
                        <span v-else-if="action == LOGINACTION" v-show="!loading">{{$t("login.loginBtn")}}</span>
                        <span v-else-if="action == RECOVERACTION" v-show="!loading">{{$t("login.recoverButton")}}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                    </button> 
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js"
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import VuePhoneNumberInput from 'vue-phone-number-input';
import Tooltip from '@/components/Tooltip.vue'
import { API_HELPER } from "@/helpers/api.js";

const LOGINACTION = 0;
const REGISTERACTION = 1;
const RECOVERACTION = 2;

export default {
    components: {
        VuePhoneNumberInput,
        Tooltip
    },
    metaInfo () {
      return { 
            title: `${this.$t('vueRouter.loginTitle')} - FactoryPlay`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'pt')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRoutes.login", 'es')])},
            ]
        } 
    },
    data: function () {
        return {
            action: LOGINACTION,
            // form
            email: null,
            name: null,
            company: null,
            vat: null,
            phoneNumber: null,
            password: null,
            confirmPassword: null,
            brand: this.brandType ? this.brandType : 'ActiveBrands',
            submitted: false,
            // Expose for HTML
            LOGINACTION: LOGINACTION,
            REGISTERACTION: REGISTERACTION,
            RECOVERACTION: RECOVERACTION,
            loading: false,
        };
    },
    validations() {
        switch(this.action) 
        {
            case LOGINACTION:
            {
                return {
                    email: { required, email },
                    password: { required }
                }
            }
            case REGISTERACTION:
                {
                    return {
                        email: { required, email },
                        name: { required },
                        company: { required },
                        vat: { required, minLength: minLength(8) },
                        phoneNumber: { required },
                        brand: { required },
                        password: { required },
                        confirmPassword: { required, sameAsPassword: sameAs('password') }
                    }
            }
            case RECOVERACTION:
            {
                return {
                    email: { required, email }
                }
            }
        } 
    },
    computed:
    {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['processTask']),
        ...mapState('account', ['status']),
        ...mapState('account', ['brandType']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasPasswordError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Password")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Password") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordTooShort") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresNonAlphanumeric") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresDigit") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresLower") || Object.prototype.hasOwnProperty.call(this.errors, "PasswordRequiresUpper");
        },
        hasEmailError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Email")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Email")|| Object.prototype.hasOwnProperty.call(this.errors, "Username") || Object.prototype.hasOwnProperty.call(this.errors, "InvalidEmail") || Object.prototype.hasOwnProperty.call(this.errors, "DuplicateEmail");
        }
    },
      methods: {
    ...mapActions('account', ['login']),
    ...mapActions('account', ['signup']),
    ...mapActions('account', ['requestResetToken']),
    ...mapActions({
        clearAlert: 'alert/clear' 
    }),
    submitClicked: function()
    {
        switch(this.action)
        {
            case LOGINACTION: { this.loginClicked(); break; }
            case REGISTERACTION: { this.registerClicked(); break; }
            case RECOVERACTION: { this.forgotPasswordClicked(); break; }
        }
    },
    loginClicked: function(){
        this.clearAlert();
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.loading = true;

        const { email, password } = this;
        this.login({ email, password })
    },
    forgotPasswordClicked: function()
    {
        this.clearAlert();
        this.submitted = true;
        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
    
        this.loading = true;
        this.requestResetToken(this.email);
    },
    registerClicked: function()
    {
        this.clearAlert();
        this.submitted = true;

        //stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        this.loading = true;
        
        const { email, name, company, vat, phoneNumber, brand, password } = this;
        const username = email;
        this.signup({ username, name, company, vat, phoneNumber, email, brand, password })

    },
    hasError(fieldName)
    {
      return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    }
  },
    watch:{
        action: function()
        {
            this.submitted = false;
        },
        alertType: function(val)
        {
            this.loading = val === null;
        },
        status: function(val)
        {
            if(val && val.registered)
            {
                this.$fire({
                    title: this.$t("login.registerPopup"),
                    text: this.$t("login.registerText"),
                    type: "info"
                })
                this.submitted = false;
                this.name= null;
                this.company= null;
                this.vat= null;
                this.phoneNumber= null;
                this.brand= null;
                this.email= null;
                this.password = null;
                this.confirmPassword = null;
            }
        },
        processTask: function(val)
        {
            if(val)
            {
                this.loading = false;
                this.$fire({
                    title: this.$t("login.forgotPopup"),
                    text: this.$t("login.forgotText"),
                    type: "success"
                })
                this.submitted = false;
                this.email = null;
            }
        }
    }
}
</script>

<style scoped>
.container{
    max-width: 500px!important;
    min-height: 100vh;
}
.register-style{
    margin-right: 2rem;
}
.login-style{
    margin-left: 2rem;
}
.vertical-line{
    border-right: 2px solid #17224d;
}
h4.active{
    color: #17224d;
    font-weight: bold;
}
#recover-style{
    text-decoration: underline;
    color: #17224d;
}
</style>