<template>
  <div class="color-picker buttons mb-0">
    <stepper :steps="steps" :current-step="currentStep" @step="updateCurrentStep" :button-class="['button', 'is-white',]" :disabled="$parent.loaders.rotating" :subtitle="false"/>
    <div id="colors-container">
      <div v-for="(position, index) in positions" :key="index">
        <div class="is-relative">
          <div class="box is-fullwidth p-3 mb-0">
            <p>
              <span class="is-small has-text-left has-text-weight-semibold">
                {{ `${ $t('simulator.position') } ` + position }}
              </span>
            </p>
            <!-- Color picker -->
            <div class="buttons">
              <button class="button is-white is-small"
                      :disabled="$route.params.product"
                      @click="chooseColor(position, color)"
                      v-for="color in options"
                      :key="color">
                  <span class="icon is-medium">
                    <span :class="['fas',  'is-size-5', {
                      'fa-circle': selections[steps[currentStep].name][position] !== color,
                      'fa-circle custom': color === '#ffffff',
                      'fa-check-circle': checkCircle(position,color),
                      'fa-check-circle custom': checkCircleCustom(position,color)
                    }]" :style="{
                      color: color
                    }"></span>
                  </span>
              </button>
            </div>
            <!-- Action buttons (remove & rotate) -->
            <div class="d-flex justify-content-end gap-1" v-if="patterns[steps[currentStep].name][patternKeys[index]] !== false && !$route.params.product">
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="rotateLeft(patternKeys[index])">
                <span class="icon">
                  <span class="fa fa-undo"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="rotateRight(patternKeys[index])">
                <span class="icon">
                  <span class="fa fa-redo"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="cleanUpPosition(patternKeys[index])">
                <span class="icon">
                  <span class="fa fa-trash-alt"></span>
                </span>
              </button>
            </div>
            <!-- Picker buttons -->
            <button class="button is-white is-relative is-fullwidth has-text-left is-justify-content-left is-align-items-flex-start"
              :disabled="(typeof loading[steps[currentStep].name] !== 'undefined' && 
                                 loading[steps[currentStep].name][patternKeys[index]]) ||
                         patterns[steps[currentStep].name][patternKeys[index]] !== false || 
                      $route.params.product"
              @click="openWindowUploadImage(patternKeys[index])">
              <span class="icon">
                <span :class="['fas', {
                  'fa-upload': typeof loading[steps[currentStep].name] !== 'undefined' && !loading[steps[currentStep].name][patternKeys[index]] && patterns[steps[currentStep].name][patternKeys[index]] === false,
                  'fa-check': patterns[steps[currentStep].name][patternKeys[index]] !== false,
                  'fa-spin fa-spinner': typeof loading[steps[currentStep].name] !== 'undefined' && loading[steps[currentStep].name][patternKeys[index]] && patterns[steps[currentStep].name][patternKeys[index]] === false,
                }]"></span>
              </span>
              <span class="is-small has-text-left ml-2">
                {{ `${ $t('simulator.position') } ` + position }}
                <!-- <template v-if="patterns[steps[currentStep].name][patternKeys[index]] !== false && typeof patterns[steps[currentStep].name][patternKeys[index]].name !== 'undefined'"> -->
                <template v-if="patterns[steps[currentStep].name][patternKeys[index]] !== false">
                <br>
                <small v-if="patterns[steps[currentStep].name][patternKeys[index]].name" class="is-block">{{ patterns[steps[currentStep].name][patternKeys[index]].name.substr(0, 15) }}{{ patterns[steps[currentStep].name][patternKeys[index]].name.length > 15 ? '...' : '' }}</small>
              </template>
              </span>
            </button>
            <!-- Image preview -->
            <template v-if="patterns_preview[steps[currentStep].name][patternKeys[index]]">
              <div class="ml-2 py-3 d-flex justify-content-center">
                <img :src="patterns_preview[steps[currentStep].name][patternKeys[index]]"
                    style="max-width: 100%; height: auto; max-height: 200px" alt="">
              </div>
            </template>
          </div>
          <input class="d-none" type="file" accept=".svg"
                :ref="`fileUploadPattern-${steps[currentStep].name}-${patternKeys[index]}`"
                :id="`fileUploadPattern-${steps[currentStep].name}-${patternKeys[index]}`"
                :disabled="loading[steps[currentStep].name][patternKeys[index]]"
                @change="callbackChangeHandler(patternKeys[index])"/>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Stepper from "@/components/Simulator/Stepper/index";

export default {
  name: 'ColorPickerStep',
  components: {Stepper},
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Object,
      required: true,
    },
    selections: {
      type: Object,
      required: true,
    },
    preview: {
      type: Object,
      required: true,
    },
    patterns: {
      type: Object,
      required: true,
    },
    patterns_preview: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },

  },
  watch: {
    patterns() {
      this.bootLoaders()
    },
  },
  data() {
    return {
      loading: { },
      file: null
    };
  },
  created() {
   this.bootLoaders();
  },
  computed: {
    positions() {
      return this.steps && this.steps[this.currentStep] && this.steps[this.currentStep].name ? Object.keys(this.selections[this.steps[this.currentStep].name]) : [];
    },
    patternKeys() {
      return Object.keys(this.patterns[this.steps[this.currentStep].name]);
    },
  },
  methods: {
    bootLoaders() {
      for(let i in this.steps) {
        this.$set(this.loading, this.steps[i].name, {});
        for(let k in this.patterns[this.steps[i].name]) {
          this.$set(this.loading[this.steps[i].name], k, false)
        }
      }
    },
    openWindowUploadImage(position) {
      let element = this.$refs[`fileUploadPattern-${this.steps[this.currentStep].name}-${position}`];
      return element.length > 0 ? element[0].click() : null;
    },
    callbackChangeHandler(position) {
      if(this.$refs[`fileUploadPattern-${this.steps[this.currentStep].name}-${position}`][0].files.length) {
        this.$set(this.loading[this.steps[this.currentStep].name], position, true);
        this.file = this.$refs[`fileUploadPattern-${this.steps[this.currentStep].name}-${position}`][0].files[0];
        // this.$set(this.preview[this.steps[this.currentStep].name], position, URL.createObjectURL(file));
        this.$emit('file', this.steps[this.currentStep].name, position, this.file);
        this.$refs[`fileUploadPattern-${this.steps[this.currentStep].name}-${position}`][0].value = "";
      } else { this.$toast.bottom('Something went wrong uploading your image.', { duration: 2500 }); }
    },
    rotateLeft(position)
    {
      this.rotate(position, -1);
    },
    rotateRight(position)
    {
      this.rotate(position, 1);
    },
    rotate(position, srcOrientation)
    {
      let self = this;

      var fr = new FileReader();
      fr.onload = function () {
        var svg = fr.result;
        var img = new Image();

        // make it base64
        var svg64 = btoa(svg);
        var b64Start = 'data:image/svg+xml;base64,';

        // prepend a "header"
        var image64 = b64Start + svg64;

        // set it as the source of the img element
        img.onload = function() {
          var width = img.width;
          var height = img.height;

          let heightPattern = /height="(.*?)"/;
          svg = svg.replace(heightPattern,`height="${width}"`);
          let widthPattern = /width="(.*?)"/;
          svg = svg.replace(widthPattern,`width="${height}"`);

          let svgPattern = /<svg[\S\s]*?>/;
          let svgTag = svg.match(svgPattern)[0];
          let transformPattern = /transform=".*?"/;

          var rotation = 0;
          if(transformPattern.test(svgTag))
          {
            let rotationPattern = /transform="rotate\((.*)\)"/;
            if(rotationPattern.test(svgTag))
            {
              rotation = svgTag.match(rotationPattern)[1];
            }
          }
          rotation = srcOrientation < 0 ? rotation - 90 : rotation + 90;

          svgTag = svgTag.replace(transformPattern, '');
          svgTag = svgTag.replace(">", ` transform="rotate(${rotation})">`);
          svg = svg.replace(svgPattern,svgTag);

          // convert to blob and fire event
          const blob = new Blob([svg], {type: 'image/svg+xml'});
          self.$emit('file', self.steps[self.currentStep].name, position, blob);
          self.file = blob;
        }
        img.src = image64;
      }
      fr.readAsText(this.file);
    },
    cleanUpPosition(position) {
      this.$set(this.loading[this.steps[this.currentStep].name], position, false);
      this.$set(this.preview[this.steps[this.currentStep].name], position, false);
      this.$emit('file', this.steps[this.currentStep].name, position, false);
    },
    updateCurrentStep(step) {
      this.$emit('step', step)
    },
    chooseColor(position, color) {
      this.$emit('color', this.steps[this.currentStep].name, position, color);
    },
    checkCircle(position,color) {
      let colorWhite = '#ffffff';
      if(color != colorWhite) {
        return this.selections[this.steps[this.currentStep].name][position] === color;
      }
    },
    checkCircleCustom(position,color) {
      let colorWhite = '#ffffff';
      if(color === colorWhite) {
        return this.selections[this.steps[this.currentStep].name][position] === color;
      }
    },
  }
}
</script>
<style scoped>
.fa-circle.custom {
  border: 1px solid black;
  border-radius: 50%;
  font-size: 1.1rem!important;
}
.fa-check-circle.custom::before {
  content: "\f058";
  background-color: black;
  border-radius: 50%;
}
.box{
  border-radius: unset;
  box-shadow: unset;
  border: 1px solid black;
  background-color: #f5f5f5;
}
.button.is-white {
  background-color: transparent;
}
#colors-container
{
  column-count: 2;
}
@media screen and (max-width: 767px) {
  #colors-container
  {
    column-count: 1;
  }
}
#colors-container > div
{
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
}
</style>