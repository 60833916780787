<template>
  <section id="page">
    <SpinnerLoading v-if="!detailPage"/>
    <div v-else class="container">
      <div v-if="detailPage" class="info">
        <div v-if="detailPage.image" class="full-top">
          <img :src="getImage" class="top-bg" alt="Image">
        </div>
        <div v-for="tab in tabs" :key="tab.id">
          <div class="about-text">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="section-title">
                    {{tab.title}}
                  </div>
                  <div class="p">
                    <p v-html="tab.content"></p>
                  </div>
                </div>
              </div>
              <div v-if="currentRoute == '/Page' && $route.query.id == 2">
                <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d47623.875800294765!2d-6.837198725974262!3d41.75304387391239!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3bb4d5d9411efd%3A0x6dba00007b379a41!2sFactory%20Play%20-%20Produ%C3%A7%C3%A3o%20de%20Insufl%C3%A1veis%20e%20Equipamentos%20de%20Anima%C3%A7%C3%A3o%2C%20Lda.!5e0!3m2!1spt-PT!2spt!4v1683646579206!5m2!1spt-PT!2spt" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>  
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";
import SpinnerLoading from "@/components/SpinnerLoading.vue"
import { UTILS_HELPER } from "@/helpers/utils.js";  

export default { 
  components: {
    SpinnerLoading
  },
     metaInfo () {
    var result = 
    { 
      title: this.detailPage ? `${this.detailPage.title} - FactoryPlay` : "FactoryPlay", 
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.detailPage && this.detailPage.content  ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
        {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: this.detailPage ? `${this.detailPage.title} - FactoryPlay` : "FactoryPlay"},
        {name: 'twitter:description', content: this.detailPage && this.detailPage.content ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assetslogo.png"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: this.detailPage ? `${this.detailPage.title} - FactoryPlay` : "FactoryPlay"},
        {property: 'og:site_name', content: 'FactoryPlay'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assetslogo.png"]) },
        {property: 'og:description', content: this.detailPage && this.detailPage.content ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
      ], 
    };

    if(this.$route.query.id)
    {
      result.link = 
      [
        {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.page", 'pt')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.page", 'en')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.page", 'es')])}?id=${this.$route.query.id}`},
      ];
    }

    return result; 
  },
data() {
    return {
      currentRoute: this.$route.path,
    };
  },
mounted() 
  {
    this.initializePage();
  },
computed: {
    ...mapState("staticPages", ["detailOutput"]),
    detailPage: function () {
      return this.detailOutput && this.detailOutput.data ? this.detailOutput.data : null;
    },
    getImage: function() {
      return this.detailPage && this.detailPage.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.detailPage.image]) : "";      

    },
    tabs: function () {
      return this.detailPage && this.detailPage.tabs && this.detailPage.tabs.length ? this.detailPage.tabs : [];
    },
  },
  methods:
  {
    ...mapActions('staticPages', ['getDetails']),

    initializePage()
    {
      if (!this.$route.query.id) {
        router.push({ name: "Home" });
      }else{
        this.getDetails(this.$route.query.id);
       }
    }
  },
  watch: {
    $route(to) {
      if(to.name)
      {
        this.initializePage();
      }
    },
    '$route.path': function(newVal) {
      this.currentRoute = newVal
    }
  }
};
</script>

<style scoped>
.container{
  min-height: 100vh;
}
.full-top {
  height: 700px;
  position: relative;
  width: 100%;
}
.full-top::before {
  content: "";
  width: 100%;
  height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg,#000,rgba(84,84,84,0));
  mix-blend-mode: multiply;
  opacity: .45;
  z-index: 4;
}
.full-top .top-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.about-text {
  position: relative;
  padding-top: 64px;
  padding-bottom: 88px;
}
.section-title {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: #17224d;
  text-align: center;
}
.p {
    font-size: 1rem;
    line-height: 32px;
    color: #575757;
}
</style>
