import { PRODUCT_SERVICE } from "@/services/products.service.js";
// import router from "@/router";
import InputGetProducts from "@/classes/InputGetProducts";
import { HomeResult } from "@/classes/HomeResult";

const state = { listOutput:{}, groupedListOutput: [], processTask: false, productOutput: {}, relatedOutput: {}};

const actions = {
    getList({ dispatch, commit }, inputgetProducts) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        PRODUCT_SERVICE.list(inputgetProducts)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getAll({ dispatch, commit }, homeResult) {
        dispatch('alert/clear', null, { root: true });
        commit('groupListRequest', homeResult);

        // GET list of products
        const itemsPerPage = 999;
        const currentPage = 1;
        const orderBy = 1; // Ascending
        const propertyName = "Highlighted";
        const searchText = '';
        const status = 1;

        var category = null;
        switch(homeResult)
        {
            case HomeResult.ActiveInStock:
            {
                category = process.env.VUE_APP_ONLINE_STORE_ACTIVE;
                break;
            }
            case HomeResult.InflateInStock:
            {
                category = process.env.VUE_APP_ONLINE_STOCK_INFLATE;
                break;
            } 
            default:
            {
                break;
            }
        }

        var input = new InputGetProducts(itemsPerPage, currentPage, orderBy, propertyName, searchText, status, category);
        
        var highlighted = (homeResult == HomeResult.ActiveHighlighted || homeResult == HomeResult.InflateHighlighted) ?? null;
        if(highlighted) input.highlighted = highlighted;

        var brand = homeResult == HomeResult.ActiveHighlighted || homeResult == HomeResult.ActiveInStock ? "ActiveBrands" : "InflateYourImagination";
        input.brand = brand;

        input.searchInSubcategories = true;

        // Make request        
        PRODUCT_SERVICE.list(input)
            .then(
                output => {
                    commit('groupListSuccess', {output, homeResult});
                },
                error => {
                    commit('groupListFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getProductIdRequest');
        
        PRODUCT_SERVICE.getDetail(slug)
        .then(
            output => {
                commit('getProductIdSuccess', output);
            },
            error => {
                    commit('getProductIdFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getSimulatorDetail({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getSimulatorDetailRequest');
        
        PRODUCT_SERVICE.getSimulatorDetail(slug)
        .then(
            output => {
                commit('getSimulatorDetailSuccess', output);
            },
            error => {
                    commit('getSimulatorDetailFailure', error);
                    dispatch('alert/error', error, { root: true });

                    // When you are not logged in, it goes to the login. If you are, go to the shop
                    // const loggedIn = localStorage.getItem('user');
                    // if(loggedIn)
                    // {
                    //     router.push({ path: '/Shop' });
                    // }else{
                    //     router.push({ path:'/Login' }); 
                    // }
                }
            );
    },
    getRelated({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getRelatedRequest');
        
        PRODUCT_SERVICE.getRelated(slug)
        .then(
            output => {
                commit('getRelatedSuccess', output);
            },
            error => {
                    commit('getRelatedFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    sendMessage({ dispatch, commit }, imput) {
        dispatch('alert/clear', null, { root: true });
        commit('sendMessageRequest');
        
        PRODUCT_SERVICE.sendMessage( imput )
        .then(
            output => {
                commit('sendMessageSuccess', output);
            },
            error => {
                    commit('sendMessageFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest() {
       
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Group List Problem with watch -> Used because vue has no idea that it has changed */
    groupListRequest(state, homeResult) {
        state.groupedListOutput.splice(homeResult, 1, null);
    },
    groupListSuccess(state, {homeResult, output }) 
    {
        var data = output && output.data && output.data.products ? output.data.products : [];
        state.groupedListOutput.splice(homeResult, 1, data);
    },
    groupListFailure(state, homeResult) {
        state.groupedListOutput.splice(homeResult, 1, false);

    },
    /* getProductDetail */
    getProductIdRequest() {
        state.productOutput = null;
    },
    getProductIdSuccess(state, output) {
        state.productOutput = output;
    },
    getProductIdFailure(state) {
        state.productOutput = false;
    },
    /* get detail for simulator with looged */
    getSimulatorDetailRequest() {
        state.productOutput = null;
    },
    getSimulatorDetailSuccess(state, output) {
        state.productOutput = output;
    },
    getSimulatorDetailFailure(state) {
        state.productOutput = false;
    },
      /* getRelated */
    getRelatedRequest() {
        state.relatedOutput = null;
    },
    getRelatedSuccess(state, output) {
        state.relatedOutput = output;
    },
    getRelatedFailure(state) {
        state.relatedOutput = false;
    },
     //sendMessage
    sendMessageRequest(state) {
        state.processTask = null;
    },
    sendMessageSuccess(state) {
        state.processTask = true;
    },
    sendMessageFailure(state) {
        state.processTask = false;
    },
};


export const products = {
    namespaced: true,
    state,
    actions,
    mutations
};