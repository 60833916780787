<template>
  <div class="overflow-auto d-flex justify-content-center w-100 pagination py-3">
    <SpinnerLoading v-if="loading"/>
    <div class="mt-5" :style="{margin:'0px', padding:'0px', minWidth:'350px'}">
      <!-- The number of pages is computed from the provided prop values for total-rows and per-page -->
      <b-pagination
         v-model="currentPage"
        :total-rows="rows"
        :per-page="itemsPerPage"
      >
      </b-pagination>    
    </div>
  </div>
</template>

<script>
import SpinnerLoading from "@/components/SpinnerLoading.vue";

  export default {
    components: {
      SpinnerLoading,
    },
    data() {
      return {
        currentPage: this.value
      }
   },
    props:{
      value: Number,
      rows: Number,
      itemsPerPage: Number,
      loading: {
        type: Boolean,
        default(){
          return false
        }
      }
    },
    watch: {
      currentPage: function(val) {
        this.$emit('input', val);    
      },
      value: function(val)
      {
        this.currentPage = val;  
      }
    }
  }
</script>
<style scoped>
>>>.page-link{
  border: 1px solid transparent;
  color: #17224d;
}
>>>.page-item.active .page-link{
  background-color: #17224d;
  border-color: #17224d;
  color: white !important;
}
>>>.page-item.disabled .page-link{
  border: 1px solid transparent;
}
>>>.page-link:hover{
  color: white;
  background-color: #17224d;
  border-color: #17224d;
  margin: 0;
}
>>>.page-item:not(:first-child) .page-link{
  margin: 0!important;
}
>>>.page-link:focus {
    box-shadow: none;
}
</style>