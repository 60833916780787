import {SimulatorSettings} from "./settings";
import {SimulatorHelpers} from "./helpers";
import {SimulatorLoaders} from "./loaders";
import {SimulatorWatchers} from "./watchers";
import * as THREE from "three";
import {IOSystems} from "@/mixins/simulator/iosystems";
import {mapActions, mapState} from "vuex";
import {API_HELPER} from "@/helpers/api";

export const Simulator = {
    mixins: [
        IOSystems,
        SimulatorHelpers,
        SimulatorLoaders,
        SimulatorSettings,
        SimulatorWatchers,
    ],
    data() {
        return {
            /**
             * Note that if the positions of the steps change, this object needs to be updated.
             */
            StepIdentifiers: {
                COLORS: 1,
                IMAGES: 2,
                UNITS: 3,
                NOTES: 4,
                CHECKOUT: 5
            },
            lazy: {
                texture: null,
            },
            objectPath: null,
            svgPath: null,
            svgDocument: null,
            productId: null,
            productDetail: null,
            pdfFilePath: null,
            taxOptions: [{value: 0, key: "Free"}, {value: 6, key: "Reduced"}, {
                value: 13,
                key: "Intermediate"
            }, {value: 24, key: "Normal"}],
        }
    },
    mounted() {
        if (this.$route.params.slug) {
            this.getProductBySlug(this.$route.params.slug);
        }
    },
    computed: {
        ...mapState({
            palettesOutput: state => state.palettes.paletteOutput,
            productsOutput: state => state.products.productOutput,
        }),
        svgModelTexture() {
            if (this.svgDocument === null) {
                return null;
            }

            /**
             * First we apply the colors to the SVG...
             */
            for (let section in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections) {

                for (let position in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[section]) {

                    if (this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[section][position]) {

                        this.svgDocument.querySelector(`#${position}`).setAttribute('fill', this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[section][position]);

                    }else {this.svgDocument.querySelector(`#${position}`).setAttribute('fill', '#FFFFFF'); }

                }

            }

            /**
             * ...then we apply the images:
             *  while the colors are straightforward, by changing the fill of the attribute where they are,
             *  for the images we have to also fill an attribute, but not necessarily the attribute that correspond
             *  to the id of the image: rather it's pattern... let's see...
             */
            for (let section in this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections) {

                for (let position in this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections[section]) {

                    let image = this.svgDocument.querySelector(`#${position}`);

                    let imagePatternId = image.getAttribute('fill').replace(/^url\((#[a-z0-9_-]+)\)$/i, '$1');

                    let usePattern = this.svgDocument.querySelector(`${imagePatternId} use`);

                    if (usePattern) {

                        let theFile = this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[section][position];

                        if (theFile) {

                            try {
                                // Add file
                                this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', theFile);
                                // Apply transformation
                                let transformLevel = this.settings.wizard.steps[this.StepIdentifiers.IMAGES].scales[position] ?? 1;

                                var element = this.svgDocument.querySelector(usePattern.getAttribute('xlink:href'));
                                element.setAttribute('transform', `translate(0 12.5) translate(50 50) scale(${transformLevel}) translate(-50 -50)`);
                            } 
                            catch (error) 
                            { 
                                this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', ''); 
                            }
                        } 
                        else 
                        { 
                            this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', ''); 
                        }
                    } 
                    else 
                    { 
                        this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', ''); 
                    }

                }

            }


            /**
             * ...then we apply the patterns:
             */
            for (let section in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns) {

                for (let position in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns[section]) {


                    let image = this.svgDocument.querySelector(`#${position}`);

                    let imagePatternId = image.getAttribute('fill').replace(/^url\((#[a-z0-9_-]+)\)$/i, '$1');

                    let usePattern = this.svgDocument.querySelector(`${imagePatternId} use`);

                    if (usePattern) {

                        let theFile = this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[section][position];
                        if (theFile) {

                            try 
                            {
                                this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', theFile);
                            } 
                            catch (error) 
                            { 
                                this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', ''); 
                            }
                        } 
                        else 
                        { 
                            this.svgDocument.querySelector(usePattern.getAttribute('xlink:href')).setAttribute('xlink:href', ''); 
                        }
                    }

                }

            }


            return (new XMLSerializer().serializeToString(this.svgDocument.documentElement));

        },
        objectDownloadPdf()
        {
            return this.pdfFilePath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.pdfFilePath]) : null;
        }
    },
    watch: {
        svgModelTexture() {
            this.applyTexture();
        },
        productsOutput: function (val) {
            if (val !== null) {
                if (val && val.data) {
                    this.productDetail = val.data;
                    this.settings.wizard.steps[3].properties.prices = val.data.prices;
                    this.productDetail.tax = this.taxOptions.find(x => x.key == this.productDetail.tax).value
                    this.settings.wizard.steps[3].properties.currentSelected.amount = (val.data.price + (val.data.price * (this.productDetail.tax / 100)));
                    this.productId = val.data.id;
                    this.getPalette(val.data.paletteId);
                    this.objectPath = API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, val.data.modelPath]);

                    this.svgPath = API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, val.data.svgPath]);
                    this.processSVGPath()

                    this.pdfFilePath = val.data.datasheet;
                }
            }
        },
        palettesOutput:
            {
                handler: function (val) {
                    if (val != null) {
                        if (val && val.data) {
                            this.settings.wizard.steps[1].properties.options = val.data.colors;
                        }
                    }
                },
                immediate: true
            },
    },
    methods: {
        ...mapActions({
            getProductBySlug: 'products/getSimulatorDetail',
            getPalette: 'palettes/getDetail',
        }),
        applyTexture() {

            if (this.svgModelTexture !== null) {

                const textureLoader = new THREE.TextureLoader();

                let blob = new Blob([
                    this.svgModelTexture
                ], {type: 'image/svg+xml'});

                let svgUrl = URL.createObjectURL(blob);

                textureLoader.load(svgUrl, (texture) => {

                    var material = new THREE.MeshBasicMaterial({
                        map: texture,
                        side: THREE.DoubleSide,
                    });

                    this.objectModel ? this.objectModel.traverse((child) => {
                        if (child.isMesh) {
                            child.material = material;
                            // child.material.color = new THREE.Color(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selection);
                        }
                    }) : null;

                    this.loaders.model = false;

                });
            }
        },
        processSVGPath() {

            fetch(this.svgPath, {
                method: 'GET',
            }).then(response => response.blob())
                .then(blob => {

                    let fileReader = new FileReader();

                    fileReader.onload = () => {

                        let domParser = new DOMParser();

                        this.svgDocument = domParser.parseFromString(fileReader.result, "text/xml");

                        this.buildStructureFromSVGDocument();

                    };

                    fileReader.readAsText(blob);

                });

        },

        buildStructureFromSVGDocument() {

            let colorSections = this.svgDocument.querySelectorAll('g[id] > g[id=colors] > g[id]');

            let imageSections = this.svgDocument.querySelectorAll('g[id] > g[id=images] > g[id]');

            let patternSections = this.svgDocument.querySelectorAll('g[id] > g[id=patterns] > g[id]');
            
            colorSections.forEach((section, index) => {
                let colorSubSections = section.querySelectorAll('path[id], rect[id], circle[id]');

                let colorSubSectionsObject = {};

                colorSubSections.forEach((colorSection) => {
                    colorSubSectionsObject[colorSection.id] = false;
                })

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.steps, +index + 1, {
                    title: `${this.$t('simulator.side')} ` + section.id,
                    name: section.id
                });

                if(this.$route.params.colors)
                {
                    let sections = colorSubSectionsObject;
                    let selectedSections = this.$route.params.colors;
    
                    for (let key in selectedSections) {
                        if (key in sections)
                        {
                            sections[key] = selectedSections[key]
                        } 
                    }
                }
                if(this.$route.params.product)
                {
                    let sections = colorSubSectionsObject;
                    let selectedSections = this.$route.params.product.colors;
    
                    for (let key in selectedSections) {
                        if (key in sections)
                        {
                            sections[key] = selectedSections[key]
                        } 
                    }
                }
                
                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.preview, section.id, JSON.parse(JSON.stringify(colorSubSectionsObject)));
                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections, section.id, JSON.parse(JSON.stringify(colorSubSectionsObject)));
            });

            imageSections.forEach((section, index) => {

                let imageSubSections = section.querySelectorAll('rect[id], circle[id]');

                let imageSubSectionsObject = {};

                imageSubSections.forEach((imageSection) => {
                    imageSubSectionsObject[imageSection.id] = false;
                });

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.steps, +index + 1, {
                    title: `${this.$t('simulator.side')} ` + section.id,
                    name: section.id
                });

                if(this.$route.params.images)
                {
                    let sections = imageSubSectionsObject;
                    let selectedSections  = this.$route.params.images;
    
                    for (let key in selectedSections ) {
                        if (key in sections)
                        {
                            let dataImage = "data:image/jpg;base64";
                            let result = dataImage.concat(',', selectedSections[key])
                            sections[key] = result;
                        } 
                    }
                }
                if(this.$route.params.product)
                {
                    let sections = imageSubSectionsObject;
                    let selectedSections = this.$route.params.product.images;
    
                    for (let key in selectedSections) {
                        if (key in sections)
                        {
                            let dataImage = "data:image/jpg;base64";
                            let result = dataImage.concat(',', selectedSections[key])
                            sections[key] = result;
                        } 
                    }
                }

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview, section.id, JSON.parse(JSON.stringify(imageSubSectionsObject)));

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections, section.id, JSON.parse(JSON.stringify(imageSubSectionsObject)));

            });


            patternSections.forEach((section) => {

                let imageSubSections = section.querySelectorAll('path[id], rect[id], circle[id]');

                let imageSubSectionsObject = {};

                imageSubSections.forEach((imageSection) => {
                    imageSubSectionsObject[imageSection.id] = false;
                });

                if(this.$route.params.patterns)
                {
                    let sections = imageSubSectionsObject;
                    let selectedSections  = this.$route.params.patterns;
    
                    for (let key in selectedSections ) {
                        if (key in sections)
                        {
                            let dataImage = "data:image/jpg;base64";
                            let result = dataImage.concat(',', selectedSections[key])
                            sections[key] = result;
                        } 
                    }
                }

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview, section.id, JSON.parse(JSON.stringify(imageSubSectionsObject)));

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns, section.id, JSON.parse(JSON.stringify(imageSubSectionsObject)));

            });
        },
        objectApplyColor(color) {
            return this.objectModel ? this.objectModel.traverse(function (child) {
                if (child.isMesh) {
                    child.material.color = new THREE.Color(color);
                }
            }) : null;
        },
        objectRotate(direction = 'back') {
            if (direction === 'back' && !this.loaders.rotating) {
                this.loaders.rotating = true;
                let intervals = (Math.PI / 20);
                let interval = setInterval(() => {
                    if (this.objectModel.rotation.y >= Math.PI) {
                        clearInterval(interval);
                        this.loaders.rotating = false;
                    } else {
                        this.objectModel.rotation.y += intervals;
                    }
                }, 30);
            } else if (direction === 'front' && !this.loaders.rotating) {
                this.loaders.rotating = true;
                let intervals = (-Math.PI / 20);
                let interval = setInterval(() => {
                    if (this.objectModel.rotation.y <= 0) {
                        clearInterval(interval);
                        this.loaders.rotating = false;
                    } else {
                        this.objectModel.rotation.y += intervals;
                    }
                }, 30);
            }
        },
        objectApplyTexture(selections = {}, previews = {}) {
            this.loaders.texture = true;
            let images = [];
            for (let face in selections) {
                for (let position in selections[face]) {
                    if (selections[face][position] !== false &&
                        previews[face][position] !== false) {
                        let path = /^https?:/i.test(previews[face][position]) ?
                            previews[face][position] :
                            `${window.location.origin}//${previews[face][position]}`;
                        images.push(Object.assign(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties[face].options[position], {
                            path: `${path}`,
                        }));
                    } else {
                        continue;
                    }
                }
            }
            this.designKitImagesGdConcatenatePromise({
                base: {
                    color: this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selection === null ? '#FFFFFF' :
                        this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selection,
                    dimensions: {
                        width: 1000,
                        height: 1000,
                    }
                },
                images: images
            }).then((response) => {

                // todo: move this away from here
                const textureLoader = new THREE.TextureLoader();
                textureLoader.load(response.data.file.url, (texture) => {
                    var material = new THREE.MeshBasicMaterial({
                        map: texture,
                        side: THREE.DoubleSide,
                    });
                    this.objectModel ? this.objectModel.traverse((child) => {
                        if (child.isMesh) {
                            child.material = material;
                            // child.material.color = new THREE.Color(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selection);
                        }
                    }) : null;
                    this.loaders.model = false;
                });

            });

        },
        objectBackgroundUpload(image = null) {
            if (!this.loaders.background) {
                this.loaders.background = true;
                let payload = new FormData();
                payload.append('image', image);
                this.mediaKitRepositoryUploadFile(payload, {
                    "Content-Type": "multipart/form-data"
                }).then((response) => {
                    if (response.data.success) {
                        (new THREE.TextureLoader()).load(response.data.file.url, (texture) => {
                            const canvasAspect = this.canvas.clientWidth / this.canvas.clientHeight;
                            const imageAspect = texture.image ? (texture.image.width / texture.image.height) : 1;
                            const aspect = imageAspect / canvasAspect;
                            
                            texture.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0;
                            texture.repeat.x = aspect > 1 ? 1 / aspect : 1;
                            
                            texture.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
                            texture.repeat.y = aspect > 1 ? 1 : aspect;
                            
                            this.scene.background = texture;
                            this.loaders.model = false;
                            this.successRequest = true;
                        });

                    } else {
                        this.$toast.bottom("Algo não correu bem ao fazer upload do seu background.")
                    }

                }).catch((error,) => {
                    this.successRequest = false;
                    this.$toast.bottom(error);
                }).finally(() => {
                    this.loaders.background = false;
                });
            }
        },
        resetBackground()
        {
            const self = this;
            self.scene.background = null;
            self.scene.background = new THREE.Color(self.background);
        },
        images() {
            let imageSelections = [];
            for(let section in this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview) {
                imageSelections = imageSelections.concat(Object.values(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[section]));
            }
            return {
                images: imageSelections
            }
        },
        processCheckoutSummary() {

            let imageSelections = [];

            let colorSelections = {};

            let patternSelections = [];

            for(let section in this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview) {
                imageSelections = imageSelections.concat(Object.values(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[section]));
            }

            for(let section in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections) {
                colorSelections = Object.assign(colorSelections, this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[section]);
            }

            for(let section in this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview) {
                patternSelections = patternSelections.concat(Object.values(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[section]));
            }


            imageSelections = imageSelections.filter(function (selection) {
                return selection !== false;
            }).length;

            patternSelections = patternSelections.filter(function (selection) {
                return selection !== false;
            }).length;

            let pricing = {
                amount: this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.prices[0] && this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.units >= this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.prices[0].minQuantity
                    ? this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.prices[0].price
                    *
                    this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.units

                    : this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.currentSelected.amount
                    *
                    this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.units,

                units: this.settings.wizard.steps[this.StepIdentifiers.UNITS].properties.units,
            };

            return {
                color: colorSelections,
                images: imageSelections,
                patterns: patternSelections,
                pricing: pricing,
                note: this.settings.wizard.steps[this.StepIdentifiers.NOTES].properties.note,
            }
        },
        getBase64FromUrl(src, callback)
        {
            // Get content from url
            var svgString = this.getContentFromUrl(src);
            // Remove any characters outside the Latin1 range
            var decoded = unescape(encodeURIComponent(svgString));

            // Now we can use btoa to convert the svg to base64
            var base64 = btoa(decoded);

            var imgSource = `data:image/svg+xml;base64,${base64}`;
            callback(imgSource);
        },
        getContentFromUrl(theUrl)
        {
            let xmlhttp = new XMLHttpRequest();            
            xmlhttp.onreadystatechange=function() {
                if (xmlhttp.readyState==4 && xmlhttp.status==200) {
                    return xmlhttp.responseText;
                }
            }
            xmlhttp.open("GET", theUrl, false);
            xmlhttp.send();
            
            return xmlhttp.response;
        },
        objectDownloadScreenshot() {
            if (!this.loaders.downloading) {
                this.loaders.downloading = true;
                this.objectTakeScreenshot((img,) => {
                    this.handleFileBrowserDownload(img.replace(this.exporter.mime, this.exporter.download), this.exporter.filename(), () => {
                        this.loaders.downloading = false;
                    });
                });
            }
        },
        objectTakeScreenshot(callback = function () {
        }) {
            try {
                let img = this.renderer.domElement.toDataURL(this.exporter.mime);
                let payload = new FormData();
                let pieces = img.split(',');
                payload.append('image', this.ArrayBufferToBlob(
                    this.base64ToArrayBuffer(pieces[1]))
                );
                this.mediaKitRepositoryUploadFile(payload, {
                    "Content-Type": "multipart/form-data"
                }).then((response) => {
                    if (response.data.success) {
                        callback(img, response.data.file);
                    } else {
                        callback(img, null);
                        this.$toast.bottom("Algo não correu bem ao fazer upload da sua imagem.")
                    }
                }).catch((error) => {
                    callback(img, null);
                    this.$toast.bottom(error);
                })
            } catch (e) {
                this.$toast.bottom("Algo não correu bem ao gerar a sua imagem.")
            }
        },


    }
};