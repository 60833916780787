<template>
  <div class="step-description">
    <div v-for="(description, index) in currentDescriptions" :key="index">
      <p class="my-2">{{ description }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BottomStepDescription',
  props: {
    currentStep: {
      required: true,
    }
  },
  computed: {
    currentDescriptions() {
      return this.descriptions[this.currentStep] ?? [];
    }
  },
  data() {
    return {
      descriptions: {},
    };
  },
  watch: {
    '$route.params.product': 
    {
      handler(val)
      {
        if(val)
        {
          this.descriptions = {};
        }
        else{
          this.descriptions = {
            1: [ this.$t("simulator.step1Description") ],
            2: [ this.$t("simulator.step2Description") ],
            3: [ this.$t("simulator.step3Description") ],
            4: [ this.$t("simulator.step4Description") ],
            5: [ this.$t("simulator.step5Description") ],          
          }        
        }
      },
      deep: true,
      immediate: true 
    }
  },
}
</script>