<template>
    <section>
        <form action="" @submit.prevent="handleMessageSubmit">
            <BasePopUp @detailsClosed="closeConfirmRequested" :modelTitle="productTitle">
                <template v-slot:content>
                    <div class="row">
                        <div class="col-12">
                            <label class="float-start py-2" for="inputEmail">{{$t("enquirePopup.email")}}</label>
                            <input class="form-control" id="inputEmail" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }"/>
                            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                                <span v-if="!$v.email.required">{{$t("enquirePopup.valEmail")}}</span>
                                <span v-if="errors.Email">{{errors.Email}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="form-group">
                            <label class="float-start py-2" for="concerns">{{$t("enquirePopup.concerns")}}</label>
                            <textarea type="text" rows="5" id="concerns" name="message" class="form-control form-rounded" v-model="content" :class="{ 'is-invalid': submitted && hasError('Content') }"></textarea>
                            <div v-if="submitted && hasError('Content')" class="invalid-feedback">
                                <span v-if="!$v.content.required">{{$t("enquirePopup.valContent")}}</span>
                                <span v-if="errors.Content">{{errors.Content}}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:footer>
                    <button class="btn btn-secondary border-0 mx-2 px-3" :disabled="loading">
                        <span v-show="!loading">{{$t("enquirePopup.submit")}}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                    </button>
                    <button class="btn btn-primary border-0 mx-2 px-3" @click="closeConfirmRequested" :disabled="loading">{{ $t("addressDetail.close") }}</button>
                </template>
            </BasePopUp>
        </form>
    </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";  
import BasePopUp from './BasePopUp.vue';

export default {
     data(){
        return{
            submitted: false,
            loading: false,
            email:"",
            content:"",
        }  
    },
    validations: {
      email: { email, required },
      content: { required },
    },
    components: {
        BasePopUp
    },
    props: {
        productTitle: String,
        productId: Number
    },
    mounted: function (){
        
    },
    methods:
    {
        ...mapActions('products', ['sendMessage']),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        },
        handleMessageSubmit() {
            this.clearAlert();
            this.submitted = true;

            //validation date

             if(this.pretendedDate > this.departureDate)
            {
                return alert(this.$t("InvData"))
            }

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            
            const { email, productId} = this;
            var content = this.content + "\n" + window.location.href;
            this.sendMessage({productId, email, content});
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
    },
    computed:
    {
        ...mapState('products', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
    },
    watch:
    {    
        action: function()
        {
            this.submitted = false;
        },
        processTask: function(val)
        {
            if(val)
            {
                this.$emit("success-alert-requested");
            }

            if(val != null)
            {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
</style>