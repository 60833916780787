<template>
  <div class="container-fluid container-style p-0">
    <!-- Slides -->
        <div class="div-title">
          <span class="title-style">{{title}}</span>
          <img class="img-style" :src="require('@/assets/svg/' + img)" alt="">
        </div>
        <vueper-slides ref="mainShop" class="slider no-shadow flex-grow-1 mb-0" 
          v-if="products && products.length"
          :bullets="false" 
          :arrows="true" 
          :touchable="false" 
          :visibleSlides="1" 
          :disableArrowsOnEdges="true" 
          :lazy="true" 
          fixed-height="300px"
          :autoplay="autoplayState"
          >        
            <vueper-slide v-for="(productInfo, index) in products" :key="index" :link="getFullUrl(index)">
                <template v-slot:content>
                  <img class="img-fluid img-settings cursor-pointer" :src="url + productInfo.photos[0]" alt="">
                </template>
            </vueper-slide>
        </vueper-slides>
      <!-- End Slides -->
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions } from "vuex";

const INFLATE = "InflateYourImagination";

const INFLATE_COLOR = "#ff0066";
const ACTIVE_COLOR = "#007fff";
export default {
  components: {VueperSlides, VueperSlide },
  data: function () {
    return {
        url: process.env.VUE_APP_URL_ROOT,
        baseUrl: process.env.VUE_APP_FRONT,
        quantity: 1,
        hover: false,
        dynamicHeight: null,
        slide: 0,
        INFLATE: INFLATE,
        INFLATE_COLOR: INFLATE_COLOR,
        ACTIVE_COLOR: ACTIVE_COLOR
    };
  },
  props: {
    brand: null, // INFLATE, ACTIVE
    products: Array,
    title: String,
    autoplayState: Boolean,
  },
  methods: {
    ...mapActions('carts', ['addCart']),
    sendClick(index){
        this.$router.push({name: "ProductDetails", params: {product: this.products[index], slug: this.products[index].slug}}); 
    },
    slideChanged: function(val)
    {
      this.slide = val.currentSlide.index;
    },
    getFullUrl: function(index)
    {
      return `${this.baseUrl}/Product/${this.products[index].slug}`;
    },
  },
  computed:{
    img: function()
      {
          return this.brand == INFLATE ? 'homeArrowInflate.svg' : 'homeArrowActive.svg';
      },
  }
}
</script>

<style scoped>
a{
    text-decoration: none;
}
.boxes-container{
  height: 300px;
  flex-wrap: wrap;
}
.box{
  width: 100%;
}
.img-settings{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#product-text{
  background-color:#17224d;
}
.title-style{
  color: white;
  font-size: 15px;
  position: absolute;
  bottom: -55px;
  z-index: 3;
  left: 10px;
}
.container-style{
  position: relative;
}
.div-title{
  position: relative;
}
.img-style{
  position: absolute;
  width: 135px;
  z-index: 2;
  height: 50px;
  top: 20px;
}
</style>