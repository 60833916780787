<template>
    <section id="shop">
        <SpinnerLoading v-if="loading || categoriesOutput == null"/>
        <!-- desktop devices -->
        <div v-if="!mobileFilter" class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-lg-2 py-4" id="menu-shop">
                    <div class="row" :style="{margin: '15px auto'}">
                        <div class="brand-category-img">
                            <a v-for="(option, index) in categoriesOptions" :key="index" class="px-2">
                                <img class="image-brand" :src="option.image" @click="updateSelectedBrand(option.value)" :style="{opacity: selectedBrandType != option.value ? '0.2' : '1'}" alt="">                                
                            </a>
                        </div>
                    </div>
                    <div class="d-none d-lg-flex align-items-center justify-content-between flex-wrap py-2"  v-for="(option, i) in categoriesInfo" :key="i">
                        <div class="d-flex" @click="selectedCategory = {f: option.id}" :title="option.id">
                            <span 
                                :class="{'selected': selectedCategory && selectedCategory.f == option.id }" 
                                :title="option.id"
                                class="font-weight-bold text-left cursor-pointer"
                            >
                                {{option.name}}
                            </span>
                            <span 
                                :class="{ 'rotated': selectedCategory && selectedCategory.f == option.id }" 
                                class="font-weight-bold arrow-circle cursor-pointer"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                                </svg>
                            </span>
                        </div>
                        <div class="w-100" v-show="selectedCategory && selectedCategory.f == option.id" v-for="(child, i) in option.children" :key="i" @click="selectedCategory = {f: option.id, s: child.id}">
                            <div class="cursor-pointer" v-bind:class="{'selected': selectedCategory && selectedCategory.s == child.id}" :style="{fontSize: '1em', margin: '5px'}"> 
                                <span>{{child.name}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- filter -->
                    <div class="d-flex justify-content-end d-lg-none filter mt-3" @click="mobileFilter = true">
                        <button class="btn btn-filter btn-primary border-0">
                            <span class="mx-3">{{$t('products.categories')}}</span>
                            <b-icon icon="sliders"></b-icon>
                        </button>
                    </div>
                </div>
                <div v-if="productsInfo && productsInfo.length" class="container-fluid col-md-12 col-lg-10 d-flex justify-content-center flex-wrap h-100 py-5">
                    <ProductThumb @success-alert-requested="onSuccessAlert(index)" v-for="(product,index) in productsInfo" :key="index" :product="product" @clickModel="sendClickModel(index)" @click="sendClick(index)"></ProductThumb>
                    <div v-if="rows >= 9" class="col-12 d-flex pagination">
                        <Pagination :rows="rows" :itemsPerPage="itemsPerPage" :loading="loading" v-model="currentPage"></Pagination>
                    </div>
                </div>
                <div v-else class="container-fluid col-12 col-xl-10" :style="{minHeight: '100vh'}">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <h2 class="d-flex justify-content-center shopEmpty-style">{{$t("shopEmpty")}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- small devices -->
        <div v-if="mobileFilter" class="container-fluid mobile-menu" :style="{minHeight: '100vh'}">
            <!-- Close -->
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="m-0 fw-bold">{{$t('products.categories')}}</h6>
                <div class="close-button cursor-pointer">
                    <b-icon @click="mobileFilter = false" icon="x"></b-icon>
                </div>
            </div>
            <div class="categories">
                <a v-for="(option, index) in categoriesInfo" :key="index">
                    <div class="d-flex align-items-center justify-content-between">
                        <span 
                            class="font-weight-bold text-left"
                            :class="{'selected': selectedCategory && selectedCategory.f == option.id }" 
                            @click="selectedCategory = {f: option.id}"
                        >
                            {{option.name}}
                        </span>
                        <span @click="growDiv(option,index)" :class="{ 'rotated': optionId.find(x => x === option.id)}" class="font-weight-bold arrow-circle">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-play-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                            </svg>
                        </span>
                    </div>
                    <div class="grow" ref="grow" id="grow" :style="[filterList[index] ? {height: `${filterList[index].height}px`} : {height: '0'}]" >
                        <div ref="measuringWrapper">
                            <div
                                v-for="(child, i) in option.children" :key="i" 
                                @click="(selectedCategory = {f: option.id, s: child.id}) && (mobileFilter = false)"                 
                            >
                                <div class="cursor-pointer" v-bind:class="{'selected': selectedCategory && selectedCategory.s == child.id}" :style="{fontSize: '1em', margin: '2px 20px'}">
                                    {{child.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InputGetProducts from "@/classes/InputGetProducts";
import ProductThumb from "@/components/ProductThumb.vue";
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import Pagination from "@/components/Pagination.vue";
import router from '@/router';
import InputPostCart from "../classes/InputPostCart";
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: {ProductThumb, SpinnerLoading, Pagination},
   metaInfo () {
    return { 
          title: this.$t("vueRouter.shopTitle"), 
          titleTemplate: '%s - FactoryPlay', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRouter.shopDescription")},
            {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRouter.shopTitle")},
            {name: 'twitter:description', content: this.$t("vueRouter.shopDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRouter.shopTitle")},
            {property: 'og:site_name', content: 'FactoryPlay'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.$t("vueRouter.shopDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'en')])},
            {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'es')])}
          ]
      } 
    }, 
    data: function () {
        return {
            itemsPerPage: localStorage.itemsPerPage ? Number(localStorage.itemsPerPage) : 9,
            categoriesOptions:
            [
                { image: require('../assets/shop/heroFormInflate.png'), value: 'InflateYourImagination'},
                { image: require('../assets/shop/heroFormActive.png'), value: 'ActiveBrands'}
            ],
            selectedCategory: {f: this.$route.query.f ?? null, s: this.$route.query.s ?? null},
            loading: false,
            currentPage: this.$route.query.p ?? 1,
            mobileFilter: false,
            showHighligted: true,
            searchInSubcategories: true
        }
    },
    computed:
    {
        ...mapState({
            productsOutput: state => state.products.listOutput,
            categoriesOutput: state => state.categories.listOutput,
            optionId: state => state.filter.optionId,
            filterList: state => state.filter.filterList
        }),
        ...mapState('account', ['brandType']),
        filter() {
            return this.filterList && this.filterList.length ? this.filterList : []
        },
        productsInfo: function(){
            return this.productsOutput && this.productsOutput.data && this.productsOutput.data.products ? this.productsOutput.data.products : [];
        },
        // Reorganize array to show ONLINE_STORE_PARENT_IDS (defined on .env file) first
        categoriesInfo: function(){
            var result = this.categoriesOutput && this.categoriesOutput.data ? this.categoriesOutput.data : [];

            var onlineStoreIds = [parseInt(process.env.VUE_APP_ONLINE_STORE_ACTIVE), parseInt(process.env.VUE_APP_ONLINE_STORE_INFLATE)];
            var element = result.find(function(element) {
                return onlineStoreIds.includes(element.id)
            });

            if(element)
            {
                const fromIndex = result.indexOf(element);
                const toIndex = 0;

                result.splice(fromIndex, 1)[0];
                result.splice(toIndex, 0, element);
            }

            return result;
        },
        rows: function()
        {
            return this.productsOutput ? this.productsOutput.totalRecords : 1;
        },
        selectedBrandType: function()
        {
            var result = 'ActiveBrands'
            if(this.$route.query.type){
                result = this.$route.query.type
            }
            else if(this.brandType){
                result = this.brandType
            }
            return result;
        }
    },
    updated() {
        // observe changes in real time
        if(this.filterList && this.filterList.length) this.getFilterList(this.filterList);
    },
    mounted: function()
    {        
        this.invokeGetCategories();        
        this.initializePage();       
    },
    methods:
    {
        ...mapActions({
          getProductsList: 'products/getList',
          getCategoriesBrand: 'categories/getBrand',
          getFilterList: 'filter/getList'
        }),
        ...mapActions('carts', ['addCart']),
        ...mapActions('account', ['updateBrand']),
        initializePage()
        {
            this.loading = true;

            // two levels
            if(this.$route.query.f && this.$route.query.s){
                this.selectedCategory = {f: this.$route.query.f ?? null, s: this.$route.query.s ?? null};
                this.showHighligted = null;
                this.searchInSubcategories = null;
            }
            // only one level
            else if(this.$route.query.f) {
                this.selectedCategory = {f: this.$route.query.f ?? null, s: null};
                this.showHighligted = null;
                this.searchInSubcategories = true;
            }
            // default
            else{
                this.showHighligted = true;
                this.searchInSubcategories = null;
            }

            // GET list of products
            const orderBy = 1; // Ascending
            const propertyName = "showFirst";
            const searchText = '';
            const status = 1; // Active
            const category = this.selectedCategory ? (this.selectedCategory.s ?? this.selectedCategory.f) : null;
            const brand = category ? null : this.selectedBrandType;
            // const inStock = true;
            
            //Make request
            var input = new InputGetProducts(this.itemsPerPage, this.currentPage, orderBy, propertyName, searchText, status, category, this.showHighligted, brand, this.searchInSubcategories);
            this.getProductsList(input);
        },
        updateSelectedBrand(val){
            if(val) {
                this.updateBrand(val)
                this.invokeGetCategories();

                // clean optionId and filterList when we change the brand type
                this.$store.state.filter.filterList = [];
                this.$store.state.filter.optionId = [];
                
                this.$router.push({ query: {brand: val} });
                location.reload();
            }
        },
        growDiv(option, index) {
            let growDiv = this.$parent.$refs.container.$refs.grow[index]
            let arr = [];
            // open dropdown
            if(!growDiv.clientHeight) {
                if(!this.optionId.length) this.optionId.push(option.id);
                else {
                    if(!this.optionId.includes(option.id)) {
                        this.optionId.push(option.id);
                    }
                }
                let wrapper =  this.$parent.$refs.container.$refs.measuringWrapper[index];
                // set auto height
                growDiv.style.height = wrapper.clientHeight + "px";
                
                for(let i = 0; i < this.categoriesInfo.length; i++ ) {
                    if(i === index) {
                        arr[i] = {
                            id: option.id,
                            height: wrapper.clientHeight
                        }
                        this.filterList[i] = arr[i]                        
                    }
                }
            }
            // close dropdown
            else {
                let returnIndex = this.optionId.indexOf(option.id);
                let returnIndexheightDiv = this.filterList.findIndex((object) => {   
                    if(object && object.id) return object.id === option.id; 
                });
                this.optionId.splice(returnIndex, 1);    
                this.filterList[returnIndexheightDiv] = null;
                // set height to 0
                growDiv.style.height = 0;
            }
        },
        invokeGetCategories()
        {
            this.loading = true;
            this.getCategoriesBrand(this.selectedBrandType);
        },
        sendClickModel(index){
            this.$router.push({name: "Simulator", params: {slug: this.productsInfo[index].slug}}); 
        },
        sendClick(index){
            this.$router.push({name: "ProductDetails", params: {product: this.productsInfo[index], slug: this.productsInfo[index].slug} });
        },
        goToCart()
        {
            router.push({name : 'Cart'});
        },
        onSuccessAlert: function(index)
        {
            this.addCart(new InputPostCart (this.productsInfo[index].id, 1));
            this.$emit("success-alert-requested", { title:`${this.productsInfo[index].name }`, message: this.$t("cart.addCart"), primaryButton: this.$t("cart.goToCart"), primaryCallback: this.goToCart, secondaryButton: this.$t("cart.close")})   
        },
        // first level, second level, page
        goToShop(f,s,p)
        {
            const query = { ...this.$route.query };

            if(f) query.f = f;
            else delete query.f;

            if(s) query.s = s;
            else delete query.s;

            if(p) query.p = p;
            else delete query.p;

            this.$router.push({  query: query })
        }
    },
    watch:{
        selectedCategory(val, oldVal) {
            if(JSON.stringify(val) !== JSON.stringify(oldVal)) 
            {
                if(this.currentPage != 1 && oldVal !== true) {
                    this.currentPage = 1;
                }else{
                    this.goToShop(val.f, val.s);
                }
            }
        },
        productsOutput(){  
            this.loading = false; 
            setTimeout(function () {
                window.scrollTo(0, 0);
            },100);
        },
        categoriesOutput(){
            this.loading = false;
        },
        currentPage(val){
            this.goToShop(this.selectedCategory ? this.selectedCategory.f : null, this.selectedCategory ? this.selectedCategory.s : null, val);
        },
        $route(to) {
            if(to.name)
            {
                this.initializePage();
            }
        },
    }
}
</script>

<style scoped>
>>>.box{
    margin: 15px;
}
/* Structure */
.menu-block
{
    margin-left: 0.2rem;
}

/* Text elements */

/* >>>button
{
    border-style: outset !important;
} */
a:hover,
button:hover
{
    color: #17224d;
}

.selected
{
    text-decoration: none;
    font-weight: bold;
    border-bottom: 2px solid #17224d;
}

div.selected
{
    text-decoration: none;
    font-weight: bold;
    color: white;
    background-color: #17224d;
}
.btn
{
    font-size: 1.2em;
}
.btn-check:focus + .btn, .btn:focus {
    box-shadow: none!important;
}
/* Others */
.arrow-circle{
    display: flex;
    padding: 0.375rem 0.75rem;
}
.rotated {
  transform: rotate(-90deg);
}
.default-rotated{
    transform: rotate(0deg);
}
.shopEmpty-style{
  color: #17224d;
}

#menu-shop
{
    background-color: whitesmoke;
}
.brand-category-img{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
@media screen and (max-width: 991px) {
    #menu-shop{
        display: flex;
      flex-direction: column;
      justify-content: center;
  }
    .image-brand{
        width: 300px;
        height: 100px;
    }
    .brand-category-img{
        flex-direction: row;
    }
}
@media screen and (max-width: 720px) {
    .brand-category-img{
        flex-direction: column;
    }
}
.close-button{
    font-size: 3rem;
}

#grow{
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    height: 0;
    transition: height .5s;
    overflow: hidden;
}
>>>.btn-filter{
    font-size: 0.8rem;
}
>>>.btn-filter:hover{
    color: white;
}
</style>