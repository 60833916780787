/* JSON Web Tokens that are used as authentication header. */

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.data && user.data.accessToken) {
        return { 'Authorization': 'Bearer ' + user.data.accessToken };
    } else {
        return {};
    }

    // return { 'Authorization': 'Bearer "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHdpbGRkb3Vyby5wdCIsIm5hbWVpZCI6Ijg3OTQ2MmQ3LTFmMGUtNDRkOS04ZmY4LTE0MTA5MDU1OTRhYSIsInJvbGUiOiJBZG1pbiIsIm5iZiI6MTYyNDM1MTg3OCwiZXhwIjoxNjI0Mzc3MDc4LCJpYXQiOjE2MjQzNTE4Nzh9.qiBXVKqFKX41bwPBGzEiyPtX5BPGpqywRahs61eqHWc' };
}