/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const NEWSLETTER_SERVICE = {
    sendNewsletter
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "newsletter";

/* Services */
function sendNewsletter( imputPostNewsletter )
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify(imputPostNewsletter);
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}