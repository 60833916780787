import { render, staticRenderFns } from "./Sharing.vue?vue&type=template&id=0b6d5af2&scoped=true"
import script from "./Sharing.vue?vue&type=script&lang=js"
export * from "./Sharing.vue?vue&type=script&lang=js"
import style0 from "./Sharing.vue?vue&type=style&index=0&id=0b6d5af2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6d5af2",
  null
  
)

export default component.exports