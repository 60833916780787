/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { authHeader } from "@/helpers/auth-header.js";

/* Exports */
// API services that can be invoked on the modules
export const PRODUCT_SERVICE = {
    list,
    getDetail,
    getRelated,
    getSimulatorDetail,
    sendMessage
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "products";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}
// Service for product details without logged
function getDetail(slug)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
//It can't be the same service because to use the simulator we have to be logged in.
// Service to get detail for simulator with looged
function getSimulatorDetail(slug)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
function getRelated(slug)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'related', slug]);    

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
function sendMessage(input)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'SendMessage']);
    
    var raw = JSON.stringify(input);
    
    const requestOptions = {
        method: 'POST',
        headers:  { ...authHeader(), "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}



