import { PALETTES_SERVICE } from "@/services/palettes.service.js";

const state = { paletteOutput: {}};

const actions = {
    getDetail({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getPaletteIdRequest');
        
        PALETTES_SERVICE.getDetail(id)
        .then(
            output => {
                commit('getPaletteIdSuccess', output);
            },
            error => {
                    commit('getPaletteIdFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* getPaletteId */
    getPaletteIdRequest() {
        state.paletteOutput = null;
    },
     getPaletteIdSuccess(state, output) {
        state.paletteOutput = output;
    },
     getPaletteIdFailure(state) {
        state.paletteOutput = false;
    },
};


export const palettes = {
    namespaced: true,
    state,
    actions,
    mutations
};