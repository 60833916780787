export default class InputPostCart
{
  constructor( productId, quantity, notes, colors, images) 
  {
    this.productId = productId;
    this.quantity = quantity;
    this.notes = notes;
    this.colors = colors;
    this.images = images;
  }    
}