import Vue from "vue";
import VueRouter from "vue-router";
import i18n from '@/helpers/i18n';
import { CONSTANTS } from '@/helpers/consts';

import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";
import Home from "../views/Home.vue";
import Cart from "../views/Cart.vue";
import ProductDetails from "../views/ProductDetails.vue";
import Shop from "../views/Shop.vue";
import Login from "../views/Login.vue";
import MyAccount from "../views/MyAccount.vue";
import ConfirmAccount from "../views/ConfirmAccount.vue";
import CheckoutPage from "../views/CheckoutPage.vue";
import Simulator from "../views/Simulator.vue";
import StaticPages from "../views/StaticPages.vue";
import ResetPassword from "../views/ResetPassword.vue";
import SearchResults from "../views/SearchResults.vue";
import Page404 from "../views/Page404.vue";

Vue.use(VueRouter);


const routes = [
    {
        path: "/",
        name: "Home",
        components: {
            header: AppHeader,
            default: Home,
            footer: AppFooter
        }
    },
    {
        path: i18n.t('vueRouter.cart'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.cart', locale.key) ),
        name: 'Cart',
        components: {
          header: AppHeader,
          default: Cart,
          footer: AppFooter
        }
    },
    {
        path: `${i18n.t('vueRouter.product')}/:slug`,
        alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRouter.product', locale.key)}/:slug` ),
        name: 'ProductDetails',
        components: {
          header: AppHeader,
          default: ProductDetails,
          footer: AppFooter
        }
    },
    {
        path: i18n.t('vueRouter.shop'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.shop', locale.key) ),
        name: 'Shop',
        components: {
          header: AppHeader,
          default: Shop,
          footer: AppFooter
        }
    },
    {
        path: i18n.t('vueRouter.login'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.login', locale.key) ),
        name: 'Login',
        components: {
          header: AppHeader,
          default: Login,
          footer: AppFooter
        }
    },
    {
        path: i18n.t('vueRouter.myAccount'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.myAccount', locale.key) ),
        name: 'MyAccount',
        components: {
          header: AppHeader,
          default: MyAccount,
          footer: AppFooter
        }
    },
    {
      path: "/ConfirmAccount",
      // path: i18n.t('vueRouter.confirmAccount'),
      // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.confirmAccount', locale.key) ),
      name: 'ConfirmAccount',
      components: {
        header: AppHeader,
        default: ConfirmAccount,
        footer: AppFooter
      }
    },
    {
      path: "/ResetPassword",
      // path: i18n.t('vueRouter.resetPassword'),
      // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.resetPassword', locale.key) ),
      name: 'ResetPassword',
      components: {
        header: AppHeader,
        default: ResetPassword,
        footer: AppFooter
      }
    },
    {
        path: i18n.t('vueRouter.checkoutPage'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.checkoutPage', locale.key) ),
        name: 'CheckoutPage',
        components: {
          header: AppHeader,
          default: CheckoutPage,
          footer: AppFooter
        }
    },
    {
        path: `${i18n.t('vueRouter.simulator')}/:slug`,
        alias: CONSTANTS.supportedLanguages.map(locale => `${i18n.t('vueRouter.simulator', locale.key)}/:slug` ),
        name: 'Simulator',
        components: {
          header: AppHeader,
          default: Simulator,
          footer: AppFooter
        }
    },
    {
        path: i18n.t('vueRouter.page'),
        alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.page', locale.key) ),
        name: 'StaticPages',
        components: {
          header: AppHeader,
          default: StaticPages,
          footer: AppFooter
        }
    },
    {
      path: i18n.t('vueRouter.searchResults'),
      alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.searchResults', locale.key) ),
      name: 'SearchResults',
      components: {
        header: AppHeader,
        default: SearchResults,
        footer: AppFooter
      },
    },
    {
      path: '*',
      name: '404',
      components: {
        header: AppHeader,
        default: Page404,
        footer: AppFooter
      }
    }, 
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
  
  export default router;