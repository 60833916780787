export const SimulatorLoaders = {
    name: 'SimulatorLoaders',
    data() {
        return {
            loaders: {
                downloading: false,
                texture: false,
                background: false,
                model: false,
                rotating: false,
            },
            successRequest : false
        }
    },
}