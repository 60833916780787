<template>
  <section id="home">
    <SpinnerLoading v-if="loading"/>
    <div class="container-fluid p-0" v-else>
      <SliderHome :products="heroProducts"></SliderHome>
      <div class="row my-5">
        <div class="col-12 col-md-6 products-col-style">
          <ProductSlider :products="inflateHighlightedProducts" :brand="INFLATE" :autoplayState="true" :title="$t('news')"></ProductSlider>
        </div>
        <div class="col-12 col-md-6 products-col-style">
          <ProductSlider :products="activeHighlightedProducts" :brand="ACTIVE" :autoplayState="true" :title="$t('news')"></ProductSlider>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-md-6 products-col-style">
          <ProductSlider :products="inflateStockProducts" :brand="INFLATE" :autoplayState="false" :title="$t('stock')"></ProductSlider>
        </div>
        <div class="col-12 col-md-6 products-col-style">
          <ProductSlider :products="activeStockProducts" :brand="ACTIVE" :autoplayState="false" :title="$t('stock')"></ProductSlider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductSlider from "@/components/home/ProductSlider.vue";
import SliderHome from "@/components/home/SliderHome.vue";
import { mapState, mapActions } from "vuex";
import SpinnerLoading from "@/components/SpinnerLoading";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { HomeResult } from "@/classes/HomeResult";

const ACTIVE = "ActiveBrands";
const INFLATE = "InflateYourImagination";
const ONLINESTORE = "Online Store";

export default {
  name: "Home",
  components: { SliderHome, SpinnerLoading, ProductSlider },
   metaInfo () {
    return { 
      title: 'FactoryPlay',
      htmlAttrs: {
          lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: 'FactoryPlay'},
        {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: 'FactoryPlay'},
        {property: 'og:site_name', content: 'FactoryPlay'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
        {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL_ROOT},
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL_ROOT},
        {rel: 'alternate', hreflang: 'es', href: process.env.VUE_APP_URL_ROOT},
      ]
    } 
  }, 
  data() {
    return {
      ACTIVE: ACTIVE,
      INFLATE: INFLATE,
      ONLINESTORE: ONLINESTORE
    }
  },
  mounted: function()
  {
    // GET list of products
    this.getAllProducts(HomeResult.ActiveInStock);
    this.getAllProducts(HomeResult.InflateInStock);
    this.getAllProducts(HomeResult.ActiveHighlighted);
    this.getAllProducts(HomeResult.InflateHighlighted);

    // GET orderId from backoffice
    const loggedIn = localStorage.getItem('user');
    if(this.$route.query.id && loggedIn)
    {
      this.getOrderDetail(this.$route.query.id);
    }
    if(this.$route.query.id && !loggedIn){
      this.$router.push({name: 'Login'});  
    }
  },
  computed:
  {
    ...mapState({
      groupedListOutput: state => state.products.groupedListOutput,
      orderOutput: state => state.orders.orderOutput,
    }),
    loading: function()
    {
      return this.heroProducts.length == 0 || this.activeHighlightedProducts.length == 0 || this.inflateHighlightedProducts.length == 0 || this.activeStockProducts.length == 0 || this.inflateStockProducts.length == 0;
    },
    heroProducts: function()
    {
      var result = [];

      if(this.groupedListOutput[HomeResult.InflateHighlighted] && this.groupedListOutput[HomeResult.InflateHighlighted].length) result.push(this.groupedListOutput[HomeResult.InflateHighlighted][0]);
      if(this.groupedListOutput[HomeResult.ActiveHighlighted] && this.groupedListOutput[HomeResult.ActiveHighlighted].length) result.push(this.groupedListOutput[HomeResult.ActiveHighlighted][0]);
      
      return result;
    },
    activeHighlightedProducts: function()
    {
      var result = this.groupedListOutput[HomeResult.ActiveHighlighted] ?? [];
      const length = result.length;

      if(length)
      {
        result = result.slice(1);
      }
      
      return result;
    },
    inflateHighlightedProducts: function()
    {
      var result = this.groupedListOutput[HomeResult.InflateHighlighted] ?? [];
      const length = result.length;

      if(length)
      {
        result = result.slice(1);
      }
      
      return result;
    },
    activeStockProducts: function()
    {
      return this.groupedListOutput[HomeResult.ActiveInStock] ?? [];
    },
    inflateStockProducts: function()
    {
      return this.groupedListOutput[HomeResult.InflateInStock] ?? [];
    },
  },
  methods: {
    ...mapActions({
        getAllProducts: 'products/getAll',
        getOrderDetail: 'orders/getDetail'
    }),
  },
  watch: 
  {
    orderOutput: function(val)
    {
      if(val && val.data && val.data.products)
      {
        const params = new URLSearchParams(window.location.search);
        if(params.has('index'))
        {
          const index = params.get('index');
          let productDetail = val.data.products[index];
          this.$router.push({name: 'Simulator', params: {slug: productDetail.slug, product: productDetail}});
        }
      }
    }
  }
}
</script>

<style scoped>
>>>.box {
  width: 100%!important; 
}
.products-col-style{
  padding:0 30px;
}
@media screen and (max-width: 991px) {
  .brands-container
  {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .products-col-style{
    padding:30px 30px;
  }
}

  @media screen and (min-width: 1200px) {
    >>>.img-footer{
      background-image: url("../assets/footer/footer-img-home.png") !important;
    }
  }
</style>