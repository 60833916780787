<template>
  <div class="is-block">
    <input id="showHuman" type="checkbox" name="showHuman" class="switch is-rounded is-rtl" v-model="showing">
    <label for="showHuman">{{$t("simulator.showHuman")}}</label>
  </div>
</template>
<script>
export default {
  name: 'ShowHuman',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showing: false,
    }
  },
  watch: {
    selected() {
      this.showing = this.selected;
    },
    showing() {
    if(this.selected !== this.showing) {
      this.$emit('showing', this.showing);
      }
    }
  }
}
</script>