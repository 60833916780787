<template>
    <section>
        <div class="navbar-container">
            <div class="navbar">
                <ul>
                  <li><router-link :to="{ name: 'Shop'}">{{$t("products.shop")}}</router-link></li>
                  <li><a @click="sendClicked(1)">{{$t("header.aboutUs")}}</a></li>
                  <li><a @click="sendClicked(2)">{{$t("header.contacts")}}</a></li>
                </ul>
            </div>
            <div class="pop-up-close">
                <p class="pop-up-close-p"><img id="btn-close" alt="close" class="w-100 sizebtn" src="@/assets/close.svg" @click="$emit('close')"/></p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  data(){
    return{
      drag: false,
    }
  },
    mounted() {
      document.addEventListener('mousedown', () => this.drag = false);
      document.addEventListener('mousemove', () => this.drag = true);
      document.addEventListener('mouseup', this.handleMouseUp);
  },
  destroyed() {
      document.removeEventListener('mousedown', () => this.drag = false);
      document.removeEventListener('mousemove', () => this.drag = true);
      document.removeEventListener('mouseup', this.handleMouseUp);
  },
  methods: {
    sendClicked (id) {
      this.$router.push({name: 'StaticPages', query: {id: id} });
    },
    handleMouseUp(event) {
      var toggle = document.getElementById("navbar-toggle");
      if(toggle.classList.contains("not-collapsed") && !this.drag)
      {
        var element = document.getElementById("sidebar-1");
        var isClickInside = element.contains(event.target);
        var isAnchor = event.target.tagName == 'a';
        var isToggle = event.target.classList.contains("sizebtn");

        if (!isToggle && (!isClickInside || (isClickInside && isAnchor))) 
        {
          document.getElementById("btn-close").click();
        }
      }

      this.drag = false;
    },
    simulateHamburgerClick()
    {
      document.getElementById("btn-close").click();
    },
  },

 };
</script>

<style scoped>
.navbar-container{
  background-color: rgba(0,0,0,0.9);
  width: 320px;
  height: 100vh;
  margin-left: -15px;
  position: relative;
  text-align: left;
}

.b-sidebar-right .navbar-container
{
  text-align: right;
}

.b-sidebar-right .navbar
{
  justify-content: flex-end;
  padding-right: 22px;
}

.pop-up-close-p
{
  position: absolute;
  top: 30px;
  left: 53px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  z-index: 9999999;
}

.b-sidebar-right .pop-up-close-p
{
  left: auto;
  right: 40px;
}


ul {
  list-style-type: none;
  margin-top: 15vh;
  margin-left: 1vw;
  font-size: 18px;
  z-index: -111;
  
}

li a {
  display: block;
  color:white !important;
  padding: 8px 16px;
  text-decoration: none !important;
}

/* Change the link color on hover */
li a:hover {
  color: rgb(155, 155, 155);
}

</style>