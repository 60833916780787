<template>
  <button class="button is-white is-large"
          :disabled="downloading"
          @click="$emit('click')">
    <span class="mr-5 is-size-6 is-inline-block">
      {{ downloading ? this.$t("simulator.downloading") : this.$t("simulator.download") }}
    </span>
    <span class="icon ml-2 is-size-6">
      <span :class="['fa', {
        'fa-download': !downloading,
        'fa-spin fa-spinner': downloading,
      }]"></span>
    </span>
  </button>
</template>
<script>
export default {
  name: 'Download',
  props: {
    downloading: {
      type: Boolean,
      default: false,
    },
  }
}
</script>