<template>
<div>

  <div class="checkout-step" id="myIframe">
    <button class="button is-white opacity-1 has-text-weight-bold has-text-left d-flex flex-wrap h-100">
      <span style="width: 100px;" class=" is-inline-block">{{$t("simulator.color")}}</span>

      <template v-if="selections.color">
      <span v-for="(color, index) in selections.color" class="icon ml-3 " :key="index" :style="{
        color: color
      }">
        <span class="fa fa-circle"></span>
      </span>
      </template>
      <span class="is-invalid pl-4" v-else>{{$t("simulator.missing")}}</span>
    </button>
    <button class="button is-white opacity-1 has-text-weight-bold has-text-left is-block">
      <span style="width: 100px;" class=" is-inline-block">{{$t("simulator.images")}}</span>
      <span class=" ml-3 ">
        {{ selections.images }} {{ selections.images == 1 ? this.$t("simulator.chosenImage") : this.$t("simulator.chosenImages") }}
      </span>
    </button>
    <button class="button is-white opacity-1 has-text-weight-bold has-text-left is-block">
      <span style="width: 100px;" class=" is-inline-block">{{$t("simulator.price")}}</span>
      <span class=" ml-3 ">
        {{ formatAmount(selections.pricing.amount) }} ({{ selections.pricing.units }} {{$t("simulator.unity")}})
      </span>
    </button>
    <button class="button is-white opacity-1 has-text-weight-bold has-text-left is-block">
      <span style="width: 100px;" class=" is-inline-block">{{$t("simulator.notes")}}</span>
      <span v-if="selections.note" class=" ml-3 ">
        {{ selections.note }} 
      </span>
      <span class="is-invalid pl-4" v-else>{{$t("simulator.missing")}}</span>
    </button>
  </div>
</div>
</template>
<script>
export default {
  name: 'CheckoutStep',
  props: {
    selections: {
      type: Object,
    },
    images: {
      type: Object
    }
  },
  data() {
    return {

    };
  },
  methods: {
    formatAmount(amount) {
      return new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount);
    },
  }
}
</script>

<style scoped>
>>>.is-invalid
{
  color: #e55353;
}
</style>