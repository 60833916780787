import ColorPicker from "@/components/Simulator/Stepper/Steps/ColorPickerStep";
import ImagePicker from "@/components/Simulator/Stepper/Steps/ImagePickerStep";
import UnitsStep from "@/components/Simulator/Stepper/Steps/UnitsStep";
import NotesStep from "@/components/Simulator/Stepper/Steps/NotesStep";
import CheckoutStep from "@/components/Simulator/Stepper/Steps/CheckoutStep";

export const SimulatorSettings = {
    name: 'SimulatorSettings',
    data() {
        return {
            settings: {
                wizard: {
                    steps: {
                        1: {
                            title: this.$t("simulator.background"),
                            component: ColorPicker,
                            properties: {
                                currentStep: 1,
                                steps: {},
                                options: null,
                                preview: { },
                                selections: {},
                                patterns: {},
                                patterns_preview: {},
                            },
                            handlers: {
                                color: 'handleColorSelection',
                                step: 'handleColorHandlersStepSelection',
                                file: 'handlePatternHandlersImageUpload'

                            }
                        },
                        2: {
                            title: this.$t("simulator.logos"),
                            component: ImagePicker,
                            properties: {
                                currentStep: 1,
                                steps: { },
                                preview: { },
                                selections: { },
                            },
                            handlers: {
                                step: 'handleImageHandlersStepSelection',
                                file: 'handleImageHandlersImageUpload',
                                increase: 'handleImageHandlersIncreaseSize',
                                decrease: 'handleImageHandlersDecreaSize',
                            },
                            scales: []
                        },
                        3: {
                            title: this.$t("simulator.unity"),
                            component: UnitsStep,
                            properties: {
                                currentSelected: {
                                    amount: null,
                                },
                                units: this.$route.params.quantity ? this.$route.params.quantity : 1,
                                prices: null
                            },
                            handlers: {
                                units: 'handleUnitsSelection',
                            }
                        },
                        4: {
                            title: this.$t("simulator.notes"),
                            component: NotesStep,
                            properties: {
                                note: this.$route.params.notes ? this.$route.params.notes : "",
                            },
                            handlers: {
                                note: 'handleNoteSelection'
                            },
                        },
                        5: {
                            title: this.$t("simulator.checkout"),
                            component: CheckoutStep,
                            preProcessedProperties: {
                                images: 'images',
                                selections: 'processCheckoutSummary',
                            }
                        },
                    },
                    currentStep: 1,
                },
            },
            exporter: {
                mime: "image/png",
                download: "image/octet-stream",
                filename() {
                    return "MySimulation.png";
                },
                serving: {},
            },
        };
    },
    methods: {
        handleColorSelection(name, position, color) {
            if(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[name][position] === color) {
                this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[name][position] = null;
            } else { 
                this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.selections[name][position] = color;                    
            }
        },

        handlePatternSelection(name, position, file) {
            if(file)
            {
                this.getBase64(file).then((base64) => {
                    this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[name], position, base64);
                    this.$nextTick(() => {
                        this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns[name][position] = file;
                    })
                })
            } else {
                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[name], position, false);
                this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns[name][position] = false;
            }

        },

        handleImageHandlersStepSelection(step) {
            this.settings.wizard.steps[this.settings.wizard.currentStep].properties.currentStep = +step;
            if(this.settings.wizard.steps[this.settings.wizard.currentStep].component === ImagePicker) {
                if(this.settings.wizard.steps[this.settings.wizard.currentStep].properties.currentStep === 1) {
                    this.objectRotate('front');
                } else { this.objectRotate('back'); }
            }
        },
        handleColorHandlersStepSelection(step) {
            this.settings.wizard.steps[this.settings.wizard.currentStep].properties.currentStep = +step;
            if(this.settings.wizard.steps[this.settings.wizard.currentStep].component === ColorPicker) {
                if(this.settings.wizard.steps[this.settings.wizard.currentStep].properties.currentStep === 1) {
                    this.objectRotate('front');
                } else { this.objectRotate('back'); }
            }
        },
        
        handleImageHandlersImageUpload(name, position, file, preview) {
            if(file)
            {
                this.getBase64(preview ?? file).then((base64) => {
                    this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[name], position, base64);
                    this.$nextTick(() => {
                        this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections[name][position] = file;
                    })
                })

                // this.fallbackUploadFileFrame(this.settings.wizard.currentStep, name, position, file);
            } else {

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview[name], position, false);
                this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections[name][position] = false;
            }
        },
        handlePatternHandlersImageUpload(name, position, file) {
            if(file)
            {
                this.getBase64(file).then((base64) => {
                    this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[name], position, base64);
                    this.$nextTick(() => {
                        this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns[name][position] = file;
                    })
                })
                // this.fallbackUploadFileFrame(this.settings.wizard.currentStep, name, position, file);
            } else {

                this.$set(this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns_preview[name], position, false);
                this.settings.wizard.steps[this.StepIdentifiers.COLORS].properties.patterns[name][position] = false;
            }
        },
        handleImageHandlersIncreaseSize(position)
        {
            var scale = this.settings.wizard.steps[this.StepIdentifiers.IMAGES].scales[position] ?? 1;
            var newScale = scale < 0 ? scale * 2 : scale + 1;

            this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].scales, position, newScale);
        },
        handleImageHandlersDecreaSize(position)
        {
            var scale = this.settings.wizard.steps[this.StepIdentifiers.IMAGES].scales[position] ?? 1;
            var newScale = scale <= 1 ? scale / 2 : scale - 1;

            this.$set(this.settings.wizard.steps[this.StepIdentifiers.IMAGES].scales, position, newScale);
        },
        /**
         * @deprecated due to the fact that I don't think we need to execute this anymore.
         * @param currentStep
         * @param name
         * @param position
         * @param file
         * @returns {boolean}
         */
        fallbackUploadFileFrame(currentStep, name, position, file) {

            let payload = new FormData();
            payload.append('image', file);
            this.mediaKitRepositoryUploadFile(payload, {
                "Content-Type": "multipart/form-data"
            }).then((response) => {
                if(response.data.success) {
                    // this.settings.wizard.steps[currentStep].properties.preview[name][position] = response.data.file.url;
                    // this.loaders.model = true;
                    this.$root.$emit('upload.file.loading.success', currentStep, name, position, response.data);
                } else { this.handleBackgroundError('Oops, something went wrong with your upload.', currentStep, name, position, file); }
            }).catch((error) => { this.handleBackgroundError(error, currentStep, name, position, file); }).finally(() => {

            });
        },
        handleBackgroundError(message = '', currentStep, name, position) {
            this.$toast.bottom(message, { duration: 2500 });
            // this.loaders.model = true;
            this.$root.$emit('upload.file.loading.error', currentStep, name, position);
        },
        handleUnitsSelection(units) {   
            this.settings.wizard.steps[this.settings.wizard.currentStep].properties.units = units;  
        },
        handleNoteSelection(note) {
            this.settings.wizard.steps[this.settings.wizard.currentStep].properties.note = note;
        }

    }
};