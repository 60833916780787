import ColorPickerStep from "@/components/Simulator/Stepper/Steps/ColorPickerStep";
import ImagePickerStep from "@/components/Simulator/Stepper/Steps/ImagePickerStep";
import UnitsStep from "@/components/Simulator/Stepper/Steps/UnitsStep";

export const SimulatorWatchers = {
    name: 'SimulatorWatchers',
    watch: {
        'settings.wizard.steps': {
            deep: true,
            handler(steps) {
                let currentStep = steps[this.settings.wizard.currentStep];
                if(currentStep.component === ColorPickerStep) {
                    if(currentStep.properties.selection === null) {
                        // this.loaders.model = true;
                        // this.objectApplyTexture(
                        //     this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections,
                        //     this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview
                        // );
                    } else {
                    //    this.objectApplyColor(currentStep.properties.selection);
                    //     this.loaders.model = true;
                        // this.objectApplyTexture(
                        //     this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.selections,
                        //     this.settings.wizard.steps[this.StepIdentifiers.IMAGES].properties.preview
                        // );

                    }
                } 
                else if(currentStep.component === ImagePickerStep) {
                    // for this step, the process is handled as an event.
                } else if(currentStep.component === UnitsStep) {
                    //
                }
            }
        }
    },
    mounted() {
        this.$root.$on('upload.file.loading.success', (currentStep) => {
            // this.objectApplyTexture(
            //     this.settings.wizard.steps[currentStep].properties.selections,
            //     this.settings.wizard.steps[currentStep].properties.preview
            // );
            console.log('upload.file.loading.success', (currentStep));
        });
        this.$root.$on('upload.file.cleared', (currentStep) => {
            // this.objectApplyTexture(
            //     this.settings.wizard.steps[currentStep].properties.selections,
            //     this.settings.wizard.steps[currentStep].properties.preview
            // );
            console.log('upload.file.cleared', (currentStep));
        });
    },
    // Currently resizing is not supported on the simulator
    created() {
        window.addEventListener("resize", this.resizeHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    methods:
    {
        resizeHandler() {
            let wrapper = document.getElementById("simulator-wrapper");
      
            if (wrapper == null) {
              return false;
            }
      
            this.dimensions.window.width = wrapper.offsetWidth;
            this.dimensions.window.height = this.canvas.offsetHeight;
      
            this.camera.aspect = wrapper.clientWidth / wrapper.clientHeight;
            this.camera.updateProjectionMatrix();
        
            this.renderer.setSize(
                this.dimensions.window.width,
                this.dimensions.window.height
            );        
        }
    }
}