<template>
    <BasePopUp @detailsClosed="closeConfirmRequested">
        <template v-slot:content>
            <div class="popup-addresses d-flex">
                <div v-if="order[0].type === 'Billing' " class="modal-body">
                    <h4 class="mb-4 text-left fw-bold">{{ $t("addressDetail.billAddress") }}</h4>
                    <p v-if="order[0].name" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.name") }}: </span>{{order[0].name}}</p>
                    <p v-if="order[0].email" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.email") }}: </span>{{order[0].email}}</p>
                    <p v-if="order[0].phone" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.phone") }}: </span>{{order[0].phone}}</p>
                    <p v-if="order[0].companyName" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.company") }}: </span>{{order[0].companyName}}</p>
                    <p v-if="order[0].vat" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.nif") }}: </span>{{order[0].vat}}</p>
                    <p v-if="order[0].country" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.country") }}: </span>{{order[0].country}}</p>
                    <p v-if="order[0].street" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.address") }}: </span>{{order[0].street}}</p>
                    <p v-if="order[0].zipCode" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.zip") }}: </span>{{order[0].zipCode}}</p>
                    <p v-if="order[0].state" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.location") }}: </span>{{order[0].state}}</p>
                    <p v-if="order[0].city" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.city") }}: </span>{{order[0].city}}</p>
                </div>
                <div v-if="order[1].type === 'Shipping'" class="modal-body">
                    <h4 class="mb-4 text-left fw-bold">{{ $t("addressDetail.shipAddress") }}</h4>
                    <p v-if="order[1].name" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.name") }}: </span>{{order[1].name}}</p>
                    <p v-if="order[1].email" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.email") }}: </span>{{order[1].email}}</p>
                    <p v-if="order[1].phone" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.phone") }}: </span>{{order[1].phone}}</p>
                    <p v-if="order[1].companyName" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.company") }}: </span>{{order[1].companyName}}</p>
                    <p v-if="order[1].vat" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.nif") }}: </span>{{order[1].vat}}</p>
                    <p v-if="order[1].country" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.country") }}: </span>{{order[1].country}}</p>
                    <p v-if="order[1].street" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.address") }}: </span>{{order[1].street}}</p>
                    <p v-if="order[1].zipCode" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.zip") }}: </span>{{order[1].zipCode}}</p>
                    <p v-if="order[1].state" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.location") }}: </span>{{order[1].state}}</p>
                    <p v-if="order[1].city" class="mb-2 text-left"><span class="fw-bold">{{ $t("checkout.city") }}: </span>{{order[1].city}}</p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-primary border-0 mx-2 px-3" @click="closeConfirmRequested">{{ $t("addressDetail.close") }}</button>
        </template>    
    </BasePopUp>        
</template>

<script>
import BasePopUp from './BasePopUp.vue';

export default {
    props: {
        order: {
            type: Array
        },
    },
    components: {
        BasePopUp,
    },
    methods:{
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 576px) {
  .popup-addresses{
    flex-direction: column;
  }
}
</style>