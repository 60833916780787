<template>
  <section id="header">
    <!-- Sidebar -->
    <b-sidebar id="sidebar-1" width="auto" no-header bg-variant="no-light">
      <template #default="{ hide }">
        <SideMenu @close="hide"></SideMenu>
      </template>
    </b-sidebar>
    <nav class="navbar nav p-0">
      <!-- Left side -->
      <div v-if="!brandType" class="d-xxl-none justify-content-start button-sidebar p-3">
        <b-button id="navbar-toggle" v-b-toggle.sidebar-1 variant="transparent" size="lg">
          <b-icon icon="list" variant="light"></b-icon>
        </b-button>
      </div>
      <div class="d-flex align-items-center gap-4 w-50 p-3 justify-content-start flex-grow-1" :class="{'logo-inflate':(brandType == INFLATE && currentRoute === $t('vueRouter.shop')) || (brandType == INFLATE && currentRoute.includes($t('vueRouter.product'))), 'logo-active': (brandType == ACTIVE && currentRoute === $t('vueRouter.shop')) || ( brandType == ACTIVE && currentRoute.includes($t('vueRouter.product')))}">
        <b-button v-if="brandType" id="navbar-toggle" v-b-toggle.sidebar-1 variant="transparent" size="lg">
          <b-icon icon="list" variant="light"></b-icon>
        </b-button>
        <a href="/"><img class="img img-fluid" src="../assets/logo_white.svg" alt=""></a>
        <!-- <div v-if="brandType == INFLATE" class="brand-title cursor-pointer flex-grow-1"><span class="font-weight-bold">{{$t('hero.inflateBrand')}} </span>{{$t('hero.inflateSlogan')}}</div>
        <div v-if="brandType == ACTIVE" class="brand-title cursor-pointer flex-grow-1"><span class="font-weight-bold">{{$t('hero.activeBrand')}} </span>{{$t('hero.activeSlogan')}}</div> -->
        <!-- Shop -->
        <li v-if="brandType" class="nav-item d-none d-lg-block">
          <router-link class="router-link px-2" :to="{ name: 'Shop'}">{{$t("header.viewAllProducts")}}</router-link>
        </li>
      </div>
      <!-- Right side -->
      <div class="d-flex justify-content-end right-items p-3">
        <!-- Shop -->
        <li v-if="!brandType" class="nav-item d-none" :class="{'d-xxl-block': !searching}">
          <router-link class="router-link px-2" :to="{ name: 'Shop'}">{{$t("header.viewAllProducts")}}</router-link>
        </li>
        <!-- About us -->
        <li class="nav-item d-none" :class="{'d-xxl-block': !searching}">
          <a class="router-link px-2" @click="sendClicked(1)">{{$t("header.aboutUs")}}</a>
        </li>
        <!-- Contacts -->
        <li class="nav-item d-none" :class="{'d-xxl-block': !searching}">
          <a class="router-link px-2" @click="sendClicked(2)">{{$t("header.contacts")}}</a>
        </li>
        <!-- Locale -->
        <li v-show="!searching" class="nav-item" id="dropdown">
          <div class="dropdown" id="dropdown">
            <button class="btn .bg-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{ $i18n.locale.toUpperCase() }}</button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li>
                <a href="#" @click.prevent="setLocale('pt')" class="dropdown-item">
                  <span class="ml-2 h-8 w-8">{{$t("header.portuguese")}}</span>
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="setLocale('es')" class="dropdown-item">
                  <span class="ml-2 h-8 w-8">{{$t("header.spanish")}}</span>
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="setLocale('en')" class="dropdown-item">
                  <span class="ml-2 h-8 w-8">{{$t("header.english")}}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- My account -->
        <li v-show="!searching" class="nav-item">
          <router-link :to="{name: 'MyAccount'}">
            <b-icon icon="person-circle" class="cursor-pointer"></b-icon>
          </router-link>
        </li>
        <!-- Cart -->
        <li v-show="!searching" class="nav-item">
          <router-link :to="{name: 'Cart'}">
            <b-icon icon="cart" class="cursor-pointer"></b-icon>
          </router-link>
        </li>
        <li class="nav-item search-box d-none d-sm-flex" @mouseover="searching = true" @mouseleave="searching = false">
          <b-icon icon="search" class="search-btn cursor-pointer" :class="[searching ? 'mx-1' : null]" @click="searching = !searching"></b-icon>
          <input v-show="searching" :placeholder="$t('searchingFor')" class="mr-3 search-txt" v-model="searchQuery" v-on:keyup.enter="searchClicked">
        </li>
        <!-- mobile search-->
        <li class="nav-item search-box d-flex d-sm-none" @click="mobileSearch = true">
          <b-icon icon="search" class="search-btn cursor-pointer"></b-icon>
        </li>
      </div>
    </nav>
    <transition name="fade">
      <div class="search-mobile" v-if="mobileSearch">
        <!-- Close -->
        <div class="close-button cursor-pointer d-flex justify-content-end">
          <b-icon @click="mobileSearch = false" icon="x" variant="light" class="icon-close"></b-icon>
        </div>
        <div class="search_field-mobile" id="search">
          <div class="d-flex">
            <input class="inp w-100" :placeholder="$t('searchingFor')" v-model="searchQuery" v-on:keyup.enter="searchClicked"/>
          </div>
        </div>
        <button class="btn btn-primary border-0 w-100" @click="searchClicked">{{ $t("header.search") }}</button>
      </div>
    </transition>
  </section>
</template>
<script>
import SideMenu from "@/components/SideMenu.vue";
import { mapState, mapActions } from "vuex";

const ACTIVE = "ActiveBrands";
const INFLATE = "InflateYourImagination";

export default {
  components: {
      SideMenu,
    },
    data() {
    return {
      searching: false,
      searchQuery: '',
      //
      ACTIVE: ACTIVE,
      INFLATE: INFLATE,
      mobileSearch: false,
      currentRoute: this.$route.path,
      currentType: this.$route.query.type,
      baseUrl: process.env.VUE_APP_FRONT,
    };
  },
  computed:
  {
    ...mapState('account', ['brandType']),
  },
  methods: {
    ...mapActions('account', ['updateBrand']),
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.$router.push("/");
      this.$router.go(0);
    },
    searchClicked()
    {
      if(this.searchQuery)
      {
        this.$router.push({name: 'SearchResults', query: { query: this.searchQuery } });
        this.searchQuery = '';
        this.searching = false;
        this.mobileSearch = false;
      }
    },
    sendClicked (id) {
      this.$router.push({name: 'StaticPages', query: {id: id} });
    },
    getFullUrl: function()
    {
      return `${this.baseUrl}/Product/${this.products.slug}`;
    },
  },
  watch: {
    '$route.path': function(newVal) {
      this.currentRoute = newVal
    },
  }
  }
</script>
<style scoped>
#header
{
  background-color: #17224d;
}
[class^="icon-"], [class*=" icon-"] {
  color: white;
}
#dropdown > button,
.nav-item svg,
.router-link
{
  color: white;
}
.img{
  width: 250px;
}
.nav-item svg {
  font-size: 20px;
}
.nav-item {
  white-space: nowrap;
  margin: auto 5px;
}
.nav-item a:hover
{
  opacity: 0.8;
}
.router-link
{
  text-decoration: none;
}
.search-txt {
  border: none;
  background:none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  border-bottom: 1px solid #fff;
  width: 30vw;
}
::placeholder { 
  color: white;
  opacity: 1; 
}

.brand-title
{
  color: white;
  font-size: 18px;
}
.logo-inflate
{
  background-color: #ff0066 !important;
}
.logo-active
{
  background-color: #007fff !important;
}

@media (max-width: 767px) {
  .header{
    height: 15vh;
    min-height:125px;
  }

  .right-items{
    width: 100%!important;
    justify-content: end!important;
    margin-top: 1rem;
  }
  .right-items li#dropdown{
    justify-content: flex-start!important;
    display: flex!important;
    width: 100%!important;
  }  

  /* css because of the iphone, min width put the logo out of alignment */
  .img{
    width: 100%;
    min-width: unset!important;
  }
  .button-sidebar{
    width: 50%;
  }
}

/* PLaceholder */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255,255,255,0.5);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255,255,255,0.5);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255,255,255,0.5);
}
.search-mobile{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #17224d;
  z-index: 9999;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
}
.search_field-mobile{
  border-bottom: 1px solid white;
  margin: 2rem auto;
}
.icon-close{
  font-size: 3rem;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  }
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.inp {
  border: none;
  background-color: transparent !important;
  color: white;
  outline: none;
  font-size: 1.5rem;
}
.btn-primary{
  background-color: white!important;
  color: #17224d;
}
.btn-primary:hover{
  color: #17224d;
}
.btn:focus {
  box-shadow: none!important;
}

</style>