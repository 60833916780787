<template>
  <div v-if="steps" class="stepper buttons mb-0 w-100">
    <button :class="buttonClass"
            :disabled="disabled"
            @click="$emit('step', (index))"
            v-for="(step, index) in steps" style="height: auto"
            :key="index">
      <span 
        v-if="$route.params.product && index != 5"  
        class="icon is-large mr-1 mt-1">
          <span :class="['fas', {
            'fa-circle' : +currentStep !== (+index),
            'fa-check-circle' : +currentStep === (+index),
          }, 'is-size-5']"></span>
      </span>

      <span 
        v-if="!$route.params.product"  
        class="icon is-large mr-1 mt-1">
          <span :class="['fas', {
            'fa-circle' : +currentStep !== (+index),
            'fa-check-circle' : +currentStep === (+index),
          }, 'is-size-5']"></span>
      </span>

      <span v-if="$route.params.product && index != 5"  class="is-small is-size-6 has-text-left ml-2"  style="line-height: 17px">
        <template v-if="subtitle">
          <small class="is-size-7 ">{{$t("simulator.step")}} {{ index }}</small><br>
        </template>
        <span class="has-text-weight-bold">{{ step.title }}</span>
      </span>

      <span v-if="!$route.params.product" class="is-small is-size-6 has-text-left ml-2"  style="line-height: 17px">
        <template v-if="subtitle">
          <small class="is-size-7 ">{{$t("simulator.step")}} {{ index }}</small><br>
        </template>
        <span class="has-text-weight-bold">{{ step.title }}</span>
      </span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'Stepper',
  props: {
    buttonClass: {
      type: Array,
      default() {
        return [
          'button', 'is-white', ' is-align-items-flex-start', 'mb-0'
        ];
      }
    },
    subtitle: {
      required: false,
      type: Boolean,
      default() {
        return true;
      }
    },
    steps: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    currentStep: {
      required: true,
      type: Number,
      default: 1,
    }
  },
  watch: {
    '$route.params.product': 
    {
      handler(val)
      {
        if(val)
        {         
          this.steps[5] = {};
        }
      },
      deep: true,
      immediate: true 
    }
  },
}
</script>