import Vue from 'vue'
import App from './App.vue'
import i18n from '@/helpers/i18n';
//Router
import router from "./router";
// JQuery
import 'jquery/src/jquery.js'

// Vuex
import store from "./store";

// Country Plugin
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

// VuePhoneNumber Plugin
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// SimpleAlert
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);

//BootstrapVue 

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


// animations
import AOS from 'aos'
import 'aos/dist/aos.css'





const options = {
  locale: i18n.locale,
  fonts: [
    {
      src: "https://fonts.googleapis.com/css?family=Ruda",
      family: 'Ruda',
      style: 'normal',
    },
  ],
};
Vue.use(options);
Vue.config.productionTip = false

import 'vue2-toast/lib/toast.css';
// @ts-ignore
import Toast from 'vue2-toast';
Vue.use(Toast);

// @ts-ignore
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(Toast);
Vue.use(VueAxios, axios);


// Scroll to top on route change
router.beforeEach((to, from, next) => {
  // Scroll to top between each route change
  setTimeout(function () {
      window.scrollTo(0, 0);
    },100);

  next();

  // Block logged in only pages pages
  const loggedIn = localStorage.getItem('user');
  const privatePages = ['MyAccount', 'Cart', 'Simulator'];
  const authRequired = privatePages.includes(to.name);
  if (authRequired && !loggedIn) {
    next('/Login');
  }

})

new Vue({
  i18n,
  router,
  store,
  created () {
    AOS.init()
  },
  render: (h) => h(App),
}).$mount("#app");