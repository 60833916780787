<template>
  <div class="image-picker">
    <stepper :steps="steps" :current-step="currentStep" @step="updateCurrentStep" :button-class="['button', 'is-white']" :disabled="$parent.loaders.rotating" :subtitle="false"/>
    <div id="images-container">
      <div v-for="(position, index) in positions" :key="index">
        <div class="is-relative">
          <div class="box is-fullwidth p-3 mb-0">
            <!-- Action buttons (delete, rotate & resize) -->
            <div class="d-flex justify-content-end gap-1" v-if="selections[steps[currentStep].name][position] !== false && !$route.params.product">
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="rotateLeft(position)">
                <span class="icon">
                  <span class="fa fa-undo"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="rotateRight(position)">
                <span class="icon">
                  <span class="fa fa-redo"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="decreaseSize(position)">
                <span class="icon">
                  <span class="fa fa-minus"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="increaseSize(position)">
                <span class="icon">
                  <span class="fa fa-plus"></span>
                </span>
              </button>
              <button class="button is-danger is-small is-inverted" @click.prevent.stop="cleanUpPosition(position)">
                <span class="icon">
                  <span class="fa fa-trash-alt"></span>
                </span>
              </button>
            </div>
            <!-- Picker button -->
            <button class="button is-white is-relative is-fullwidth has-text-left is-justify-content-left is-align-items-flex-start my-2"
              :disabled="loading[steps[currentStep].name][position] ||
                        selections[steps[currentStep].name][position] !== false || 
                        $route.params.product"
              @click="openWindowUploadImage(position)">
              <span class="icon">
                <span :class="['fas', {
                  'fa-upload': !loading[steps[currentStep].name][position] && selections[steps[currentStep].name][position] === false,
                  'fa-check': selections[steps[currentStep].name][position] !== false,
                  'fa-spin fa-spinner': loading[steps[currentStep].name][position] && selections[steps[currentStep].name][position] === false,
                }]"></span>
              </span>
              <span class="is-small has-text-left ml-2">
                {{ `${ $t('simulator.position') } ` + position }}
                <!-- <template v-if="selections[steps[currentStep].name][position] !== false && typeof selections[steps[currentStep].name][position].name !== 'undefined'"> -->
                <template v-if="selections[steps[currentStep].name][position] !== false">
                <br>
                <small v-if="selections[steps[currentStep].name][position].name" class="is-block">{{ selections[steps[currentStep].name][position].name.substr(0, 15) }}{{ selections[steps[currentStep].name][position].name.length > 15 ? '...' : '' }}</small>
              </template>
              </span>
            </button>
            <!-- Image preview -->
            <template v-if="preview[steps[currentStep].name][position]">
              <div class="ml-2 py-3 d-flex justify-content-center">
                <img :src="preview[steps[currentStep].name][position]"
                    style="max-width: 100%; height: auto; max-height: 200px" alt="">
              </div>
            </template>
          </div>
          <input class="d-none" type="file" accept=".svg"
                :ref="`fileUpload-${steps[currentStep].name}-${position}`"
                :id="`fileUpload-${steps[currentStep].name}-${position}`"
                :disabled="loading[steps[currentStep].name][position]"
                @change="callbackChangeHandler(position)"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Stepper from "@/components/Simulator/Stepper/index";
export default {
  name: 'ImagePickerStep',
  components: {Stepper},
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    steps: {
      type: Object,
      required: true,
    },
    selections: {
      type: Object,
      required: true,
    },
    preview: {
      type: Object,
      required: true,
    },
  },
  computed: {
    positions() {
      return Object.keys(this.selections[this.steps[this.currentStep].name])
    }
  },
  data() {
    return {
      loading: { },
      file: null
    };
  },
  created() {
    for(let i in this.steps) {
      this.$set(this.loading, this.steps[i].name, {});
      for(let k in this.selections[this.steps[i].name]) {
        this.$set(this.loading[this.steps[i].name], this.selections[this.steps[i].name][k], false)
      }
    }
  },
  mounted() {
    this.$root.$on('upload.file.loading.success', (currentStep, name, position) => {
      this.$set(this.loading[this.steps[this.currentStep].name], position, false);
      this.$toast.bottom("Your upload was successful! We're now preparing your model.", { duration: 2500 });
    });
    this.$root.$on('upload.file.loading.error', (currentStep, name, position) => {
      this.$set(this.loading[this.steps[currentStep].name], position, false);
      this.$set(this.preview[this.steps[currentStep].name], position, null);
    });
  },
  methods: {
    openWindowUploadImage(position) {
      let element = this.$refs[`fileUpload-${this.steps[this.currentStep].name}-${position}`];
      return element.length > 0 ? element[0].click() : null;
    },
    updateCurrentStep(step) {
      this.$emit('step', step)
    },
    callbackChangeHandler(position) {
      if(this.$refs[`fileUpload-${this.steps[this.currentStep].name}-${position}`][0].files.length) {
        this.$set(this.loading[this.steps[this.currentStep].name], position, true);
        this.file = this.$refs[`fileUpload-${this.steps[this.currentStep].name}-${position}`][0].files[0];
        // this.$set(this.preview[this.steps[this.currentStep].name], position, URL.createObjectURL(file));

        this.$emit('file', this.steps[this.currentStep].name, position, this.file);
        this.$refs[`fileUpload-${this.steps[this.currentStep].name}-${position}`][0].value = "";
      } else { this.$toast.bottom('Something went wrong uploading your image.', { duration: 2500 }); }
    },
    cleanUpPosition(position) {
      this.$set(this.loading[this.steps[this.currentStep].name], position, false);
      this.$set(this.preview[this.steps[this.currentStep].name], position, false);
      this.$emit('file', this.steps[this.currentStep].name, position, false);
    },
    rotateLeft(position)
    {
      this.rotate(position, -1);
    },
    rotateRight(position)
    {
      this.rotate(position, 1);
    },
    rotate(position, srcOrientation)
    {
      let self = this;

      var fr = new FileReader();
      fr.onload = function () {
        var svg = fr.result;
        var img = new Image();

        // make it base64
        var svg64 = btoa(svg);
        var b64Start = 'data:image/svg+xml;base64,';

        // prepend a "header"
        var image64 = b64Start + svg64;

        // set it as the source of the img element
        img.onload = function() {
          var width = img.width;
          var height = img.height;

          let heightPattern = /height="(.*?)"/;
          svg = svg.replace(heightPattern,`height="${width}"`);
          let widthPattern = /width="(.*?)"/;
          svg = svg.replace(widthPattern,`width="${height}"`);

          let svgPattern = /<svg[\S\s]*?>/;
          let svgTag = svg.match(svgPattern)[0];
          let transformPattern = /transform=".*?"/;

          var rotation = 0;
          if(transformPattern.test(svgTag))
          {
            let rotationPattern = /transform="rotate\((.*)\)"/;
            if(rotationPattern.test(svgTag))
            {
              rotation = svgTag.match(rotationPattern)[1];
            }
          }
          rotation = srcOrientation < 0 ? rotation - 90 : rotation + 90;

          svgTag = svgTag.replace(transformPattern, '');
          svgTag = svgTag.replace(">", ` transform="rotate(${rotation})">`);
          svg = svg.replace(svgPattern,svgTag);

          // convert to blob and fire event
          const blob = new Blob([svg], {type: 'image/svg+xml'});
          self.$emit('file', self.steps[self.currentStep].name, position, blob);
          self.file = blob;
        }
        img.src = image64;
      }
      fr.readAsText(this.file);
    },
    decreaseSize(position)
    {
      this.$emit('decrease', position);
    },
    increaseSize(position)
    {
      this.$emit('increase', position);
    }
  }
}
</script>
<style scoped>
.box {
  border-radius: unset;
  box-shadow: unset;
  border: 1px solid black;
  background-color: #f5f5f5;
}
.button.is-white {
  background-color: transparent;
}
#images-container
{
  column-count: 2;
}
@media screen and (max-width: 767px) {
  #images-container
  {
    column-count: 1;
  }
}
#images-container > div
{
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
}
</style>