<template>

  <div v-if="button" class="action-next">
    <button class="button is-success is-fullwidth is-large" @click="$emit('click')" :disabled="loading" >
      <span class="is-small is-size-6 has-text-left has-text-weight-bold">
        {{button}}
      </span>
      <span class="icon ml-2 is-size-6">
      <span :class="['fa', {
          'fas fa-chevron-right': !loading,
          'fa-spin fa-spinner': loading,
        }]"></span>
      </span>
    </button>
  </div>

</template>
<script>
export default {
  name: 'AddToCart',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      button: null
    }
  },
  watch: {
    '$route.params': 
    {
      handler(val)
      {
        if(val && val.id)
        {
          this.button = this.$t("simulator.updateProduct");
        }
        else{
          this.button = this.$t("simulator.addToCart");
        }
      },
      deep: true,
      immediate: true 
    }
  },
}
</script>