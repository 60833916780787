var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-picker"},[_c('stepper',{attrs:{"steps":_vm.steps,"current-step":_vm.currentStep,"button-class":['button', 'is-white'],"disabled":_vm.$parent.loaders.rotating,"subtitle":false},on:{"step":_vm.updateCurrentStep}}),_c('div',{attrs:{"id":"images-container"}},_vm._l((_vm.positions),function(position,index){return _c('div',{key:index},[_c('div',{staticClass:"is-relative"},[_c('div',{staticClass:"box is-fullwidth p-3 mb-0"},[(_vm.selections[_vm.steps[_vm.currentStep].name][position] !== false && !_vm.$route.params.product)?_c('div',{staticClass:"d-flex justify-content-end gap-1"},[_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.rotateLeft(position)}}},[_vm._m(0,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.rotateRight(position)}}},[_vm._m(1,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.decreaseSize(position)}}},[_vm._m(2,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.increaseSize(position)}}},[_vm._m(3,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.cleanUpPosition(position)}}},[_vm._m(4,true)])]):_vm._e(),_c('button',{staticClass:"button is-white is-relative is-fullwidth has-text-left is-justify-content-left is-align-items-flex-start my-2",attrs:{"disabled":_vm.loading[_vm.steps[_vm.currentStep].name][position] ||
                      _vm.selections[_vm.steps[_vm.currentStep].name][position] !== false || 
                      _vm.$route.params.product},on:{"click":function($event){return _vm.openWindowUploadImage(position)}}},[_c('span',{staticClass:"icon"},[_c('span',{class:['fas', {
                'fa-upload': !_vm.loading[_vm.steps[_vm.currentStep].name][position] && _vm.selections[_vm.steps[_vm.currentStep].name][position] === false,
                'fa-check': _vm.selections[_vm.steps[_vm.currentStep].name][position] !== false,
                'fa-spin fa-spinner': _vm.loading[_vm.steps[_vm.currentStep].name][position] && _vm.selections[_vm.steps[_vm.currentStep].name][position] === false,
              }]})]),_c('span',{staticClass:"is-small has-text-left ml-2"},[_vm._v(" "+_vm._s(`${ _vm.$t('simulator.position') } ` + position)+" "),(_vm.selections[_vm.steps[_vm.currentStep].name][position] !== false)?[_c('br'),(_vm.selections[_vm.steps[_vm.currentStep].name][position].name)?_c('small',{staticClass:"is-block"},[_vm._v(_vm._s(_vm.selections[_vm.steps[_vm.currentStep].name][position].name.substr(0, 15))+_vm._s(_vm.selections[_vm.steps[_vm.currentStep].name][position].name.length > 15 ? '...' : ''))]):_vm._e()]:_vm._e()],2)]),(_vm.preview[_vm.steps[_vm.currentStep].name][position])?[_c('div',{staticClass:"ml-2 py-3 d-flex justify-content-center"},[_c('img',{staticStyle:{"max-width":"100%","height":"auto","max-height":"200px"},attrs:{"src":_vm.preview[_vm.steps[_vm.currentStep].name][position],"alt":""}})])]:_vm._e()],2),_c('input',{ref:`fileUpload-${_vm.steps[_vm.currentStep].name}-${position}`,refInFor:true,staticClass:"d-none",attrs:{"type":"file","accept":".svg","id":`fileUpload-${_vm.steps[_vm.currentStep].name}-${position}`,"disabled":_vm.loading[_vm.steps[_vm.currentStep].name][position]},on:{"change":function($event){return _vm.callbackChangeHandler(position)}}})])])}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-undo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-redo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-minus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-plus"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-trash-alt"})])
}]

export { render, staticRenderFns }