import { CART_SERVICE } from "@/services/cart.service.js";
const selectedShipping = JSON.parse(localStorage.getItem('selectedShipping'));
const state = selectedShipping 
?{ listOutput:{}, processTask: null, removeTask: undefined, selectedShipping: selectedShipping}
:{ listOutput:{}, processTask: null, removeTask: undefined, selectedShipping: undefined};

const actions = {
    getList({ dispatch, commit }, inputgetCarts) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        CART_SERVICE.list(inputgetCarts)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    addCart({ dispatch, commit }, inputPostCart) {
        dispatch('alert/clear', null, { root: true });
        commit('addCartRequest');
        
        CART_SERVICE.addCart( inputPostCart )
        .then(
            output => {
                commit('addCartSuccess', output);
            },
            error => {
                    commit('addCartFailure', error);
                    dispatch('alert/error', error, { root: true }); 
                }
            );
    },
    updateCart({ dispatch, commit }, { product, quantity }) {
        commit('updateRequest');
        if(quantity == undefined) quantity = 1; // Default
        
        CART_SERVICE.update(product.id, quantity)
        .then(
            output => {
                commit('updateSuccess', output);
            },
            error => {
                commit('updateFailure', error);
                dispatch('alert/error', error, { root: true }); 
            }
        );
    },
    removeCart({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');
        
        CART_SERVICE.remove(id)
            .then(
                output => {
                    commit('removeSuccess', output);
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    setSelectedShipping({ dispatch, commit}, selectedShipping){
        dispatch('alert/clear', null, { root:true });
        commit('setSelectedShippingSuccess', selectedShipping);  
    }
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
      //addCart
    addCartRequest(state) {
        state.processTask = null;
    },
    addCartSuccess(state, output) {
        state.processTask = true;
        state.listOutput = output;
    },
    addCartFailure(state) {
        state.processTask = false;
    },
    //updateQt
    updateRequest(state) {
        state.processTask = null;
    },
    updateSuccess(state) {
        // state.listOutput = output;
        state.processTask = true;
    },
    updateFailure(state) {
        // state.products = [];
        state.processTask = false;
    },
    /* Remove */
    removeRequest(state) {
        state.removeTask = null;
    },
    removeSuccess(state) {
        state.removeTask = true;
    },
    removeFailure(state) {
        state.removeTask = false
    },
    //getSelectedShipping
    setSelectedShippingSuccess(state, selectedShipping) {
        state.selectedShipping = selectedShipping;
        localStorage.setItem('selectedShipping', JSON.stringify(state.selectedShipping));
    }
};


export const carts = {
    namespaced: true,
    state,
    actions,
    mutations
};