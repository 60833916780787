<template>

  <div class="action-next">
    
    <button class="button is-success is-fullwidth is-large" @click="$emit('next')">
      <span class="is-small is-size-6 has-text-left has-text-weight-bold">
        {{ $t("simulator.nextStep") }}
      </span>
      <span class="icon ml-2 is-size-6">
      <span class="fas fa-chevron-right"></span>
      </span>
    </button>

  </div>

</template>
<script>
export default {
  name: 'NextStep',
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
  }
}
</script>