<template>
  <div class="is-block">
    <button class="buttonClasses" @click="reset">
    <label for="ResetPosition">{{$t("simulator.ResetPosition")}}</label>
    </button>
  </div>
</template>
<script>
export default {
  name: 'ResetPosition',
  methods: {
    reset() {
      this.$emit('reset');
    }
  },
}
</script>