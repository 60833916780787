<template>
<section>
  <div class="d-flex flex-column" id="app">
    <router-view name="header"></router-view>
    <router-view class="flex-grow-1" ref="container" @delete-alert-requested="showDeleteAlert" @success-alert-requested="showSuccessAlert" @error-alert-requested="showErrorAlert"/>
    <router-view name="footer" @delete-alert-requested="showDeleteAlert" @success-alert-requested="showSuccessAlert" @error-alert-requested="showErrorAlert"></router-view>
  </div>
  <a href="#app"><img class="arrow-goTo-top" src="./assets/svg/arrowProductDetail.svg" alt=""></a>
</section>
</template>

<script>
import ResultPopup from '@/components/popups/ResultPopup.vue';
import Vue from "vue";

export default {
  name: 'App',
  methods: {
    showSuccessAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showDeleteAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showErrorAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showAlert(title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback)
    {
      var ComponentClass = Vue.extend(ResultPopup)
      var instance = new ComponentClass({
        propsData: {title: title, message: message, primaryButtonText: primaryButton, primaryButtonCallback: primaryCallback, secondaryButtonText: secondaryButton, secondaryButtonCallback: secondaryCallback }
      })
      instance.$mount() // pass nothing
      this.$refs.container.$el.appendChild(instance.$el)
    }
  }
}
</script>

<style lang="scss">
@import "~bulma";
@import "~bulma-switch";
@import "~@fortawesome/fontawesome-free/css/all.css";

// css created because babel generates some conflits with our css (simulator)
.navbar {
  background-color: unset;
}
.box{
  padding: 0;
}
img, video {
  height: 100%;
  max-width: 100%;
}
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0;
    margin: 0;
    overflow: hidden;
    padding: 5px;
    position: unset;
}
.footer {
  background-color: white;
  padding: 0;
}
// With the popup $fire, this one when giving display sets a padding right 17px
body.swal2-height-auto {
  padding-right: 0px!important;
}
///////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css?family=Sora');

html, body, #app {
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cursor-pointer
{
  cursor: pointer;
}
.text-right
{
  text-align: right;
}
.text-center
{
  text-align: center;
}
.text-left
{
  text-align: left;
}
.disabled
{
  pointer-events: none;
  opacity: 0.6;
}
.text-primary{
  color: #17224d !important;
}
.btn-primary{
  background-color: #17224d !important;
  border-radius: 1.25rem !important;
}
.btn-secondary{
  background-color: #17224d !important;
  border-radius: 1.25rem !important;
  border-color: #17224d!important;
}
.h-100-vh{
  height: 100vh;
}
.is-invalid .base-image-input .placeholder
{
    background-color: #e55353;
}
ul {
  list-style-type: none;
}
/* Global classes */
#app .was-validated :invalid ~ .invalid-feedback, 
#app .was-validated :invalid ~ .invalid-tooltip, 
#app .is-invalid ~ .invalid-feedback, 
#app .is-invalid ~ .invalid-tooltip
{
  display: flex;
  flex-direction: column;
}

.font-weight-bold
{
  font-weight: bold;
}
#app{
  position: relative;
  height: 100vh !important;
}
.arrow-goTo-top{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transform: rotate(180deg);
  z-index: 1;
}
</style>
