const state = {
    optionId: [],
    filterList: []
};

const actions = {
    getList({ commit }, output) {
        commit('updateList', output);
    }
};

const mutations = {
    updateList(state, output) {
        state.filterList = output;
    }
};

export const filter = {
    namespaced: true,
    state,
    actions,
    mutations
};
