<template>
  <div class="box cursor-pointer" id="box" @click="sendClicked()">
    <div id="box-wrapper">
      <vueper-slides v-if="product.photos && product.photos.length" ref="mainShop" class="slider no-shadow flex-grow-1 mb-0" 
        :bullets="false" 
        :arrows="false" 
        :touchable="false" 
        :visibleSlides="1" 
        :disableArrowsOnEdges="true" 
        :lazy="true" 
        fixed-height="300px"
        :autoplay="true"
        >        
          <vueper-slide v-for="(photo, index) in product.photos" :key="index">
              <template v-slot:content>
                <img class="img-fluid img-settings cursor-pointer" :src="url + photo" alt="">
              </template>
          </vueper-slide>
      </vueper-slides>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <span class="title-style">{{product.name}}</span>
      </div>
    </div>
    <div class="container-brand cursor-pointer" id="container-brand" :style="{ 'background-color': product.brand == INFLATE ? INFLATE_COLOR : ACTIVE_COLOR }">
      <div class="content-brand">
        <div class="row mt-2 mb-2">
          <div class="col-12">
            <a><span class="learn-more-span-style">{{$t("products.learnMoreAbout")}}</span> <b><span class="span-style">{{product.name}}</span></b></a>
            <br>
            <span class="span-style">{{showPriceLogged}}<span>{{showEuro}}</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
const ACTIVE = "ActiveBrands";
const INFLATE = "InflateYourImagination";
const INFLATE_COLOR = "#ff0066";
const ACTIVE_COLOR = "#007fff";
import { mapActions, mapState } from "vuex";
export default {
  components: {VueperSlides, VueperSlide },
  data: function () {
    return {
        url: process.env.VUE_APP_URL_ROOT,
        quantity: 1,
        hover: false,
        dynamicHeight: null,
        currentSlide: 0,
        ACTIVE: ACTIVE,
        INFLATE: INFLATE,
        INFLATE_COLOR: INFLATE_COLOR,
        ACTIVE_COLOR: ACTIVE_COLOR,
    };
  },
  props: {
    product: Object,
  },
  computed: {
    ...mapState('carts', ['processTask']),
    showEuro()
    {
      return localStorage.getItem('user') && this.product && this.product.price ? "€": null
    },
    showPriceLogged()
    {
      return localStorage.getItem('user') && this.product && this.product.price ? this.product.price : null
    }
  },
  mounted() {
    this.setHeight();
  },
  methods: {
    ...mapActions('carts', ['addCart']),
    sendClickedModel () {
      this.$emit("clickModel");
    },
    sendClicked () {
      this.$emit("click");
    },
    addToCart() {
      this.$emit("success-alert-requested");
    },
    setHeight() {
      const offsetParentHeight = document.getElementById('box').offsetHeight;
      this.dynamicHeight = offsetParentHeight;
    }
  },
}
</script>

<style scoped>
a{
    text-decoration: none;
}
.boxes-container{
  height: 300px;
  flex-wrap: wrap;
}
.box{
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,.50);
  margin: 15px auto;
  width: 100%;
  position: relative;
  max-width: 460px;
  min-height: 520px;
  transition: transform .2s;
  border-radius: 15px;
  z-index: 1 !important;
}
.container-brand
{
  display: flex;
  justify-content: center;
  border-radius: 10px;
  background-color: #17224d;
  /* margin: 3rem 20px; */
  position: absolute;
  bottom: 20px;
  right: 10px;
  left: 10px;
}
.content-brand{
  margin: 0 20px;
}
.products-title{
    color: #17224d;
    margin-left: 5rem;
}
.store-title{
    color: #17224d;
    text-align: right !important;
    margin-right: 12rem;
}
.span-style{
    color: white;
    font-size: 20px;
}
.learn-more-span-style{
    color: white;
    font-size: 15px;
}
#align-icon{
  text-align: right !important;
  color: white;
}
.icon-style{
  color: white;
  font-size: 30px;
  margin-top: 0.5rem;
}
.img-settings{
  width: 100%;
  height: 100%;
  min-height: 360px;
  max-height: 360px;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
/* #home .img-settings
{
  min-height: unset;
  max-height: unset;
} */
#product-text{
  background-color:#17224d;
}
.title-style{
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.sub-title-style{
  color: black;
  font-size: 15px;
}
.img-settings{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
 @media screen and (max-width: 1220px) {
  .box {
    width: 45%; 
  }
  .products-title{
    margin-left: 0rem;
    text-align: center !important;
  }
  .store-title{
    margin-right: 0rem;
    text-align: center !important;
  }
} 
 @media screen and (max-width: 768px) {
  .boxes-container{
    flex-flow: wrap;
  }
  .box {
    width: 100%;
    margin: 10px auto;
  }
} 
 @media screen and (max-width: 576px) {
  .box {
    width: 100%;
    height: auto;
  }
  .content-brand h2{
    font-size: 16px;
  }
  .content-brand h6{
    font-size: 13px;
  }
} 
@media screen and (min-width: 576px) {
  .box:hover
  {
    transform: scale(1.01);
  }
}
</style>