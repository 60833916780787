<template>
  <section id="footer">
    <div class="footer position-relative">
      <!-- Footer -->
      <div class="img-footer position-relative" :class="{'img-footer-home': $route.name == 'Home'}">
          <div class="container">
            <a href="/"><img class="img-logo img-fluid" src="../assets/logo_white.svg" alt=""></a>
            <!-- Contacts -->
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <!-- Address -->
                <div class="col-12 align-address text-light">
                  <h5 class="mt-3 mb-0">{{ $t("footer.location") }}</h5>
                  <p class="m-0"><a href="https://goo.gl/maps/7PHMpdG1RKkMW8y9A" target="_blank">{{ $t("footer.address") }}</a></p>
                </div>
                <!-- Phone number and email -->
                <div class="col-12 align-phone-email text-light">
                  <h5 class="mt-3 mb-0">{{ $t("footer.contact") }}</h5>
                  <p class="m-0"><a :href="'mailto:'+$t('footer.email')">{{ $t("footer.email") }}</a></p>
                  <p class="m-0"><a :href="'tel:'+$t('footer.phone')">{{ $t("footer.phone") }}</a></p>
                  <p class="m-0">{{ $t("footer.callTo") }}</p>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="col-12 text-light align-terms-legalArea">
                  <a @click="sendClicked(3)" class="text-center text-light cursor-pointer">{{ $t("footer.PolAndPriv").toUpperCase() }}</a>
                  <br>
                  <a @click="sendClicked(4)" class="text-center text-light cursor-pointer">{{ $t("footer.terms").toUpperCase() }}</a>
                  <br>
                  <a id="legal-area" @click="sendClicked(5)" class="text-center cursor-pointer">{{ $t("footer.legalArea").toUpperCase() }}</a>
                </div>
                <!-- Social -->
                <div class="align-icons-div col-12 gap-5 mt-5">
                  <a target="_blank" href="https://www.tiktok.com/@factoryplay_inflatables"><img id="tiktok-icon" src="@/assets/footer/tiktok.png" alt=""></a>
                  <a target="_blank" href="https://www.instagram.com/factoryplay_inflatables/"><b-icon class="h2 m-0" icon="instagram" variant="light"></b-icon></a>
                  <a target="_blank" href="https://www.facebook.com/factoryplay.inflatables"><b-icon class="h2 m-0" icon="facebook" variant="light"></b-icon></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCgVjUNm3YaOXetIeuLRe86w"><b-icon class="h1 m-0" icon="youtube" variant="light"></b-icon></a>
                </div>
                <!-- Copyright and powered by -->
                <div class="row powered-by-row">
                  <div class="col-12 text-light" id="factoryPlay">
                    <h5>ⓒ <span>{{ new Date().getFullYear() }}</span> {{ $t("footer.copyRight") }} </h5>
                  </div>
                  <div class="col-12">
                    <span class="poweredBy">{{ $t("footer.poweredBy") }} <strong><a id="outsmartis" href="https://outsmartis.com/website/" target="_blank">Outsmartis</a></strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>


<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions , mapState} from "vuex";
import { UTILS_HELPER } from "@/helpers/utils.js"
export default {
  data() {
    return {
      email: '',
      submitted: false
    };
  },
  validations: {
    email: { required, email },
    },
  computed:{
    ...mapState('newsletter', ['processTask']),
    ...mapState('alert', ['output']),
    isCompleted(){
      return this.email;
    },
    errors: function()
    {
        return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
    },
    footerImg: function()
    {
      return this.$route.name == "Home" ? 'footer-img-home.png' : 'footer-img.png';
    }
  },
  methods: {
    ...mapActions('newsletter', ['sendNewsletter']),
    ...mapActions({
        clearAlert: 'alert/clear' 
    }),
    sendClicked (id) {
      this.$router.push({name: 'StaticPages', query: {id: id} });
    },
    handleNewsletterSubmit() {
      this.clearAlert();
      
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
      
      this.sendNewsletter({'email': this.email});
    },
    hasError(fieldName)
    {
      return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    },
  },
  watch:{
    processTask(val){
      if(val != null){
        this.submitted = false;
        if(val)
        {
          this.email = "";
          this.$emit("success-alert-requested", { 
            title: this.$t("footer.newsletter.subscribeNewsTitle"), 
            message: this.$t("footer.newsletter.subscribeNewsContent"), 
            primaryButton: this.$t("close")
          })   
        }else{
          this.$emit("error-alert-requested", { 
            title: this.$t("footer.newsletter.errorNewsletterTitle"), 
            message: this.$t("footer.newsletter.errorNewsletterContent"), 
            primaryButton: this.$t("close")
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.footer{
    width: 100% !important;
    background: #17224d;
}
.img-footer{
    padding-top: 50px;
    padding-bottom: 50px;
}
a{
  color: white;
  text-decoration: none;
}
a:hover,
#legal-area:hover
{
  color: white;
  opacity: 0.8;
  text-decoration: none;
}
#outsmartis
{
  color: white;
  font-size: 15px;
}

#tiktok-icon{
  width: 32px;
  height: 32px;
}
.button-style{
  background-color: white;
  color: rgba(57,98,190,1);
  border-radius: 20px;
}
hr{
  opacity: 1 !important;
  background-color:white !important;
  border: 1.5px solid white !important;
}
.img-logo{
  width: 250px;
}
.img-legal{
  width: 60%;
  object-fit: contain;
}
.poweredBy{
  font-size: 15px;
  color: white;
}
.powered-by-row{
  padding-top: 3rem;
  text-align: right;
}
.align-icons-div{
  display: flex;
  justify-content: right;
  align-content: center;
}
.align-phone-email{
  text-align: left;
}
.align-address{
  text-align: left;
}
.align-terms-legalArea{
  display: flex;
  justify-content: space-between;
}
#div-poweredBy{
  padding-right: 5rem;
}

@media screen and (max-width: 1200px) {
  .container{
    padding-top: 0rem !important;
  }
  .poweredBy{
    color: white;
  }
  #div-poweredBy{
    text-align: center;
    padding-right: 0rem;
  }
  #factoryPlay{
    text-align: center;
  }
  a{
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .powered-by-row{
    text-align: center;
  }
  .align-icons-div{
    display: flex;
    justify-content: center;
  }
  .align-phone-email{
    text-align: center;
  }
  .align-address{
    text-align: center;
  }
  .align-terms-legalArea{
    margin-top: 3rem;
    flex-direction: column;
    text-align: center;
  }
  .img-logo{
    width: 100%;
  } 
}

/* #sidebar-extra
{
  top: 0;
  background-color: whitesmoke;
} */

</style>