<template>
  <div v-if="currentUrl" class="d-flex" id="share">
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="facebook">
      <facebook :url="currentUrl" scale="2" ></facebook>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="twitter">
      <twitter :url="currentUrl"  scale="2" ></twitter>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="linkedin">
      <linkedin url="https://www.linkedin.com/in/pedro-santos-587905146/?originalSubdomain=pt" scale="2" ></linkedin>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="pinterest">
      <pinterest :url="currentUrl" scale="2" ></pinterest>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="email">
      <email :url="currentUrl" scale="2" ></email>
    </div>
    <div class="share-block cursor-pointer d-flex justify-content-center align-items-center flex-grow-1" id="email">
      <whats-app :url="currentUrl" scale="2" ></whats-app>
    </div>
  </div>
</template>

<script>
import {
  Facebook,
  Twitter,
  Linkedin,
  Pinterest,
  Email,
  WhatsApp
} from "vue-socialmedia-share";
import { API_HELPER } from "@/helpers/api.js";

export default {
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Pinterest,
    Email,
    WhatsApp
  },
  data: function () {
    return {
      currentUrl : "",
    };
  },
  created: function(){
    this.currentUrl = API_HELPER.pathJoin([process.env.VUE_APP_FRONT, window.location.pathname]);
  }
};
</script>

<style scoped>
h4{
  color: #17224d;
}

/* .share-block
{
  background-color: rgba(0, 0, 0, 0.8); 
  padding: 0 10px;
} */
 @media screen and (min-width: 767px) {
  #share
  {
    width: 15rem;
    margin: 0;
    gap: 5px;
  } 
} 
</style>