export const IOSystems = {

    data() {
        return {
            ioSystems: { }
        };
    },

    created() {
        this.ioSystems = require(`./env/${process.env.NODE_ENV}.json`);
    },

    methods: {
        getEndpoint(service = 'io') {
            let endpoint = this.ioSystems.endpoints[`iosystems.${service}.kit.endpoint`] ?? null;
            return `https://${endpoint}`;
        },

        designKitImagesGdConcatenatePromise(payload = {}, headers = {}) {
            headers = this.cleanHeaders(headers);
            return this.axios.post(`${this.getEndpoint('design')}/api/images/gd/concatenate`, payload, { headers: headers });
        },
        mediaKitRepositoryUploadFile(payload = {}, headers = {}) {
            headers = this.cleanHeaders(headers);
            return this.axios.post(`${this.getEndpoint('media')}/api/v1/Files`, payload, { headers: headers });
        },
        cleanHeaders(headers = {}) {
            return Object.assign({
                "X-APP-KIT-TOKEN": this.ioSystems.AppKitToken,
            }, headers);
        }


    }

};