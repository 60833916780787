var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color-picker buttons mb-0"},[_c('stepper',{attrs:{"steps":_vm.steps,"current-step":_vm.currentStep,"button-class":['button', 'is-white',],"disabled":_vm.$parent.loaders.rotating,"subtitle":false},on:{"step":_vm.updateCurrentStep}}),_c('div',{attrs:{"id":"colors-container"}},_vm._l((_vm.positions),function(position,index){return _c('div',{key:index},[_c('div',{staticClass:"is-relative"},[_c('div',{staticClass:"box is-fullwidth p-3 mb-0"},[_c('p',[_c('span',{staticClass:"is-small has-text-left has-text-weight-semibold"},[_vm._v(" "+_vm._s(`${ _vm.$t('simulator.position') } ` + position)+" ")])]),_c('div',{staticClass:"buttons"},_vm._l((_vm.options),function(color){return _c('button',{key:color,staticClass:"button is-white is-small",attrs:{"disabled":_vm.$route.params.product},on:{"click":function($event){return _vm.chooseColor(position, color)}}},[_c('span',{staticClass:"icon is-medium"},[_c('span',{class:['fas',  'is-size-5', {
                    'fa-circle': _vm.selections[_vm.steps[_vm.currentStep].name][position] !== color,
                    'fa-circle custom': color === '#ffffff',
                    'fa-check-circle': _vm.checkCircle(position,color),
                    'fa-check-circle custom': _vm.checkCircleCustom(position,color)
                  }],style:({
                    color: color
                  })})])])}),0),(_vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] !== false && !_vm.$route.params.product)?_c('div',{staticClass:"d-flex justify-content-end gap-1"},[_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.rotateLeft(_vm.patternKeys[index])}}},[_vm._m(0,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.rotateRight(_vm.patternKeys[index])}}},[_vm._m(1,true)]),_c('button',{staticClass:"button is-danger is-small is-inverted",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.cleanUpPosition(_vm.patternKeys[index])}}},[_vm._m(2,true)])]):_vm._e(),_c('button',{staticClass:"button is-white is-relative is-fullwidth has-text-left is-justify-content-left is-align-items-flex-start",attrs:{"disabled":(typeof _vm.loading[_vm.steps[_vm.currentStep].name] !== 'undefined' && 
                               _vm.loading[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]]) ||
                       _vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] !== false || 
                    _vm.$route.params.product},on:{"click":function($event){return _vm.openWindowUploadImage(_vm.patternKeys[index])}}},[_c('span',{staticClass:"icon"},[_c('span',{class:['fas', {
                'fa-upload': typeof _vm.loading[_vm.steps[_vm.currentStep].name] !== 'undefined' && !_vm.loading[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] && _vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] === false,
                'fa-check': _vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] !== false,
                'fa-spin fa-spinner': typeof _vm.loading[_vm.steps[_vm.currentStep].name] !== 'undefined' && _vm.loading[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] && _vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] === false,
              }]})]),_c('span',{staticClass:"is-small has-text-left ml-2"},[_vm._v(" "+_vm._s(`${ _vm.$t('simulator.position') } ` + position)+" "),(_vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]] !== false)?[_c('br'),(_vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]].name)?_c('small',{staticClass:"is-block"},[_vm._v(_vm._s(_vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]].name.substr(0, 15))+_vm._s(_vm.patterns[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]].name.length > 15 ? '...' : ''))]):_vm._e()]:_vm._e()],2)]),(_vm.patterns_preview[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]])?[_c('div',{staticClass:"ml-2 py-3 d-flex justify-content-center"},[_c('img',{staticStyle:{"max-width":"100%","height":"auto","max-height":"200px"},attrs:{"src":_vm.patterns_preview[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]],"alt":""}})])]:_vm._e()],2),_c('input',{ref:`fileUploadPattern-${_vm.steps[_vm.currentStep].name}-${_vm.patternKeys[index]}`,refInFor:true,staticClass:"d-none",attrs:{"type":"file","accept":".svg","id":`fileUploadPattern-${_vm.steps[_vm.currentStep].name}-${_vm.patternKeys[index]}`,"disabled":_vm.loading[_vm.steps[_vm.currentStep].name][_vm.patternKeys[index]]},on:{"change":function($event){return _vm.callbackChangeHandler(_vm.patternKeys[index])}}})])])}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-undo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-redo"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('span',{staticClass:"fa fa-trash-alt"})])
}]

export { render, staticRenderFns }